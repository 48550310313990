import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";
import { requestTypes } from "../../constants/AppConstant";
import DatePicker from "react-datepicker";

function AddExpensePage({ onSubmitSuccess, editMode, getExpenseData }) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  const onSubmit = (data) => {
    // onSubmitSuccess();
    const payload = {
      school_id: userDetails?.school_id || "",
      session_id: userDetails?.session_id || "",
      date: data?.date,
      amount: data?.amount || 0,
      expenseType: data?.expenseType,
      description: data?.description || "",
    };
    const payloadData = new FormData();

    // payloadData.append('photo', data?.photo?.[0]);
    for (let i = 0; i < data?.photo?.length; i++) {
      payloadData.append("photo", data?.photo?.[i]);
    }
    payloadData.append("Data", JSON.stringify(payload));
    axiosReq
      .post(apiEndponts?.addExpenses, payloadData)
      .then((resp) => {
        showToast.success("Expense added successfully");
        onSubmitSuccess();
        getExpenseData();
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="expenseType"
          >
            Expense Type
          </label>
          <select
            {...register("expenseType", {
              required: "Expense Type is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="incharge_id"
          >
            <option value="">Select Expense Type</option>
            {/* Add class options here */}
            {[
              "Salaries",
              "Maintenence",
              "Educational Supply",
              "Student Activity",
              "Transportation",
              "IT & Support",
              "Insurance",
              "Food Service",
              "Administrative Cost",
            ]?.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
          {errors.expenseType && (
            <p className="text-red-500 text-sm mt-1">
              {errors.expenseType.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="date"
          >
            Date
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DatePicker
                placeholderText="Select date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                wrapperClassName="w-full"
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          />
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="amount"
          >
            Amount
          </label>
          <input
            {...register("amount", { required: "Amount is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="amount"
            type="text"
            placeholder="Amount"
          />
          {errors.amount && (
            <p className="text-red-500 text-sm mt-1">{errors.amount.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="photo"
          >
            Attachments
          </label>
          <input
            {...register("photo")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="photo"
            type="file"
            multiple
          />
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="description"
          >
            Description
          </label>
          <input
            {...register("description", {
              required: "Description is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="description"
            type="text"
            placeholder="Description"
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">
              {errors.description.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-2 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add Expense
        </button>
      </div>
    </form>
  );
}

export default AddExpensePage;
