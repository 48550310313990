"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiDownload, FiInfo } from "react-icons/fi";
import apiEndpoints from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";

export default function FeesGenerationPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [feesPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredFees, setFilteredFees] = useState([]);
  const [statusSummary, setStatusSummary] = useState({
    totalStudents: 0,
    paidCount: 0,
    unpaidCount: 0,
  });

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get current fees for pagination
  const indexOfLastFee = currentPage * feesPerPage;
  const indexOfFirstFee = indexOfLastFee - feesPerPage;
  const currentFees = filteredFees.slice(indexOfFirstFee, indexOfLastFee);

  const getStatusSummary = async (month = "", year = "") => {
    setIsLoading(true);
    try {
      // Base params that are always required
      const params = {
        school_id: userDetails?.school_id,
        session_id: userDetails?.session_id,
      };

      // Add optional filters only if they are selected
      if (month && year) {
        params.month = `${month} ${year}`;
      }

      if (selectedClass) {
        params.class_id = selectedClass;
      }

      if (selectedStudent) {
        params.student_id = selectedStudent;
      }

      if (selectedDate) {
        params.date = selectedDate;
      }

      const response = await axiosReq.get(
        apiEndpoints.getStatusSummary,
        params
      );

      if (response?.success && response?.data) {
        const { totalStudents, paidCount, unpaidCount, students } =
          response.data;

        setStatusSummary({
          totalStudents,
          paidCount,
          unpaidCount,
        });

        const formattedStudents = students.map((student) => ({
          photo: student.photo || "/student-avatar.jpg",
          studentName: student.studentName,
          rollNo: student.rollNo,
          class: student.className,
          paymentDate: student.paymentDate
            ? new Date(student.paymentDate).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "-",
          totalFee: `Rs ${student.totalFee.toLocaleString()}`,
          status: student.status,
          admissionNo: student.admissionNo,
          transactionId: student.transactionId,
          paymentMode: student.paymentMode,
        }));

        setFilteredFees(formattedStudents);
      }
    } catch (err) {
      console.error("Error fetching status summary:", err);
      setFilteredFees([]);
      setStatusSummary({
        totalStudents: 0,
        paidCount: 0,
        unpaidCount: 0,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handlers for filters
  const handleClassChange = (e) => {
    const classId = e.target.value;
    setSelectedClass(classId);
    setCurrentPage(1);
    getStatusSummary(selectedMonth, selectedYear);
  };

  const handleStudentChange = (e) => {
    const studentId = e.target.value;
    setSelectedStudent(studentId);
    setCurrentPage(1);
    getStatusSummary(selectedMonth, selectedYear);
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);
    setCurrentPage(1);
    getStatusSummary(selectedMonth, selectedYear);
  };

  const handleMonthChange = (e) => {
    const month = e.target.value;
    setSelectedMonth(month);
    setCurrentPage(1);
    if (month) {
      getStatusSummary(month, selectedYear);
    } else {
      getStatusSummary();
    }
  };

  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    if (selectedMonth) {
      setCurrentPage(1);
      getStatusSummary(selectedMonth, year);
    }
  };

  // Search function
  const handleSearch = useCallback(
    (e) => {
      const searchValue = e.target.value;
      setSearchTerm(searchValue);
      setCurrentPage(1);

      if (!searchValue.trim()) {
        getStatusSummary(selectedMonth, selectedYear);
        return;
      }

      const filtered = filteredFees.filter((student) => {
        const searchStr = searchValue.toLowerCase();
        return (
          student.studentName.toLowerCase().includes(searchStr) ||
          student.rollNo.toString().includes(searchStr) ||
          student.class.toLowerCase().includes(searchStr) ||
          student.admissionNo.toLowerCase().includes(searchStr)
        );
      });
      setFilteredFees(filtered);
    },
    [selectedMonth, selectedYear, filteredFees]
  );

  // Initial load
  useEffect(() => {
    if (userDetails?.school_id && userDetails?.session_id) {
      getStatusSummary();
    }
  }, [userDetails?.school_id, userDetails?.session_id]);
  // ... continued from Part 1

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Fee stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-x-0">
                <div className="bg-[#E7F7FF] text-[#113870] px-3 py-2 border border-[#C2C2C2] font-medium">
                  <div>Total Students</div>
                  <div>{statusSummary.totalStudents}</div>
                </div>
                <div className="bg-[#E7FFE7] text-[#00A76F] px-3 py-2 border border-[#C2C2C2] font-medium">
                  <div>Fees Received</div>
                  <div>{statusSummary.paidCount}</div>
                </div>
                <div className="bg-[#FFE7E7] text-[#B53D3E] px-3 py-2 border border-[#C2C2C2] font-medium">
                  <div>Fees Pending</div>
                  <div>{statusSummary.unpaidCount}</div>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4]"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4]"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  <option value={currentYear}>{`${currentYear}-${String(
                    currentYear + 1
                  ).slice(2)}`}</option>
                </select>
                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4]"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option value="">All Months</option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4]"
                  value={selectedClass}
                  onChange={handleClassChange}
                >
                  <option value="">All Classes</option>
                  {/* Add your class options here */}
                </select>
                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4]"
                  value={selectedStudent}
                  onChange={handleStudentChange}
                >
                  <option value="">All Students</option>
                  {/* Add your student options here */}
                </select>
                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4]"
                />
                <button className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200">
                  + Add Fees
                </button>
              </div>
            </div>

            {/* Fees table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Photo</th>
                    <th className="px-3 py-2">Student Name</th>
                    <th className="px-3 py-2">Class</th>
                    <th className="px-3 py-2">Roll No</th>
                    <th className="px-3 py-2"> Payment Date</th>
                    <th className="px-3 py-2">Total Amount</th>
                    <th className="px-3 py-2">Status</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="8" className="px-3 py-4 text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : currentFees.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="px-3 py-4 text-center">
                        No data available
                      </td>
                    </tr>
                  ) : (
                    currentFees.map((student, index) => (
                      <tr key={index}>
                        <td className="px-3 py-2">
                          <img
                            src={student.photo}
                            alt=""
                            className="w-8 h-8 rounded-full"
                          />
                        </td>
                        <td className="px-3 py-2">
                          <div>{student.studentName}</div>
                          <div className="text-[#0662C6]">
                            Admission no: {student.admissionNo}
                          </div>
                        </td>
                        <td className="px-3 py-2">{student.class}</td>
                        <td className="px-3 py-2">{student.rollNo}</td>
                        <td className="px-3 py-2">
                          {student.paymentDate}
                          <div className="text-[#0662C6]">
                            payment mode : {student.paymentMode}
                          </div>
                        </td>

                        <td className="px-3 py-2 text-[#0662C6]">
                          {student.totalFee}
                        </td>
                        <td className="px-3 py-2">
                          <span
                            className={`px-2 py-1 rounded-full text-xs ${
                              student.status === "Pending"
                                ? "bg-[#FFE7E7] text-[#B53D3E]"
                                : "bg-[#E7FFE7] text-[#00A76F]"
                            }`}
                          >
                            {student.status}
                          </span>
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button
                              className="text-[#0662C6] hover:text-[#113870] transition-colors duration-200"
                              title="Download Invoice"
                            >
                              <FiDownload size={14} />
                            </button>

                            <button
                              className="text-[#0662C6] hover:text-[#113870] transition-colors duration-200"
                              title="View Details"
                            >
                              <FiInfo size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {!isLoading && filteredFees.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {indexOfFirstFee + 1} to{" "}
                  {Math.min(indexOfLastFee, filteredFees.length)} of{" "}
                  {filteredFees.length} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>
                  {[...Array(Math.ceil(filteredFees.length / feesPerPage))].map(
                    (_, i) => (
                      <button
                        key={i + 1}
                        onClick={() => setCurrentPage(i + 1)}
                        className={`px-3 py-1 rounded ${
                          currentPage === i + 1
                            ? "bg-[#0662C6] text-white"
                            : "bg-white text-[#0662C6] hover:bg-gray-50"
                        } border border-[#C2C2C2]`}
                      >
                        {i + 1}
                      </button>
                    )
                  )}
                  <button
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil(filteredFees.length / feesPerPage)
                    }
                    className={`px-3 py-1 rounded ${
                      currentPage ===
                      Math.ceil(filteredFees.length / feesPerPage)
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
