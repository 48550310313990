import React, { useState } from "react";
import PropTypes from "prop-types";
import { FiX } from "react-icons/fi";

const VehicleDetailsPopup = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState("itinerary");

  // Sample timeline data - replace with your API data
  const timelineData = [
    {
      type: "running",
      duration: "2 hr 15 min",
      distance: "240 Km",
      timestamp: "20/08/24 - 25/08/24",
    },
    {
      type: "stop",
      stopNumber: 4,
      duration: "1hr 5 min",
      location: "ITBP Park, Mode Town 1, Faridabad",
      address: "Samak Nagar, Haryana",
      timestamp: "20/08/24 - 25/08/24",
    },
    {
      type: "running",
      duration: "1 hr 45 min",
      distance: "180 Km",
      timestamp: "20/08/24 - 25/08/24",
    },
    {
      type: "stop",
      stopNumber: 3,
      duration: "45 min",
      location: "Central Market",
      address: "Sector 12, Delhi",
      timestamp: "20/08/24 - 25/08/24",
    },
  ];

  const getTimelineItemStyles = (type, isLast) => {
    const baseClasses = "relative pl-8 group";
    const connectorClasses = !isLast
      ? "absolute left-[7px] top-3 bottom-0 w-[2px] bg-gradient-to-b"
      : "";

    if (type === "running") {
      return {
        container: baseClasses,
        connector: connectorClasses + " from-[#00A76F] to-[#B53D3E]",
        dot: "w-4 h-4 rounded-full bg-white border-[2.5px] border-[#00A76F] shadow-sm",
        badge:
          "text-xs bg-[#E5F6F0] text-[#00A76F] px-2 py-0.5 rounded-full font-medium",
        title: "text-[#00A76F] font-medium text-sm",
      };
    }
    return {
      container: baseClasses,
      connector: connectorClasses + " from-[#B53D3E] to-[#00A76F]",
      dot: "w-4 h-4 rounded-full bg-white border-[2.5px] border-[#B53D3E] shadow-sm",
      badge:
        "text-xs bg-[#FBEAEA] text-[#B53D3E] px-2 py-0.5 rounded-full font-medium",
      title: "text-[#B53D3E] font-medium text-sm",
    };
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[400px] max-h-[90vh] overflow-hidden shadow-lg">
        {/* Header Tabs */}
        <div className="flex items-center border-b border-[#E5E5E5]">
          <button
            onClick={() => setActiveTab("itinerary")}
            className={`px-4 py-2.5 font-medium transition-colors ${
              activeTab === "itinerary"
                ? "text-[#0662C6] border-b-2 border-[#0662C6]"
                : "text-[#888888] hover:text-[#0662C6]"
            }`}
          >
            Itinerary
          </button>
          <button
            onClick={() => setActiveTab("vehicle")}
            className={`px-4 py-2.5 font-medium transition-colors ${
              activeTab === "vehicle"
                ? "text-[#0662C6] border-b-2 border-[#0662C6]"
                : "text-[#888888] hover:text-[#0662C6]"
            }`}
          >
            Vehicle Details
          </button>
          <button
            onClick={onClose}
            className="ml-auto px-4 py-2.5 text-[#888888] hover:text-[#B53D3E] transition-colors"
          >
            <FiX size={18} />
          </button>
        </div>

        {/* Content */}
        <div className="p-4">
          {/* Controls */}
          <div className="flex items-center gap-2 mb-4">
            <div className="flex items-center border border-[#E5E5E5] rounded px-3 py-1.5 hover:border-[#0662C6] transition-colors">
              <span className="mr-2">📅</span>
              <select className="outline-none pr-4 cursor-pointer">
                <option>Today</option>
                <option>Yesterday</option>
                <option>Last 7 days</option>
              </select>
            </div>
            <button className="p-2 border border-[#E5E5E5] rounded hover:border-[#0662C6] transition-colors">
              🔄
            </button>
            <button className="p-2 border border-[#E5E5E5] rounded hover:border-[#0662C6] transition-colors">
              ▶️
            </button>
            <button className="p-2 border border-[#E5E5E5] rounded hover:border-[#0662C6] transition-colors">
              ⬇️
            </button>
          </div>

          {/* Status Cards */}
          <div className="grid grid-cols-3 gap-2 mb-4">
            <div className="border border-[#E5E5E5] rounded p-2.5 hover:border-[#0662C6] transition-colors">
              <div className="text-[#0662C6] font-medium mb-1">All</div>
              <div className="text-xs text-[#888888]">19 hr 25 min</div>
              <div className="text-xs text-[#888888]">250 Km</div>
            </div>
            <div className="border border-[#E5E5E5] rounded p-2.5 hover:border-[#0662C6] transition-colors">
              <div className="text-[#888888] font-medium mb-1">Stopped</div>
              <div className="text-xs text-[#888888]">19 hr 25 min</div>
              <div className="text-xs text-[#888888]">250 Km</div>
            </div>
            <div className="border border-[#E5E5E5] rounded p-2.5 hover:border-[#0662C6] transition-colors">
              <div className="text-[#888888] font-medium">No Info</div>
            </div>
          </div>

          {/* Timeline */}
          <div className="space-y-0 max-h-[400px] overflow-y-auto pr-2">
            {timelineData.map((item, index) => {
              const isLast = index === timelineData.length - 1;
              const styles = getTimelineItemStyles(item.type, isLast);

              return (
                <div key={index} className={styles.container}>
                  {/* Connector Line */}
                  {!isLast && <div className={styles.connector} />}

                  {/* Timeline dot */}
                  <div className="absolute left-0 top-1 z-10">
                    <div className={styles.dot} />
                  </div>

                  <div className="pb-6">
                    {item.type === "running" ? (
                      // Running Point Content
                      <>
                        <div className="flex items-center gap-2 mb-1.5">
                          <span className={styles.title}>Running Since</span>
                          <span className={styles.badge}>{item.duration}</span>
                        </div>
                        <div className="text-sm font-medium text-gray-800">
                          Covered {item.distance}
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          {item.timestamp}
                        </div>
                      </>
                    ) : (
                      // Stop Point Content
                      <>
                        <div className="flex items-center gap-2 mb-1.5">
                          <span className={styles.title}>
                            Stop {item.stopNumber}
                          </span>
                          <span className={styles.badge}>{item.duration}</span>
                        </div>
                        <div className="text-sm font-medium text-gray-800">
                          {item.location}
                        </div>
                        <div className="text-sm font-medium text-gray-800">
                          {item.address}
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          {item.timestamp}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

VehicleDetailsPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default VehicleDetailsPopup;
