import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";

// Layout
import Layout from "./Layout/Layout";

// Landing and Auth Pages
import LandingPage from "./Pages/LandingPage/LandingPage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import ForgotPasswordPage from "./Pages/LoginPage/forgotPassword";

// Dashboard Pages
import DashboardPage from "./Pages/SchoolDashboard/DashboardPage";
import StudentDashboard from "./Pages/StudentDashboard/Dashboard";
import TeacherDashboard from "./Pages/TeacherDashboard/dashboard";

// My School Pages
import ActivityPage from "./Pages/SchoolActivity/ActivityPage";
import Assignments from "./Pages/Assignments/Assignments";
import ClassPage from "./Pages/ClassPage/ClassPage";
import CircularsPage from "./Pages/CircularPage/CircularPage";
import CoCurricularActivityPage from "./Pages/CCAPage/CoCurricularActivityPage";
import Expensepage from "./Pages/ExpensePage/ExpensePage";
import HolidayPage from "./Pages/HolidayPage/HolidayPage";
import HomeworkPage from "./Pages/HomeworkPage/HomeworkPage";
import LecturePage from "./Pages/LecturePage/LecturePage";
import NoticesPage from "./Pages/NoticePage/NoticePage";
import ParentsPage from "./Pages/ParentPage/ParentPage";
import PTMPage from "./Pages/PTMPage/PTMPage";
import ResultsPage from "./Pages/ResultPage/ResultPage";
import SchoolBannersPage from "./Pages/SchoolBanner/SchoolBannerPage";
import SchoolProfile from "./Pages/SchoolProfilePage/SchoolProfile";
import StudentAttendancePage from "./Pages/StudentAttendance/StudentAttendance";
import StudentsPage from "./Pages/StudentPage/StudentsPage";
import StudentLeavePage from "./Pages/LeaveRequestStudent/LeavePage";
import TeachersAttendancePage from "./Pages/TeacherAttendance/TeacherAttendance";
import TeacherLeavePage from "./Pages/LeaveRequestTeacher/TeacherLeave";
import TimeTable from "./Pages/TimetablePage/TimeTable";

// Staff Pages
import DriversPage from "./Pages/DriverPage/DriverPage";
import HelpersPage from "./Pages/HelperPage/HelperPage";
import TeacherPage from "./Pages/TeacherPage/TeacherPage";

// Fee Pages
import FeesPage from "./Pages/FeesPage/FeesPage";
import FeesGenerationPage from "./Pages/FeesGeneration/FeeGenerationPAge";

// Bus Pages
import BusTrackingPage from "./Pages/BusPage/BusPage";
import RoutesPage from "./Pages/RoutePage/RoutePage";
import SchoolBusesPage from "./Pages/SchoolBusPage/SchoolBusPage";

// Complaint Pages
import ComplaintsPage from "./Pages/ComplainPage.jsx/ComplainPage";

// SchoolEG Pages
import ERequestPage from "./Pages/eRequestPage/eRequest";
import SchoolEventsPage from "./Pages/SchoolEvent/SchoolEventsPage";
import NewsPage from "./Pages/NewsPage/NewsPage";

// SuperAdmin Pages
import AdvertisementPage from "./Pages/AdvertisementPage/AdvertisementPage";
import CounsellingPage from "./Pages/CounsellingPage/CounsellingPage";
import EnrolledPage from "./Pages/EnrolledPage/EnrolledPage";
import SchoolEGPage from "./Pages/AllSchoolPage/AllSchool";
import TuitionPage from "./Pages/TutionPage/TutionPage";

// Users Page
import UsersPage from "./Pages/UserPage/UserPage";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        {/* Protected Routes */}
        <Route element={<Layout />}>
          {/* My School Routes */}
          <Route
            path="/students"
            element={
              <ProtectedRoute>
                <StudentsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/studentsattendance"
            element={
              <ProtectedRoute>
                <StudentAttendancePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teachersattendance"
            element={
              <ProtectedRoute>
                <TeachersAttendancePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banner"
            element={
              <ProtectedRoute>
                <SchoolBannersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/holidays"
            element={
              <ProtectedRoute>
                <HolidayPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/parents"
            element={
              <ProtectedRoute>
                <ParentsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/classes"
            element={
              <ProtectedRoute>
                <ClassPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lectures"
            element={
              <ProtectedRoute>
                <LecturePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/results"
            element={
              <ProtectedRoute>
                <ResultsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/homework"
            element={
              <ProtectedRoute>
                <HomeworkPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assignments"
            element={
              <ProtectedRoute>
                <Assignments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notices"
            element={
              <ProtectedRoute>
                <NoticesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/circulars"
            element={
              <ProtectedRoute>
                <CircularsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ptm"
            element={
              <ProtectedRoute>
                <PTMPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banners"
            element={
              <ProtectedRoute>
                <SchoolBannersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/holiday"
            element={
              <ProtectedRoute>
                <HolidayPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/expense"
            element={
              <ProtectedRoute>
                <Expensepage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/studentsleave"
            element={
              <ProtectedRoute>
                <StudentLeavePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teachersLeave"
            element={
              <ProtectedRoute>
                <TeacherLeavePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/timetable"
            element={
              <ProtectedRoute>
                <TimeTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="/activity"
            element={
              <ProtectedRoute>
                <ActivityPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/co-curricular"
            element={
              <ProtectedRoute>
                <CoCurricularActivityPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <SchoolProfile />
              </ProtectedRoute>
            }
          />

          {/* Dashboard Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/studentdashboard"
            element={
              <ProtectedRoute>
                <StudentDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teacherdashboard"
            element={
              <ProtectedRoute>
                <TeacherDashboard />
              </ProtectedRoute>
            }
          />

          {/* Staff Routes */}
          <Route
            path="/teachers"
            element={
              <ProtectedRoute>
                <TeacherPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/drivers"
            element={
              <ProtectedRoute>
                <DriversPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/helpers"
            element={
              <ProtectedRoute>
                <HelpersPage />
              </ProtectedRoute>
            }
          />

          {/* Fee Routes */}
          <Route
            path="/studentfees"
            element={
              <ProtectedRoute>
                <FeesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/feegeneration"
            element={
              <ProtectedRoute>
                <FeesGenerationPage />
              </ProtectedRoute>
            }
          />

          {/* Users Page */}
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <UsersPage />
              </ProtectedRoute>
            }
          />

          {/* Bus Routes */}
          <Route
            path="/buses"
            element={
              <ProtectedRoute>
                <SchoolBusesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bus-tracking"
            element={
              <ProtectedRoute>
                <BusTrackingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/routes"
            element={
              <ProtectedRoute>
                <RoutesPage />
              </ProtectedRoute>
            }
          />

          {/* Complaint Routes */}
          <Route
            path="/complaints"
            element={
              <ProtectedRoute>
                <ComplaintsPage />
              </ProtectedRoute>
            }
          />

          {/* SchoolEG Routes */}
          <Route
            path="/e-request"
            element={
              <ProtectedRoute>
                <ERequestPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events"
            element={
              <ProtectedRoute>
                <SchoolEventsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/news"
            element={
              <ProtectedRoute>
                <NewsPage />
              </ProtectedRoute>
            }
          />

          {/* SuperAdmin Routes */}
          <Route
            path="/allschools"
            element={
              <ProtectedRoute>
                <SchoolEGPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/counsellings"
            element={
              <ProtectedRoute>
                <CounsellingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/advertisements"
            element={
              <ProtectedRoute>
                <AdvertisementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tuitions"
            element={
              <ProtectedRoute>
                <TuitionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/enrolledstudents"
            element={
              <ProtectedRoute>
                <EnrolledPage />
              </ProtectedRoute>
            }
          />

          {/* 404 Route - Keep this last */}
          <Route path="*" element={<div>Page Not Found</div>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
