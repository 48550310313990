"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiEdit2, FiTrash2, FiEye, FiVideo } from "react-icons/fi";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";
import { format, parseISO, isFuture, isPast, isToday } from "date-fns";
import { MONTHS } from "../../constants/AppConstant";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Function to determine meeting status
const getMeetingStatus = (date, time) => {
  const meetingDateTime = new Date(`${date}T${time}`);
  const now = new Date();

  if (isPast(meetingDateTime)) {
    return { status: "Expired", color: "text-[#B53D3E]" };
  } else if (isToday(meetingDateTime)) {
    return { status: "Today", color: "text-[#0A8F47]" };
  } else {
    return { status: "Upcoming", color: "text-[#0662C6]" };
  }
};

export default function PTMPage() {
  const [activeNav, setActiveNav] = useState("My School");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [originalMeetings, setOriginalMeetings] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pages: 1,
    pageSize: 8,
    total: 0,
  });

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Add "All" option to months
  const allMonths = [{ id: "all", name: "All Months" }, ...MONTHS];
  useEffect(() => {
    if (userDetails?.school_id) {
      getMeetingsData();
    }
  }, [userDetails?.school_id, selectedMonth]);

  const getMeetingsData = (page = 1) => {
    setIsLoading(true);
    const params = {
      school_id: userDetails.school_id || "",
      model: "ptm",
      page,
      year: new Date().getFullYear(),
    };

    if (selectedMonth !== "all") {
      params.month = selectedMonth;
    }

    axiosReq
      .get(apiEndponts.getCircular, params)
      .then((resp) => {
        const meetings = resp?.data?.map((item) => {
          const { status, color } = getMeetingStatus(item.date, item.time);
          return {
            ...item,
            formattedDate: format(parseISO(item.date), "dd/MM/yyyy"),
            formattedTime: format(
              parseISO(`${item.date}T${item.time}`),
              "hh:mm a"
            ),
            status,
            statusColor: color,
          };
        });

        setFilteredMeetings(meetings || []);
        setOriginalMeetings(meetings || []);
        setPaginationInfo(
          resp?.pagination || {
            current: 1,
            pages: 1,
            pageSize: 8,
            total: 0,
          }
        );
      })
      .catch((err) => {
        console.error("Error fetching meetings:", err);
        setFilteredMeetings([]);
        setOriginalMeetings([]);
        setPaginationInfo({
          current: 1,
          pages: 1,
          pageSize: 8,
          total: 0,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = useCallback(
    debounce((value) => {
      if (!value.trim()) {
        setFilteredMeetings(originalMeetings);
      } else {
        const lowercasedFilter = value.toLowerCase();
        const filtered = originalMeetings.filter((meeting) => {
          return Object.keys(meeting).some((key) =>
            String(meeting[key]).toLowerCase().includes(lowercasedFilter)
          );
        });
        setFilteredMeetings(filtered);
      }
      setCurrentPage(1);
    }, 300),
    [originalMeetings]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleMonthChange = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    const newPage = paginationInfo.current - 1;
    if (newPage >= 1) {
      setCurrentPage(newPage);
      getMeetingsData(newPage);
    }
  };

  const handleNextPage = () => {
    const newPage = paginationInfo.current + 1;
    if (newPage <= paginationInfo.pages) {
      setCurrentPage(newPage);
      getMeetingsData(newPage);
    }
  };

  const handleDeleteClick = (meeting) => {
    setSelectedMeeting(meeting);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosReq.delete(`${apiEndponts.deletePTM}/${selectedMeeting.id}`);
      getMeetingsData(currentPage);
      setIsDeleteModalOpen(false);
      setSelectedMeeting(null);
    } catch (error) {
      console.error("Error deleting meeting:", error);
    }
  };

  const handleJoinMeeting = (meetLink) => {
    window.open(meetLink, "_blank");
  };

  const renderPagination = () => {
    if (!filteredMeetings.length || isLoading) return null;

    const pageNumbers = [];
    for (let i = 1; i <= paginationInfo.pages; i++) {
      pageNumbers.push(i);
    }

    const startEntry =
      (paginationInfo.current - 1) * paginationInfo.pageSize + 1;
    const endEntry = Math.min(
      paginationInfo.current * paginationInfo.pageSize,
      paginationInfo.total
    );

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {startEntry} to {endEntry} of {paginationInfo.total} entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={handlePrevPage}
            disabled={paginationInfo.current === 1}
            className={`px-2 py-1 text-xs rounded ${
              paginationInfo.current === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => {
                setCurrentPage(number);
                getMeetingsData(number);
              }}
              className={`px-2 py-1 text-xs rounded ${
                paginationInfo.current === number
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={handleNextPage}
            disabled={paginationInfo.current === paginationInfo.pages}
            className={`px-2 py-1 text-xs rounded ${
              paginationInfo.current === paginationInfo.pages
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* PTM stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Meetings: {paginationInfo.total}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                >
                  {allMonths.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* PTM table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Date & Time</th>
                    <th className="px-3 py-2">Meeting Name</th>
                    <th className="px-3 py-2">Class</th>
                    <th className="px-3 py-2">Teacher</th>
                    <th className="px-3 py-2">Notes</th>
                    <th className="px-3 py-2">Status</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="7" className="px-3 py-4 text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredMeetings.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="px-3 py-4 text-center">
                        No meetings found
                      </td>
                    </tr>
                  ) : (
                    filteredMeetings.map((meeting, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-2">
                          <div>{meeting.formattedDate}</div>
                          <div className="text-[#0662C6]">
                            {meeting.formattedTime}
                          </div>
                        </td>
                        <td className="px-3 py-2">{meeting.name}</td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {meeting.className}
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex items-center space-x-2">
                            {meeting.teacherPhoto && (
                              <img
                                src={meeting.teacherPhoto}
                                alt={meeting.teacherName}
                                className="w-6 h-6 rounded-full"
                              />
                            )}
                            <span>{meeting.teacherName}</span>
                          </div>
                        </td>
                        <td className="px-3 py-2">
                          {meeting.meet_info?.notes || "N/A"}
                        </td>
                        <td className={`px-3 py-2 ${meeting.statusColor}`}>
                          {meeting.status}
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            {meeting.status !== "Expired" && (
                              <button
                                className="text-[#0A8F47] hover:text-[#113870]"
                                onClick={() =>
                                  handleJoinMeeting(
                                    meeting.meet_info?.meet_link
                                  )
                                }
                              >
                                <FiVideo size={14} />
                              </button>
                            )}
                            <button className="text-[#0662C6] hover:text-[#113870]">
                              <FiEdit2 size={14} />
                            </button>
                            <button
                              className="text-[#B53D3E] hover:text-[#113870]"
                              onClick={() => handleDeleteClick(meeting)}
                            >
                              <FiTrash2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {renderPagination()}

            {/* Delete Confirmation Modal */}
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onConfirm={handleDeleteConfirm}
              itemName={selectedMeeting ? "this meeting" : ""}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
