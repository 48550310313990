import {
  Calendar,
  Users,
  BookOpen,
  BarChart,
  MessageSquare,
  Shield,
} from "lucide-react";
import { FeatureCard } from "../ui/FeatureCard";
import { SectionTitle } from "../ui/SectionTitle";

const features = [
  {
    icon: Calendar,
    title: "Smart Scheduling",
    description:
      "Automated timetable generation and event management for seamless academic planning.",
  },
  {
    icon: Users,
    title: "Student Management",
    description:
      "Complete student lifecycle management from admission to graduation.",
  },
  {
    icon: BookOpen,
    title: "Learning Management",
    description:
      "Integrated platform for assignments, resources, and online learning.",
  },
  {
    icon: BarChart,
    title: "Performance Analytics",
    description:
      "Comprehensive analytics and reporting for data-driven decisions.",
  },
  {
    icon: MessageSquare,
    title: "Communication Hub",
    description:
      "Seamless communication between teachers, students, and parents.",
  },
  {
    icon: Shield,
    title: "Secure & Reliable",
    description: "Enterprise-grade security with 99.9% uptime guarantee.",
  },
];

const Features = () => {
  return (
    <section id="features" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <SectionTitle
          title="Everything You Need to Run Your School"
          subtitle="Powerful features designed to streamline school operations and enhance the learning experience."
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
