import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";
import { activeClassTime } from "../../constants/AppConstant";

function AddTimeTableForm({ onSubmitSuccess, editMode, getClassInfo }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [teachers, setTeachers] = useState([]);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  const [classOptions, setClassOptions] = useState([]);

  useEffect(() => {
    if (userDetails?.school_id) {
      getTeachers();
      getClassNames();
    }
  }, [userDetails?.school_id]);

  const getClassNames = () => {
    const query = JSON.stringify({
      school_id: userDetails?.school_id,
      session_id: userDetails?.session_id,
    });
    axiosReq
      .get(apiEndponts.getClassOfSchool, { query })
      .then((resp) => {
        const classOptions = resp?.records?.map((item) => ({
          name: item?.name,
          id: item?._id,
        }));
        setClassOptions(classOptions);
      })
      .catch((err) => console.log(err));
  };

  const getTeachers = () => {
    const url = `${apiEndponts.getTeacherList}${userDetails?.school_id}/${userDetails?.session_id}`;
    axiosReq
      .get(url)
      .then((resp) => {
        const teachers = resp?.data?.map((item) => ({
          id: item?._id,
          name: item?.name,
        }));
        setTeachers(teachers);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (data) => {
    const payload = {
      class_id: data?.class_id || "",
      periods: [
        {
          teacher_id: data?.teacher_id,
          periodNumber: data?.periodNumber,
          subject: data?.subject,
          // "startTime": data?.startTime,
          // "endTime": data?.endTime,
          startTime: "2024-11-20T09:00:00.000Z",
          endTime: "2024-11-20T09:45:00.000Z",
          meet_Info: {
            Link: data?.meetLink,
            Note: data?.Note,
          },
          periodDays: [data?.periodDays],
        },
      ],
    };

    const payloadData = new FormData();
    payloadData.append("Data", JSON.stringify(payload));
    axiosReq
      .post(apiEndponts?.addTimeTable, payloadData)
      .then((resp) => {
        showToast.success("Timetable added successfully");
        onSubmitSuccess();
        getClassInfo();
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="class_id"
          >
            Class
          </label>
          <select
            {...register("class_id", { required: "Class is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="class_id"
          >
            <option value="">Select Class</option>
            {/* Add class options here */}
            {classOptions?.map((item) => {
              return <option value={item.id}>{item?.name}</option>;
            })}
          </select>
          {errors.class_id && (
            <p className="text-red-500 text-sm mt-1">
              {errors.class_id.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="teacher_id"
          >
            Teacher
          </label>
          <select
            {...register("teacher_id", { required: "Teacher is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="teacher_id"
          >
            <option value="">Select Teacher</option>
            {/* Add class options here */}
            {teachers?.map((item) => {
              return <option value={item.id}>{item?.name}</option>;
            })}
          </select>
          {errors.teacher_id && (
            <p className="text-red-500 text-sm mt-1">
              {errors.teacher_id.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="periodNumber"
          >
            Period No.
          </label>
          <input
            {...register("periodNumber", {
              required: "Period No. is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 "
            id="periodNumber"
            type="text"
            placeholder="Period No."
          />
          {errors.periodNumber && (
            <p className="text-red-500 text-sm mt-1">
              {errors.periodNumber.message}
            </p>
          )}
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="startTime"
          >
            Start Time
          </label>
          <input
            {...register("startTime", { required: "Start Time is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 "
            id="startTime"
            type="time"
            placeholder="Start Time"
          />
          {errors.startTime && (
            <p className="text-red-500 text-sm mt-1">
              {errors.startTime.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="endTime"
          >
            End Time
          </label>
          <input
            {...register("endTime", { required: "End Time is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="endTime"
            type="time"
            placeholder="End Time"
          />
          {errors.endTime && (
            <p className="text-red-500 text-sm mt-1">
              {errors.endTime.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="subject"
          >
            Subject
          </label>
          <input
            {...register("subject")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="subject"
            type="text"
            maxLength={20}
            placeholder="Subject"
          />
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="periodDays"
          >
            Active Days
          </label>
          <select
            {...register("periodDays")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="periodDays"
          >
            <option value="">Select Active Days</option>
            {/* Add class options here */}
            {activeClassTime?.map((item) => {
              return <option value={item.name}>{item?.name}</option>;
            })}
          </select>
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="meetLink"
          >
            Meet Link
          </label>
          <input
            {...register("meetLink")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="meetLink"
            type="text"
            placeholder="Meet Link"
          />
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="Note"
          >
            Meeting Instruction
          </label>
          <input
            {...register("Note")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="Note"
            type="text"
            placeholder="Meeting Instruction"
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-2 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add Time Table
        </button>
      </div>
    </form>
  );
}

export default AddTimeTableForm;
