import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";
import DatePicker from "react-datepicker";

function AddCoCurricularActivity({ onSubmitSuccess, editMode, getClassInfo }) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [teachers, setTeachers] = useState([]);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    if (userDetails?.school_id) {
      getTeachers();
    }
  }, [userDetails?.school_id]);

  const getTeachers = () => {
    const url = `${apiEndponts.getTeacherList}${userDetails?.school_id}/${userDetails?.session_id}`;
    axiosReq
      .get(url)
      .then((resp) => {
        const teachers = resp?.data?.map((item) => ({
          id: item?._id,
          name: item?.name,
        }));
        setTeachers(teachers);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (data) => {
    const payload = {
      name: data?.name || "",
      date: data?.date || "",
      school_id: userDetails?.school_id,
      activityFor: "School",
      details: data?.details || "",
    };
    const payloadData = new FormData();
    payloadData.append("Data", JSON.stringify(payload));
    for (let i = 0; i < data?.photo?.length; i++) {
      payloadData.append("photo", data?.photo?.[i]);
    }

    axiosReq
      .post(apiEndponts?.addCoCurricular, payloadData)
      .then((resp) => {
        showToast.success("Co-Curricular Activity created successfully");
        onSubmitSuccess();
        getClassInfo();
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="name"
          >
            Activity Name
          </label>
          <input
            {...register("name", { required: "Activity Name is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 "
            id="name"
            type="text"
            placeholder="Activity Name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="date"
          >
            Activity Date
          </label>
          {/* <input
            {...register("date")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="date"
            type="date"
            placeholder=" Activity Date"
          /> */}
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DatePicker
                placeholderText="Select date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                wrapperClassName="w-full"
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          />
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="details"
          >
            Description
          </label>
          <input
            {...register("details")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="details"
            type="text"
            placeholder="Description"
          />
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="photo"
          >
            Photos/Attachments
          </label>
          <input
            {...register("photo")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="photo"
            type="file"
            multiple
            // onChange={handlePhotoChange}
          />
          {/* {photoPreview && (
            <img
              src={photoPreview}
              alt="Preview"
              className="mt-2 w-16 h-16 rounded-full"
            />
          )} */}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-2 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add Co-Curricular Activity
        </button>
      </div>
    </form>
  );
}

export default AddCoCurricularActivity;
