import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { stateAndDistrict } from "../../constants/AppConstant";
import { showToast } from "../../utils/toast";

function AddParentForm({
  onSubmitSuccess,
  editMode,
  getParensts,
  isEditMode,
  editData,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [districtOptions, setDistrictOptions] = useState([]);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    if (isEditMode) {
      let { name, email, phone, originalAddress } = editData;
      selectDistrictOptions(originalAddress?.state);
      reset({
        name,
        email,
        phone,
        address: originalAddress?.name || "",
        district: originalAddress?.district || "",
        state: originalAddress?.state || "",
        pincode: originalAddress?.pincode,
      });
    }
  }, [isEditMode]);

  const onSubmit = (data) => {
    // onSubmitSuccess();
    const payload = {
      name: data?.name || "",
      phone: data?.phone || "",
      email: data?.email || "",
      password: data?.password || "",
      school_id: [userDetails?.school_id],
      // "session_id": userDetails?.session_id || "",
      address: {
        name: data?.address || "",
        state: data?.state || "",
        district: data?.district || "",
        pincode: data?.pincode || "",
      },
    };
    const payloadData = new FormData();
    payloadData.append("Data", JSON.stringify(payload));
    // updateParent
    if (isEditMode) {
      const url = `${apiEndponts?.updateParent}/${editData?.parent_id}`;
      axiosReq
        .put(url, payloadData)
        .then((resp) => {
          showToast.success("Parent info update successfully");
          onSubmitSuccess();
          getParensts();
        })
        .catch((err) => console.log(err));
    } else {
      axiosReq
        .post(apiEndponts?.addParents, payloadData)
        .then((resp) => {
          onSubmitSuccess();
          showToast.success("New Parent created successfully");
          getParensts();
        })
        .catch((err) => console.log(err));
    }
  };

  const selectDistrictOptions = (state_name) => {
    const districts = stateAndDistrict?.find(
      (item) => item?.state === state_name
    )?.districts;
    setDistrictOptions(districts);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="name"
          >
            Name
          </label>
          <input
            {...register("name", { required: "Name is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
            type="text"
            placeholder="Name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="email"
          >
            Email
          </label>
          <input
            {...register("email", {
              required: isEditMode ? false : "Email is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="email"
            type="text"
            placeholder="Email"
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
            {...register("phone", { required: "Phone is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="phone"
            type="text"
            maxLength={10}
            placeholder="Phone"
          />
          {errors.phone && (
            <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="password"
          >
            Password
          </label>
          <input
            {...register("password", { required: "Password is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="password"
            type="text"
            maxLength={10}
            placeholder="Password"
          />
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">
              {errors.password.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="classRollNo"
          >
            Address
          </label>
          <input
            {...register("address", {
              // required: "Class Roll No. is required",
              // valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="classRollNo"
            type="text"
            placeholder="Address"
          />
          {errors.classRollNo && (
            <p className="text-red-500 text-sm mt-1">
              {errors.classRollNo.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="state"
          >
            State
          </label>
          <select
            {...register("state", { required: "State is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="state"
            onChange={(e) => {
              const val = e.target.value;
              const districts = stateAndDistrict?.find(
                (item) => item?.state === val
              )?.districts;
              setDistrictOptions(districts);
            }}
          >
            <option value="">Select State</option>
            {stateAndDistrict?.map((item) => {
              return <option value={item?.state}>{item?.state}</option>;
            })}
            {/* Add route options here */}
          </select>
          {errors.state && (
            <p className="text-red-500 text-sm mt-1">{errors.state.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="district"
          >
            District
          </label>
          <select
            {...register("district", { required: "District is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="district"
          >
            <option value="">Select District</option>
            {districtOptions?.map((item) => {
              return <option value={item}>{item}</option>;
            })}
            {/* Add route options here */}
          </select>
          {errors.district && (
            <p className="text-red-500 text-sm mt-1">
              {errors.district.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="classRollNo"
          >
            Pincode
          </label>
          <input
            {...register("pincode", {
              required: "Pincode is required",
              // valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="classRollNo"
            type="text"
            placeholder="Pincode"
          />
          {errors.pincode && (
            <p className="text-red-500 text-sm mt-1">
              {errors.pincode.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-2 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add Parent
        </button>
      </div>
    </form>
  );
}

export default AddParentForm;
