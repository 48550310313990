"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiX, FiEdit } from "react-icons/fi";
import { Switch } from "@headlessui/react";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import AddUserForm from "./AddUser";
import EditUserForm from "./EditUserForm";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function UsersPage() {
  // State Management
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(8); // Updated to match API's pageSize
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [selectedUserAccess, setSelectedUserAccess] = useState(null);
  const [isViewAccessModalOpen, setIsViewAccessModalOpen] = useState(false);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Get Users Data
  const getUsersData = async (page = 1, search = "") => {
    setIsLoading(true);
    try {
      const response = await axiosReq.get(apiEndponts.getCircular, {
        school_id: userDetails?.school_id,
        model: "user",
        page,
        limit: usersPerPage,
        search,
      });

      if (response?.data) {
        const formattedUsers = response.data.map((item) => ({
          ...item,
          id: item._id,
          originalAccess: item?.access,
          access: Object.values(item?.access)?.filter((moduleVal) => moduleVal)
            ?.length,
          isActive: Boolean(item.isActive),
        }));

        setFilteredUsers(formattedUsers);
        setTotalUsers(response?.pagination?.total || 0);
        setTotalPages(response?.pagination?.pages || 1);
      }
    } catch (err) {
      console.error("Error fetching users:", err);
      showToast.error("Failed to fetch users");
      setFilteredUsers([]);
      setTotalUsers(0);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails?.school_id) {
      getUsersData(currentPage, searchTerm);
    }
  }, [currentPage, userDetails?.school_id]);

  const handleEdit = (user) => {
    setEditingUser(user);
    setIsEditModalOpen(true);
  };

  const handleToggle = async (userId, currentStatus) => {
    try {
      setFilteredUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, isActive: !currentStatus } : user
        )
      );

      await axiosReq.put(`${apiEndponts.updateUser}/${userId}`, {
        isActive: !currentStatus,
      });

      showToast.success(
        `User ${!currentStatus ? "activated" : "deactivated"} successfully`
      );
    } catch (error) {
      console.error("Error toggling user status:", error);
      setFilteredUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, isActive: currentStatus } : user
        )
      );
      showToast.error("Failed to update user status");
    }
  };

  // Debounced search handler
  const handleSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      getUsersData(1, value);
    }, 300),
    []
  );

  // Search term change handler
  const handleSearchTermChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleViewAccess = (access) => {
    setSelectedUserAccess(access);
    setIsViewAccessModalOpen(true);
  };

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Users header */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Users: {totalUsers}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <button
                  onClick={() => setIsAddUserModalOpen(true)}
                  className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs"
                >
                  + Add User
                </button>
              </div>
            </div>

            {/* Users table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Name</th>
                    <th className="px-3 py-2">Phone</th>
                    <th className="px-3 py-2">Password</th>
                    <th className="px-3 py-2">Access</th>
                    <th className="px-3 py-2">Is Active</th>
                    <th className="px-3 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredUsers.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        No users found
                      </td>
                    </tr>
                  ) : (
                    filteredUsers.map((user) => (
                      <tr key={user._id}>
                        <td className="px-3 py-2">{user.name}</td>
                        <td className="px-3 py-2">{user.phone}</td>
                        <td className="px-3 py-2">{user.password}</td>
                        <td className="px-3 py-2">
                          {user.access}{" "}
                          <button
                            onClick={() =>
                              handleViewAccess(user.originalAccess)
                            }
                            className="text-[#0662C6] ml-1 hover:underline"
                          >
                            View
                          </button>
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex items-center space-x-2">
                            <Switch
                              checked={user.isActive}
                              onChange={() =>
                                handleToggle(user._id, user.isActive)
                              }
                              className={`${
                                user.isActive ? "bg-[#00A76F]" : "bg-red-600"
                              } relative inline-flex h-4 w-8 items-center rounded-full transition-colors focus:outline-none`}
                            >
                              <span
                                className={`${
                                  user.isActive
                                    ? "translate-x-4"
                                    : "translate-x-1"
                                } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
                              />
                            </Switch>
                            <div
                              className={`w-2 h-2 rounded-full ${
                                user.isActive ? "bg-[#00A76F]" : "bg-red-600"
                              }`}
                            />
                          </div>
                        </td>
                        <td className="px-3 py-2">
                          <button
                            onClick={() => handleEdit(user)}
                            className="text-[#0662C6] hover:text-[#113870]"
                          >
                            <FiEdit size={16} />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {!isLoading && filteredUsers.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {(currentPage - 1) * usersPerPage + 1} to{" "}
                  {Math.min(currentPage * usersPerPage, totalUsers)} of{" "}
                  {totalUsers} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={() => {
                      setCurrentPage((prev) => Math.max(prev - 1, 1));
                      getUsersData(currentPage - 1, searchTerm);
                    }}
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>

                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => {
                        setCurrentPage(index + 1);
                        getUsersData(index + 1, searchTerm);
                      }}
                      className={`px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? "bg-[#0662C6] text-white"
                          : "bg-white text-[#0662C6] hover:bg-gray-50"
                      } border border-[#C2C2C2]`}
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    onClick={() => {
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages));
                      getUsersData(currentPage + 1, searchTerm);
                    }}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Add User Modal */}
            {isAddUserModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Add New User</h2>
                    <button
                      onClick={() => setIsAddUserModalOpen(false)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddUserForm
                    onSubmitSuccess={() => {
                      setIsAddUserModalOpen(false);
                      getUsersData(currentPage, searchTerm);
                      showToast.success("User added successfully");
                    }}
                  />
                </div>
              </div>
            )}

            {/* Edit User Modal */}
            {isEditModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Edit User</h2>
                    <button
                      onClick={() => {
                        setIsEditModalOpen(false);
                        setEditingUser(null);
                      }}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <EditUserForm
                    user={editingUser}
                    onSubmitSuccess={() => {
                      setIsEditModalOpen(false);
                      setEditingUser(null);
                      getUsersData(currentPage, searchTerm);
                    }}
                  />
                </div>
              </div>
            )}
            {/* View Access Modal */}
            {isViewAccessModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/3 mx-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">User Access Details</h2>
                    <button
                      onClick={() => {
                        setIsViewAccessModalOpen(false);
                        setSelectedUserAccess(null);
                      }}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <div className="space-y-2">
                    {selectedUserAccess &&
                      Object.entries(selectedUserAccess).map(([key, value]) => (
                        <div
                          key={key}
                          className="flex items-center justify-between"
                        >
                          <span className="capitalize">
                            {key.replace(/_/g, " ")}
                          </span>
                          <span
                            className={
                              value ? "text-green-600" : "text-red-600"
                            }
                          >
                            {value ? "Enabled" : "Disabled"}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
