"use client";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

// Mock data
const mockPhotoUrl =
  "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop";

const teacherData = {
  name: "Tarun Shukla",
  teacherId: "20FF23",
  gender: "Male",
  designation: "PRT",
  contact: "8956231235",
  email: "XYZ@gmail.com",
  address: "ITBP Park Chauhan Calony, Sector 144, Gurgaon",
  busDetails: {
    stoppage: "Street 4, Chauhan Calony",
    busNo: "HRXCV7687",
    route: "09",
  },
};

// Attendance Data and Configuration
const attendanceData = {
  labels: [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ],
  datasets: [
    {
      data: [89, 91, 85, 99, 86, 80, 78, 81, 82, 88, 79, 87],
      fill: true,
      borderColor: "#4A90E2",
      backgroundColor: "rgba(74, 144, 226, 0.2)",
      tension: 0.4,
      pointRadius: 4,
      pointBackgroundColor: "#fff",
      pointBorderColor: "#4A90E2",
      pointBorderWidth: 2,
    },
  ],
};

const attendanceOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
    tooltip: {
      backgroundColor: "#fff",
      titleColor: "#000",
      bodyColor: "#000",
      borderColor: "#ddd",
      borderWidth: 1,
      padding: 10,
      displayColors: false,
      callbacks: {
        label: function (context) {
          return `${context.parsed.y}% Attendance`;
        },
      },
    },
  },
  scales: {
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 25,
        callback: function (value) {
          return value + "%";
        },
      },
      grid: {
        color: "#f0f0f0",
        drawBorder: false,
      },
    },
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
};

// Generate attendance table data
const generateAttendanceTableData = () => {
  return Array(7)
    .fill(null)
    .map((_, dayIndex) => ({
      day: dayIndex + 1,
      months: Array(12)
        .fill(null)
        .map(() => {
          const random = Math.random();
          if (random < 0.7) return "present";
          if (random < 0.9) return "absent";
          return "none";
        }),
    }));
};

// TeacherAttendanceContent Component
function TeacherAttendanceContent({
  attendanceData,
  attendanceOptions,
  attendanceTableData,
}) {
  return (
    <div className="flex-1 flex flex-col min-h-0">
      {/* Attendance Summary */}
      <div className="px-4 py-3 flex items-center justify-between border-b border-gray-100">
        <div className="flex items-center gap-8">
          <div>
            <p className="text-[13px] text-gray-500 mb-1">Total Attendance</p>
            <div className="flex items-baseline">
              <span className="text-2xl font-semibold text-gray-800">225</span>
              <span className="text-gray-500 ml-1">/250 days</span>
            </div>
          </div>
          <div>
            <p className="text-[13px] text-gray-500 mb-1">Average Attendance</p>
            <div className="text-2xl font-semibold text-gray-800">89%</div>
          </div>
        </div>

        <div className="relative">
          <select className="appearance-none bg-white border border-gray-200 rounded-lg px-4 py-2 pr-8 text-[13px] text-gray-600 focus:outline-none focus:border-[#29A7E4]">
            <option value="lastYear">Last Year</option>
            <option value="thisYear">This Year</option>
          </select>
          <div className="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Attendance Chart */}
      <div className="px-4 h-[280px] py-3">
        <Line data={attendanceData} options={attendanceOptions} />
      </div>

      {/* Attendance Table */}
      <div className="flex-1 overflow-y-auto px-4 pb-4">
        <table className="w-full">
          <thead>
            <tr className="text-left text-[13px] text-gray-500">
              <th className="py-2 w-16 font-medium">Date</th>
              {attendanceData.labels.map((month) => (
                <th key={month} className="py-2 text-center font-medium">
                  {month}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-[13px]">
            {attendanceTableData.map((row) => (
              <tr key={row.day} className="border-t border-gray-100">
                <td className="py-3 text-gray-800">{row.day}</td>
                {row.months.map((status, index) => (
                  <td key={index} className="py-3 text-center">
                    {status === "present" && (
                      <svg
                        className="w-4 h-4 text-green-500 mx-auto"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                    {status === "absent" && (
                      <svg
                        className="w-4 h-4 text-red-500 mx-auto"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                    {status === "none" && (
                      <span className="text-gray-400">-</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Main Dashboard Component
export default function TeacherDashboard() {
  const [activeTab, setActiveTab] = useState("Time Table");
  const [activeNav, setActiveNav] = useState("My School");

  const tabs = ["Time Table", "Attendance"];
  const attendanceTableData = generateAttendanceTableData();

  return (
    <div className="flex min-h-screen max-h-screen overflow-hidden text-left">
      <div className="flex-1 flex flex-col min-w-0">
        {/* Main Content Area */}
        <main className="flex-1 overflow-hidden p-4 bg-[#f8f9fa]">
          {/* Content Container */}
          <div className="flex gap-4 h-full">
            {/* Teacher Info Card */}
            <div className="w-[300px] min-w-[300px] bg-white rounded-lg shadow-sm flex flex-col">
              <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
                <div className="p-4">
                  {/* Profile Photo Section */}
                  <div className="flex flex-col items-center">
                    <div className="w-[120px] h-[120px] rounded-full bg-pink-100 overflow-hidden mb-3 border-4 border-white shadow-lg">
                      <img
                        src={mockPhotoUrl}
                        alt={teacherData.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex items-center gap-2 mt-2">
                      <h3 className="text-[18px] font-semibold text-gray-800">
                        {teacherData.name}
                      </h3>
                      <button className="text-[#29A7E4] hover:text-[#0662C6] transition-colors">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          className="w-4 h-4"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                        </svg>
                      </button>
                    </div>
                    <p className="text-[13px] text-gray-500 mt-1">
                      # Teacher ID {teacherData.teacherId}
                    </p>
                  </div>

                  {/* Teacher Details */}
                  <div className="mt-6 space-y-4">
                    {/* Gender & Designation */}
                    <div className="grid grid-cols-2 gap-8">
                      <div>
                        <p className="text-[13px] text-gray-500 font-medium">
                          Gender
                        </p>
                        <p className="text-[14px] text-gray-800 font-semibold mt-1">
                          {teacherData.gender}
                        </p>
                      </div>
                      <div>
                        <p className="text-[13px] text-gray-500 font-medium">
                          Designation
                        </p>
                        <p className="text-[14px] text-gray-800 font-semibold mt-1">
                          {teacherData.designation}
                        </p>
                      </div>
                    </div>

                    {/* Contact & Email */}
                    <div>
                      <p className="text-[13px] text-gray-500 font-medium">
                        Contact No.
                      </p>
                      <p className="text-[14px] text-gray-800 font-semibold mt-1">
                        {teacherData.contact}
                      </p>
                    </div>
                    <div>
                      <p className="text-[13px] text-gray-500 font-medium">
                        Email
                      </p>
                      <p className="text-[14px] text-gray-800 font-semibold mt-1">
                        {teacherData.email}
                      </p>
                    </div>

                    {/* Address */}
                    <div>
                      <p className="text-[13px] text-gray-500 font-medium">
                        Address
                      </p>
                      <p className="text-[14px] text-gray-800 font-semibold mt-1 leading-snug">
                        {teacherData.address}
                      </p>
                    </div>

                    {/* Bus Details */}
                    <div>
                      <p className="text-[13px] text-gray-500 font-medium">
                        School Bus Stoppage
                      </p>
                      <p className="text-[14px] text-gray-800 font-semibold mt-1">
                        {teacherData.busDetails.stoppage}
                      </p>
                      <p className="text-[13px] text-gray-500 mt-1">
                        Bus No: {teacherData.busDetails.busNo} | Route-
                        {teacherData.busDetails.route}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Dynamic Content Section */}
            <div className="flex-1 bg-white rounded-lg shadow-sm flex flex-col min-w-0">
              {/* Tabs Section */}
              <div className="border-b border-gray-200 flex-shrink-0">
                <div className="flex overflow-x-auto scrollbar-none">
                  {tabs.map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`py-3 px-6 text-[13px] font-medium relative whitespace-nowrap transition-colors ${
                        activeTab === tab
                          ? "text-[#29A7E4]"
                          : "text-gray-600 hover:text-[#29A7E4]"
                      }`}
                    >
                      {tab}
                      {activeTab === tab && (
                        <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#29A7E4]"></div>
                      )}
                    </button>
                  ))}
                </div>
              </div>

              {/* Tab Content */}
              {activeTab === "Attendance" ? (
                <TeacherAttendanceContent
                  attendanceData={attendanceData}
                  attendanceOptions={attendanceOptions}
                  attendanceTableData={attendanceTableData}
                />
              ) : (
                <div className="flex-1 flex items-center justify-center text-gray-500">
                  <p className="text-[14px]">Time Table Coming Soon</p>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
