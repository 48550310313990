import React, { useState } from "react";

const ForgotPasswordPage = () => {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");

  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    // Send OTP to the phone number
    setStep(2);
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    // Verify OTP
    // If successful, redirect to reset password page or show success message
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-2xl shadow-2xl p-8 text-left">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-extrabold text-gray-800">
            Forgot Password
          </h2>
          <p className="text-gray-500 mt-2">
            {step === 1
              ? "Enter your phone number to receive an OTP."
              : "Enter the OTP sent to your phone."}
          </p>
        </div>

        {step === 1 ? (
          <form onSubmit={handlePhoneSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-black mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent shadow-sm"
                placeholder="Enter your phone number"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition duration-200 shadow-md"
            >
              Send OTP
            </button>
          </form>
        ) : (
          <form onSubmit={handleOtpSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                OTP
              </label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent shadow-sm"
                placeholder="Enter the 4-digit OTP"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 px-4 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition duration-200 shadow-md"
            >
              Verify OTP
            </button>
          </form>
        )}

        {/* Link to go back to the login page */}
        <div className="mt-6 text-center">
          <a href="/" className="text-sm text-blue-600 hover:text-blue-700">
            Back to Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
