import React, { useEffect, useState, useCallback } from "react";
import { FiSearch, FiChevronDown } from "react-icons/fi";
import { useSelector } from "react-redux";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import {
  format,
  parseISO,
  isBefore,
  isEqual,
  isAfter,
  startOfDay,
} from "date-fns";

const EventCard = ({ event }) => {
  const getEventStatus = (eventDate) => {
    const today = startOfDay(new Date());
    const eventStartDate = startOfDay(parseISO(event.rawDate));

    if (isEqual(eventStartDate, today)) {
      return { text: "Today", className: "bg-[#0662C6]" };
    } else if (isAfter(eventStartDate, today)) {
      return { text: "Upcoming", className: "bg-[#FF6B6B]" };
    } else {
      return { text: "Past", className: "bg-[#888888]" };
    }
  };

  const eventStatus = getEventStatus(event.rawDate);

  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-md">
      <div className="relative">
        <img
          src={event.image}
          alt={event.title}
          className="w-full h-32 object-cover"
        />
        <span
          className={`absolute top-1 right-1 ${eventStatus.className} text-white text-[10px] px-1.5 py-0.5 rounded`}
        >
          {eventStatus.text}
        </span>
      </div>
      <div className="p-2">
        <div className="flex justify-between items-center mb-1">
          <h3 className="font-bold text-[#113870] text-xs">{event.title}</h3>
          <div className="flex space-x-0.5">
            {(event?.gender?.toLowerCase() === "both" ||
              event?.gender?.toLowerCase() === "boys") && (
              <span className="bg-[#E7F7FF] text-[#113870] text-[10px] px-1 py-0.5 rounded">
                Boys
              </span>
            )}
            {(event?.gender?.toLowerCase() === "both" ||
              event?.gender?.toLowerCase() === "girls") && (
              <span className="bg-[#E7F7FF] text-[#113870] text-[10px] px-1 py-0.5 rounded">
                Girls
              </span>
            )}
          </div>
        </div>
        <p className="text-[10px] text-gray-600">{event.date}</p>
        <p className="text-[10px] text-gray-600 mb-1">{event.venue}</p>
        <div className="flex justify-between items-end">
          <div>
            {event.prizes.map((prize, index) => (
              <p key={index} className="text-[10px] flex items-center mb-0.5">
                <span className="w-3 h-3 mr-0.5 flex items-center justify-center bg-yellow-400 rounded-full text-white text-[8px]">
                  {index + 1}
                </span>
                <span className="font-medium">{prize.place}</span> -{" "}
                {prize.amount}
              </p>
            ))}
            <p className="text-[10px] mt-0.5 flex items-center">
              <span className="w-3 h-3 mr-0.5 flex items-center justify-center bg-gray-300 rounded-full text-gray-600 text-[8px]">
                ₹
              </span>
              <span className="font-medium">Enrollment Fee</span> - Rs{" "}
              {event.enrollmentFee}
            </p>
          </div>
          <div className="text-right">
            <p className="text-[10px] mb-0.5">Total Prizes Value</p>
            <p className="font-bold text-xs text-[#113870]">
              {event.totalPrizeValue}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function SchoolEventsPage() {
  // State Management
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(6);
  const [eventData, setEventData] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Get Events Data
  const getEventData = async (page = 1, searchQuery = "") => {
    setIsLoading(true);
    try {
      const response = await axiosReq.get(apiEndponts.getEvents, {
        params: {
          school_id: userDetails?.school_id,
          page,
          limit: eventsPerPage,
          search: searchQuery,
        },
      });

      if (response?.records) {
        const formattedEvents = response.records.map((item) => ({
          ...item,
          id: item?._id,
          title: item?.name,
          image: item?.photo?.length ? item?.photo?.[0] : "",
          rawDate: item?.timing?.date,
          date: `${format(
            parseISO(item?.timing?.date),
            "dd MMM yyyy"
          )} - ${format(parseISO(item?.lastDate), "dd MMM yyyy")}, ${
            item?.timing?.time
          }`,
          venue: item?.venue || "Not specified",
          prizes: [
            {
              place: "1st Prize",
              amount: `Rs ${item?.prize?.first?.toLocaleString("en-US")}`,
            },
            {
              place: "2nd Prize",
              amount: `Rs ${item?.prize?.second?.toLocaleString("en-US")}`,
            },
            {
              place: "3rd Prize",
              amount: `Rs ${item?.prize?.third?.toLocaleString("en-US")}`,
            },
          ],
          totalPrizeValue: `Rs ${item?.totalValue?.toLocaleString("en-US")}`,
          enrollmentFee: item?.enrollmentFee?.toLocaleString("en-US"),
          gender: item?.gender || "both",
        }));

        // Sort events by date (most recent first)
        formattedEvents.sort((a, b) => {
          const dateA = parseISO(a.rawDate);
          const dateB = parseISO(b.rawDate);
          return dateB - dateA;
        });

        setEventData(formattedEvents);
        setTotalEvents(response?.totalCount || 0);
      }
    } catch (err) {
      console.error("Error fetching events:", err);
      setEventData([]);
      setTotalEvents(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Debounced Search Handler
  const handleSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setCurrentPage(1);
      getEventData(1, value);
    }, 500),
    []
  );

  // Pagination Handlers
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getEventData(pageNumber, searchTerm);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      getEventData(currentPage - 1, searchTerm);
    }
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(totalEvents / eventsPerPage);
    if (currentPage < maxPage) {
      setCurrentPage((prev) => prev + 1);
      getEventData(currentPage + 1, searchTerm);
    }
  };

  // Initial Load
  useEffect(() => {
    if (userDetails?.school_id) {
      getEventData(currentPage, searchTerm);
    }
  }, [userDetails?.school_id]);

  // Calculate pagination values
  const totalPages = Math.ceil(totalEvents / eventsPerPage);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs overflow-hidden text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Header */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Event {totalEvents}
              </div>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search for month"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="pl-6 pr-6 py-1 border rounded text-xs"
                />
                <FiSearch
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={12}
                />
                <FiChevronDown
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={12}
                />
              </div>
            </div>

            {/* Events Grid */}
            {isLoading ? (
              <div className="text-center py-4">Loading...</div>
            ) : eventData.length === 0 ? (
              <div className="text-center py-4">No events found</div>
            ) : (
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
                {eventData.map((event) => (
                  <EventCard key={event.id} event={event} />
                ))}
              </div>
            )}

            {/* Pagination */}
            {!isLoading && eventData.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {(currentPage - 1) * eventsPerPage + 1} to{" "}
                  {Math.min(currentPage * eventsPerPage, totalEvents)} of{" "}
                  {totalEvents} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>

                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (pageNum) => (
                      <button
                        key={pageNum}
                        onClick={() => handlePageChange(pageNum)}
                        className={`px-3 py-1 rounded ${
                          currentPage === pageNum
                            ? "bg-[#0662C6] text-white"
                            : "bg-white text-[#0662C6] hover:bg-gray-50"
                        } border border-[#C2C2C2]`}
                      >
                        {pageNum}
                      </button>
                    )
                  )}

                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
