import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiChevronDown, FiX } from "react-icons/fi";
import axiosReq from "../../constants/axiosObj";
import apiEndpoints from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { getStatusAccordingToDate } from "../../constants/AppConstant";

function NewsPage() {
  // State Management
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024-25");
  const [selectedNews, setSelectedNews] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(6);
  const [filteredNews, setFilteredNews] = useState([]);
  const [totalNews, setTotalNews] = useState(0);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Get News Data
  const getNewsData = async (searchQuery = "") => {
    setIsLoading(true);
    try {
      const response = await axiosReq.get(apiEndpoints.getNews, {
        params: {
          school_id: userDetails?.school_id,
          session_id: userDetails?.session_id,
          page: currentPage,
          limit: newsPerPage,
          search: searchQuery,
          academic_year: selectedYear,
        },
      });

      if (response?.records) {
        const formattedNews = response.records.map((item) => {
          const dateStatus = getStatusAccordingToDate(item?.date);
          return {
            id: item?._id,
            title: item?.headline || "",
            date: format(parseISO(item?.date), "dd/MM/yyyy"),
            description: item?.content || "",
            image: item?.photo?.length ? item?.photo[0] : "",
            rawDate: item?.date,
            status: dateStatus,
          };
        });

        // Sort by date (most recent first)
        formattedNews.sort((a, b) => {
          const dateA = parseISO(a.rawDate);
          const dateB = parseISO(b.rawDate);
          return dateB - dateA;
        });

        setFilteredNews(formattedNews);
        setTotalNews(response?.totalCount || 0);
      }
    } catch (err) {
      console.error("Error fetching news:", err);
      setFilteredNews([]);
      setTotalNews(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Debounced Search Handler
  const handleSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      getNewsData(value);
    }, 500),
    [selectedYear]
  );

  // Year Change Handler
  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    setCurrentPage(1);
    getNewsData(searchTerm);
  };

  // Modal Handlers
  const toggleDetails = (id) => {
    setSelectedNews(id);
    setShowDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedNews(null);
  };

  // Pagination handlers
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(totalNews / newsPerPage);
    setCurrentPage((prev) => Math.min(prev + 1, maxPage));
  };

  // Effect to fetch data when page or year changes
  useEffect(() => {
    if (userDetails?.school_id) {
      getNewsData(searchTerm);
    }
  }, [currentPage, selectedYear, userDetails?.school_id]);

  // Calculate pagination values
  const totalPages = Math.ceil(totalNews / newsPerPage);
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs overflow-hidden text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* News stats and controls */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] rounded-md font-medium text-xs">
                Total News: {totalNews}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search for news"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <div className="relative">
                  <select
                    value={selectedYear}
                    onChange={handleYearChange}
                    className="border border-[#C2C2C2] rounded-md pl-2 pr-6 py-1 bg-[#F4F4F4]"
                  >
                    <option value="2024-25">2024-25</option>
                    <option value="2023-24">2023-24</option>
                    <option value="2022-23">2022-23</option>
                  </select>
                  <FiChevronDown
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#888888] pointer-events-none"
                    size={12}
                  />
                </div>
              </div>
            </div>

            {/* News cards */}
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
              {isLoading ? (
                <div className="col-span-full text-center py-4">Loading...</div>
              ) : filteredNews.length === 0 ? (
                <div className="col-span-full text-center py-4">
                  No news available
                </div>
              ) : (
                filteredNews.map((news) => (
                  <div
                    key={news.id}
                    className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]"
                  >
                    {/* News card content */}
                    <div className="relative h-32">
                      <img
                        src={news.image || "/default-news-image.jpg"}
                        alt={news.title}
                        className="w-full h-full object-cover"
                      />
                      <span
                        className={`absolute top-1 right-1 px-1.5 py-0.5 rounded-full text-[10px] ${news.status.className}`}
                      >
                        {news.status.text}
                      </span>
                    </div>
                    <div className="p-2">
                      <h2 className="text-sm font-bold mb-1">{news.title}</h2>
                      <p className="text-xs mb-2 line-clamp-2">
                        {news.description}
                      </p>
                      <div className="flex justify-between items-center">
                        <span className="text-xs text-blue-500">
                          Date: {news.date}
                        </span>
                        <button
                          onClick={() => toggleDetails(news.id)}
                          className="bg-[#0662C6] text-white px-2 py-0.5 rounded text-[10px]"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            {/* Pagination */}
            {!isLoading && filteredNews.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {indexOfFirstNews + 1} to{" "}
                  {Math.min(indexOfLastNews, totalNews)} of {totalNews} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>

                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={`px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? "bg-[#0662C6] text-white"
                          : "bg-white text-[#0662C6] hover:bg-gray-50"
                      } border border-[#C2C2C2]`}
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>

      {/* Details Modal */}
      {showDetailsModal && selectedNews && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-4 w-full max-w-md mx-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">News Details</h2>
              <button
                onClick={closeDetailsModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <FiX size={24} />
              </button>
            </div>
            {filteredNews.find((news) => news.id === selectedNews) && (
              <div>
                <img
                  src={
                    filteredNews.find((news) => news.id === selectedNews)
                      .image || "/default-news-image.jpg"
                  }
                  alt={
                    filteredNews.find((news) => news.id === selectedNews).title
                  }
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
                <h3 className="text-lg font-semibold mb-2">
                  {filteredNews.find((news) => news.id === selectedNews).title}
                </h3>
                <p className="text-sm mb-2">
                  Date:{" "}
                  {filteredNews.find((news) => news.id === selectedNews).date}
                </p>
                <p className="text-sm text-[#0662C6]">
                  {
                    filteredNews.find((news) => news.id === selectedNews)
                      .description
                  }
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default NewsPage;
