import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";

function AddHelperForm({
  onSubmitSuccess,
  editMode,
  getHelperInfo,
  editData,
  isEditMode,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    if (isEditMode) {
      let { name, phone, salary, aadhar, licenseNo, address } = editData;
      reset({
        name,
        phone,
        licenseNo,
        salary,
        address,
        aadhar,
      });
    }
  }, [isEditMode]);

  const onSubmit = (data) => {
    // onSubmitSuccess();
    const payload = {
      name: data?.name,
      phone: data?.phone,
      salary: data?.salary,
      school_id: userDetails?.school_id,
      session_id: userDetails?.session_id,
      address: data?.address || "",
      aadharNo: data?.aadharNo,
    };
    const payloadData = new FormData();
    // payloadData.append('photo', data?.photo?.[0]);
    payloadData.append("Data", JSON.stringify(payload));
    if (isEditMode) {
      const url = `${apiEndponts?.updateHelper}/${editData?._id}`;
      axiosReq
        .put(url, payloadData)
        .then((resp) => {
          showToast.success("Helper updated successfully");
          onSubmitSuccess();
          getHelperInfo();
        })
        .catch((err) => console.log(err));
    } else {
      axiosReq
        .post(apiEndponts?.addHelpers, payloadData)
        .then((resp) => {
          showToast.success("Helper created successfully");
          onSubmitSuccess();
          getHelperInfo();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="name"
          >
            Name
          </label>
          <input
            {...register("name", { required: "Name is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
            type="text"
            placeholder="Name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
            {...register("phone", { required: "Phone is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="phone"
            type="text"
            maxLength={10}
            placeholder="Phone"
            onChange={(e) => {
              const value = e.target.value?.replace(/\D/g, "");
              setValue("phone", value);
            }}
          />
          {errors.phone && (
            <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>
          )}
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="aadharNo"
          >
            Aadhar No
          </label>
          <input
            {...register("aadharNo", { required: "Aadhar No is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="aadharNo"
            type="text"
            maxLength={10}
            placeholder="Aadhar No"
            onChange={(e) => {
              const value = e.target.value?.replace(/\D/g, "");
              setValue("aadharNo", value);
            }}
          />
          {errors.aadharNo && (
            <p className="text-red-500 text-sm mt-1">
              {errors.aadharNo.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="salary"
          >
            Salary
          </label>
          <input
            {...register("salary", { required: "Salary is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="salary"
            type="text"
            maxLength={10}
            placeholder="Salary"
            onChange={(e) => {
              const value = e.target.value?.replace(/\D/g, "");
              setValue("salary", value);
            }}
          />
          {errors.salary && (
            <p className="text-red-500 text-sm mt-1">{errors.salary.message}</p>
          )}
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="classRollNo"
          >
            Address
          </label>
          <input
            {...register("address", {
              // required: "Class Roll No. is required",
              // valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="classRollNo"
            type="text"
            placeholder="Address"
          />
          {errors.classRollNo && (
            <p className="text-red-500 text-sm mt-1">
              {errors.classRollNo.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-2 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {isEditMode ? "Update Helper" : "Add Helper"}
        </button>
      </div>
    </form>
  );
}

export default AddHelperForm;
