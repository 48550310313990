import React from "react";
import { Users, BookOpen, Award } from "lucide-react";
import { Button } from "../ui/Button";
import { Stat } from "../ui/Stat";

const Hero = () => {
  const stats = [
    { icon: Users, label: "10k+ Users" },
    { icon: BookOpen, label: "500+ Schools" },
    { icon: Award, label: "98% Satisfaction" },
  ];

  return (
    <div className="pt-24 pb-16 bg-gradient-to-br from-indigo-50 to-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
          <div className="lg:w-1/2 space-y-8">
            <h1 className="text-5xl font-bold text-gray-900 leading-tight">
              Transform Your School Management with
              <span className="text-indigo-600"> EduManage</span>
            </h1>
            <p className="text-xl text-gray-600">
              Streamline administration, enhance communication, and improve
              learning outcomes with our comprehensive school management system.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Button variant="primary" className="text-lg">
                Start Free Trial
              </Button>
              <Button variant="outline" className="text-lg">
                Watch Demo
              </Button>
            </div>
            <div className="flex items-center gap-8 pt-4">
              {stats.map((stat, index) => (
                <Stat key={index} icon={stat.icon} label={stat.label} />
              ))}
            </div>
          </div>
          <div className="lg:w-1/2">
            <img
              src="https://images.unsplash.com/photo-1523240795612-9a054b0db644?auto=format&fit=crop&q=80"
              alt="Students using tablets"
              className="rounded-lg shadow-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
