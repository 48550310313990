import React, { useEffect } from "react";
import PropTypes from "prop-types"; // Add this import

export default function ViewMoreModal({ isOpen, onClose, title, content }) {
  // Close modal on escape key press
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") onClose();
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  // Close modal when clicking outside
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleOutsideClick}
    >
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 max-h-[80vh] overflow-y-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-4 sticky top-0 bg-white pb-2">
          <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 text-2xl transition-colors"
            aria-label="Close modal"
          >
            ×
          </button>
        </div>

        {/* Content */}
        <div className="space-y-4">
          {content.map((item, index) => (
            <div
              key={index}
              className="border-b border-gray-100 pb-4 hover:bg-gray-50 transition-colors rounded-lg p-2"
            >
              <div className="flex items-center gap-4">
                {item.image && (
                  <img
                    src={item.image}
                    alt={item.title || "Content image"}
                    className="w-20 h-20 object-cover rounded-lg shadow-sm"
                    loading="lazy"
                  />
                )}
                <div className="flex-1">
                  {item.title && (
                    <h4 className="font-medium text-gray-800 mb-1">
                      {item.title}
                    </h4>
                  )}
                  <p className="text-gray-600 text-sm">{item.content}</p>
                  {item.date && (
                    <span className="text-xs text-gray-500 mt-2 block">
                      {item.date}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ViewMoreModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string.isRequired,
      date: PropTypes.string,
      image: PropTypes.string,
    })
  ).isRequired,
};
