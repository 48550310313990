"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiEdit2, FiEye, FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import AddERequest from "./AddERequest";

export default function ERequestPage() {
  // State Management
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage] = useState(9);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [totalRequest, setTotalRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddRequestModalOpen, setIsAddRequestModalOpen] = useState(false);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Get E-Request Data with Pagination
  const getERequestData = () => {
    setIsLoading(true);
    axiosReq
      .get(apiEndponts.getCircular, {
        school_id: userDetails.school_id || "",
        model: "request",
        page: currentPage,
        limit: requestsPerPage,
      })
      .then((resp) => {
        const modifiedRequest = resp?.data?.map((item) => ({
          ...item,
          date: item?.createdAt,
          description: item?.content || "",
          requestedFor: item?.requestFor || "",
          quantity: item?.quantity || "",
          provided: item?.provided ? "Yes" : "No",
        }));
        setFilteredRequests(modifiedRequest || []);
        setTotalRequest(resp?.pagination?.total || 0);
      })
      .catch((err) => {
        setFilteredRequests([]);
        setTotalRequest(0);
      })
      .finally(() => setIsLoading(false));
  };

  // Debounced Search Handler
  const handleSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setCurrentPage(1); // Reset to first page when searching
      if (!value.trim()) {
        getERequestData();
        return;
      }
      const lowercasedFilter = value.toLowerCase();
      const filtered = filteredRequests.filter((request) => {
        return Object.keys(request).some((key) =>
          request[key].toString().toLowerCase().includes(lowercasedFilter)
        );
      });
      setFilteredRequests(filtered);
    }, 500),
    [filteredRequests]
  );

  // Modal Handlers
  const handleAddRequest = () => {
    setIsAddRequestModalOpen(true);
  };

  const closeAddRequestModal = () => {
    setIsAddRequestModalOpen(false);
    getERequestData(); // Refresh data after adding
  };

  // Pagination Handlers
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(totalRequest / requestsPerPage);
    if (currentPage < maxPage) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Effect to fetch data when page changes
  useEffect(() => {
    if (userDetails?.school_id) {
      getERequestData();
    }
  }, [currentPage, userDetails?.school_id]);

  // Calculate pagination values
  const totalPages = Math.ceil(totalRequest / requestsPerPage);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* E-Request header */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total E-Request: {totalRequest}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>2024-25</option>
                </select>
                <button
                  className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs"
                  onClick={handleAddRequest}
                >
                  + Add Request
                </button>
              </div>
            </div>

            {/* E-Request table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Date</th>
                    <th className="px-3 py-2">Requested For</th>
                    <th className="px-3 py-2">Quantity</th>
                    <th className="px-3 py-2">Description</th>
                    <th className="px-3 py-2">Provided</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="px-3 py-4 text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredRequests.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="px-3 py-4 text-center">
                        No requests found
                      </td>
                    </tr>
                  ) : (
                    filteredRequests.map((request, index) => (
                      <tr key={index}>
                        <td className="px-3 py-2">{request.date}</td>
                        <td className="px-3 py-2">{request.requestedFor}</td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {request.quantity}
                        </td>
                        <td className="px-3 py-2">{request.description}</td>
                        <td className="px-3 py-2">{request.provided}</td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button
                              className="text-[#0662C6]"
                              onClick={() => {
                                request?.photo?.forEach((element) => {
                                  window.open(element, "_blank");
                                });
                              }}
                            >
                              <FiEye size={14} />
                            </button>
                            <button className="text-[#0662C6]">
                              <FiEdit2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {!isLoading && filteredRequests.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {(currentPage - 1) * requestsPerPage + 1} to{" "}
                  {Math.min(currentPage * requestsPerPage, totalRequest)} of{" "}
                  {totalRequest} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>

                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (pageNum) => (
                      <button
                        key={pageNum}
                        onClick={() => handlePageChange(pageNum)}
                        className={`px-3 py-1 rounded ${
                          currentPage === pageNum
                            ? "bg-[#0662C6] text-white"
                            : "bg-white text-[#0662C6] hover:bg-gray-50"
                        } border border-[#C2C2C2]`}
                      >
                        {pageNum}
                      </button>
                    )
                  )}

                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Add Request Modal */}
            {isAddRequestModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Add Request</h2>
                    <button
                      onClick={closeAddRequestModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddERequest
                    onSubmitSuccess={closeAddRequestModal}
                    getERequestData={getERequestData}
                  />
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
