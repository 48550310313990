import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  FaUserGraduate,
  FaChalkboardTeacher,
  FaUsers,
  FaMoneyBillWave,
  FaBus,
  FaHandsHelping,
  FaSchool,
  FaChartLine,
  FaBell,
  FaSearch,
  FaCalendar,
  FaRoute,
  FaCar,
  FaMapMarked,
  FaMoneyBill,
} from "react-icons/fa";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";
import ViewMoreModal from "./ViewMoreModal";
import axiosReq from "../../constants/axiosObj";
import apiEndpoints from "../../constants/endpoints";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
);

const DashboardPage = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // State Management
  const [selectedYear, setSelectedYear] = useState("2023-24");
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", content: [] });
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // API Integration
  const fetchDashboardData = async (month) => {
    setIsLoading(true);
    try {
      const params = {
        school_id: userDetails?.school_id,
        session_id: userDetails?.session_id,
        month: month,
      };

      const response = await axiosReq.get(apiEndpoints.getDashboard, params);

      if (response?.status === "success") {
        setDashboardData(response.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails?.school_id && userDetails?.session_id) {
      const currentMonth = new Date().getMonth() + 1;
      fetchDashboardData(currentMonth);
    }
  }, [userDetails]);

  const handleViewMore = (section, content) => {
    setModalContent({
      title: section,
      content: content,
    });
    setIsModalOpen(true);
  };
  // Memoized Data
  const statCards = useMemo(() => {
    if (!dashboardData?.stats) return [];

    const stats = dashboardData.stats;
    return [
      {
        title: stats.students.title,
        value: stats.students.count,
        icon: <FaUserGraduate className="text-2xl text-blue-600" />,
        bgColor: "bg-blue-50",
        trend: stats.students.trend,
      },
      {
        title: stats.teachers.title,
        value: stats.teachers.count,
        icon: <FaChalkboardTeacher className="text-2xl text-amber-600" />,
        bgColor: "bg-amber-50",
        trend: stats.teachers.trend,
      },
      {
        title: stats.parents.title,
        value: stats.parents.count,
        icon: <FaUsers className="text-2xl text-green-600" />,
        bgColor: "bg-green-50",
        trend: stats.parents.trend,
      },
      {
        title: stats.revenue.title,
        value: `₹ ${stats.revenue.amount.toLocaleString()}`,
        icon: <FaMoneyBillWave className="text-2xl text-purple-600" />,
        bgColor: "bg-purple-50",
        trend: stats.revenue.trend,
      },
      {
        title: stats.buses.title,
        value: stats.buses.count,
        icon: <FaBus className="text-2xl text-amber-600" />,
        bgColor: "bg-amber-50",
        trend: stats.buses.trend,
      },
      {
        title: stats.drivers.title,
        value: stats.drivers.count,
        icon: <FaCar className="text-2xl text-blue-600" />,
        bgColor: "bg-blue-50",
        trend: stats.drivers.trend,
      },
      {
        title: stats.helpers.title,
        value: stats.helpers.count,
        icon: <FaHandsHelping className="text-2xl text-amber-600" />,
        bgColor: "bg-amber-50",
        trend: stats.helpers.trend,
      },
      {
        title: stats.classes.title,
        value: stats.classes.count,
        icon: <FaSchool className="text-2xl text-green-600" />,
        bgColor: "bg-green-50",
        trend: stats.classes.trend,
      },
      {
        title: stats.expenses.title,
        value: `₹ ${stats.expenses.amount.toLocaleString()}`,
        icon: <FaMoneyBill className="text-2xl text-purple-600" />,
        bgColor: "bg-purple-50",
        trend: stats.expenses.trend,
      },
      {
        title: stats.routes.title,
        value: stats.routes.count,
        icon: <FaMapMarked className="text-2xl text-amber-600" />,
        bgColor: "bg-amber-50",
        trend: stats.routes.trend,
      },
    ];
  }, [dashboardData?.stats]);

  const chartData = useMemo(
    () => ({
      incomeVsExpenses: {
        labels: dashboardData?.charts?.incomeVsExpenses?.labels || [],
        datasets: dashboardData?.charts?.incomeVsExpenses?.datasets || [],
      },
      feeStatus: {
        labels: dashboardData?.charts?.feeStatus?.labels || [],
        datasets: dashboardData?.charts?.feeStatus?.datasets || [],
      },
      studentDistribution: {
        labels: dashboardData?.charts?.studentDistribution?.labels || [],
        datasets: dashboardData?.charts?.studentDistribution?.datasets || [],
      },
    }),
    [dashboardData?.charts]
  );

  const informationData = useMemo(
    () => ({
      circular:
        dashboardData?.information?.schoolcircular?.map((item) => ({
          content: item.title,
          date: new Date(item.date).toLocaleDateString(),
        })) || [],
      notices:
        dashboardData?.information?.notices?.map((item) => ({
          content: item.content,
          date: new Date(item.date).toLocaleDateString(),
        })) || [],
      news:
        dashboardData?.information?.news?.map((item) => ({
          title: item.title,
          content: item.content,
          date: new Date(item.date).toLocaleDateString(),
        })) || [],
      holidays:
        dashboardData?.information?.holidays?.map((item) => ({
          title: item.name,
          date: new Date(item.date).toLocaleDateString(),
          content: item.month,
        })) || [],
    }),
    [dashboardData?.information]
  );

  const activitiesData = useMemo(
    () => ({
      schoolEvents:
        dashboardData?.activities?.events?.map((event) => ({
          title: event.title,
          content: `${event.venue}, ${event.city}`,
          date: new Date(event.date).toLocaleDateString(),
          image: event.photo[0],
        })) || [],
      classActivity:
        dashboardData?.activities?.classactivity?.map((activity) => ({
          title: activity.title,
          content: activity.description,
          date: new Date(activity.date).toLocaleDateString(),
          image: activity.photo[0] || "/placeholder.jpg",
        })) || [],
      schoolActivity:
        dashboardData?.activities?.schoolactivity?.map((activity) => ({
          title: activity.title,
          content: activity.description,
          date: new Date(activity.date).toLocaleDateString(),
          image: activity.photo[0] || "/placeholder.jpg",
        })) || [],
    }),
    [dashboardData?.activities]
  );
  // Loading and Error States
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen text-red-600">
        {error}
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white text-left text-black p-2">
      <main className="max-w-[1800px] mx-auto px-6 py-6">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-amber-600">
            Welcome to Your School Hub – Learn, Grow, Succeed!
          </h2>
          <div className="flex items-center space-x-2">
            <select
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e.target.value);
                fetchDashboardData(months.indexOf(e.target.value) + 1);
              }}
              className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
            >
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
            >
              <option value="2023-24">2023-24</option>
            </select>
          </div>
        </div>
      </main>
      {/* Stats Grid */}
      <div className="grid grid-cols-5 gap-4 mb-6 drop-shadow-lg">
        {statCards.map((stat, index) => (
          <div
            key={index}
            className={`${stat.bgColor} rounded-lg p-4 transition-transform hover:scale-[1.02] cursor-pointer`}
          >
            <div className="flex justify-between items-center">
              <div>
                <p className="text-gray-600 text-sm">{stat.title}</p>
                <p className="text-xl font-semibold text-gray-800 mt-1">
                  {stat.value}
                </p>
                <p
                  className={`text-xs ${
                    stat.trend.startsWith("+")
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {stat.trend}
                </p>
              </div>
              <div className={`${stat.bgColor} p-3 rounded-full`}>
                {stat.icon}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Charts Section */}
      <div className="grid grid-cols-2 gap-4 mb-6 drop-shadow-lg">
        {/* Income vs Expenses Chart */}
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Income vs Expenses
          </h3>
          <div className="h-[300px]">
            <Line
              data={chartData.incomeVsExpenses}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    grid: { color: "#E5E7EB" },
                  },
                  x: {
                    grid: { color: "#E5E7EB" },
                  },
                },
                plugins: {
                  legend: {
                    position: "top",
                    align: "end",
                    labels: { boxWidth: 12, padding: 15, font: { size: 12 } },
                  },
                },
              }}
            />
          </div>
        </div>

        {/* Fee Status and Student Distribution */}
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-white rounded-lg p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-8">
              Fee Status
            </h3>
            <div className="h-[250px]">
              <Bar
                data={chartData.feeStatus}
                options={{
                  indexAxis: "y",
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: { legend: { display: false } },
                  scales: {
                    x: { grid: { display: false } },
                    y: { grid: { display: false } },
                  },
                }}
              />
            </div>
          </div>
          <div className="bg-white rounded-lg p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-8">
              Student Distribution
            </h3>
            <div className="h-[250px]">
              <Doughnut
                data={chartData.studentDistribution}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  cutout: "60%",
                  plugins: {
                    legend: {
                      position: "bottom",
                      labels: { boxWidth: 12, padding: 15, font: { size: 11 } },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Information Sections */}
      <div className="grid grid-cols-4 gap-6 mb-6 drop-shadow-lg">
        {/* Circular Section */}
        <div className="bg-white rounded-lg p-6">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-8 h-8 rounded-full bg-amber-50 flex items-center justify-center">
              <FaBell className="text-xl text-amber-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-800">Circular</h3>
          </div>
          <ul className="space-y-3">
            {informationData.circular.slice(0, 3).map((item, index) => (
              <li key={index} className="flex items-start gap-2">
                <span className="text-amber-600 mt-1">•</span>
                <div>
                  <p className="text-sm text-black">{item.content}</p>
                  <p className="text-xs text-gray-500">{item.date}</p>
                </div>
              </li>
            ))}
          </ul>
          <button
            onClick={() => handleViewMore("Circular", informationData.circular)}
            className="mt-4 text-blue-600 text-sm font-medium hover:text-blue-700"
          >
            View more
          </button>
        </div>

        {/* Notice Section */}
        <div className="bg-white rounded-lg p-6">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-8 h-8 rounded-full bg-purple-50 flex items-center justify-center">
              <FaBell className="text-xl text-purple-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-800">Notice</h3>
          </div>
          <ul className="space-y-3">
            {informationData.notices.slice(0, 3).map((item, index) => (
              <li key={index} className="flex items-start gap-2">
                <span className="text-purple-600 mt-1">•</span>
                <div>
                  <p className="text-sm text-black">{item.content}</p>
                  <p className="text-xs text-gray-500">{item.date}</p>
                </div>
              </li>
            ))}
          </ul>
          <button
            onClick={() => handleViewMore("Notices", informationData.notices)}
            className="mt-4 text-blue-600 text-sm font-medium hover:text-blue-700"
          >
            View more
          </button>
        </div>

        {/* News Section */}
        <div className="bg-white rounded-lg p-6">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center">
              <FaBell className="text-xl text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-800">News</h3>
          </div>
          <ul className="space-y-3">
            {informationData.news.slice(0, 3).map((item, index) => (
              <li key={index} className="flex items-start gap-2">
                <span className="text-blue-600 mt-1">•</span>
                <div>
                  <p className="text-sm font-medium text-gray-800">
                    {item.title}
                  </p>
                  <p className="text-sm text-gray-600">{item.content}</p>
                  <p className="text-xs text-gray-500">{item.date}</p>
                </div>
              </li>
            ))}
          </ul>
          <button
            onClick={() => handleViewMore("News", informationData.news)}
            className="mt-4 text-blue-600 text-sm font-medium hover:text-blue-700"
          >
            View more
          </button>
        </div>

        {/* Holidays Section */}
        <div className="bg-white rounded-lg p-6">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-8 h-8 rounded-full bg-red-50 flex items-center justify-center">
              <FaCalendar className="text-xl text-red-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-800">Holidays</h3>
          </div>
          <ul className="space-y-4">
            {informationData.holidays.slice(0, 3).map((holiday, index) => (
              <li key={index} className="flex items-center gap-4">
                <div className="text-center min-w-[50px]">
                  <div className="text-xl font-bold text-red-600">
                    {new Date(holiday.date).getDate()}
                  </div>
                  <div className="text-xs text-gray-500">
                    {new Date(holiday.date).toLocaleString("default", {
                      month: "short",
                    })}
                  </div>
                </div>
                <div>
                  <div className="font-medium text-gray-800">
                    {holiday.title}
                  </div>
                  <div className="text-xs text-gray-500">{holiday.content}</div>
                </div>
              </li>
            ))}
          </ul>
          <button
            onClick={() => handleViewMore("Holidays", informationData.holidays)}
            className="mt-4 text-blue-600 text-sm font-medium hover:text-blue-700"
          >
            View more
          </button>
        </div>
      </div>
      {/* Activities Section */}
      <div className="grid grid-cols-3 gap-6 drop-shadow-lg">
        {/* Co-Curricular Activity */}
        <div className="bg-white rounded-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full bg-pink-50 flex items-center justify-center">
                <FaUsers className="text-xl text-pink-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-800">
                Co-Curricular Activity
              </h3>
            </div>
          </div>
          {activitiesData.schoolActivity[0] && (
            <>
              <div className="relative h-48 rounded-lg overflow-hidden mb-3 group">
                <img
                  src={activitiesData.schoolActivity[0].image}
                  alt="Activity"
                  className="w-full h-full object-cover transition-transform group-hover:scale-105 duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                  <p className="text-sm font-medium">
                    {activitiesData.schoolActivity[0].date}
                  </p>
                </div>
              </div>
              <div className="space-y-2">
                <h4 className="font-medium text-gray-800">
                  {activitiesData.schoolActivity[0].title}
                </h4>
                <p className="text-sm text-gray-600 line-clamp-2">
                  {activitiesData.schoolActivity[0].content}
                </p>
              </div>
            </>
          )}
          <button
            onClick={() =>
              handleViewMore(
                "Co-Curricular Activities",
                activitiesData.schoolActivity
              )
            }
            className="mt-4 text-blue-600 text-sm font-medium hover:text-blue-700"
          >
            View more
          </button>
        </div>

        {/* Class Activity */}
        <div className="bg-white rounded-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full bg-yellow-50 flex items-center justify-center">
                <FaSchool className="text-xl text-yellow-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-800">
                Class Activity
              </h3>
            </div>
          </div>
          {activitiesData.classActivity[0] && (
            <>
              <div className="relative h-48 rounded-lg overflow-hidden mb-3 group">
                <img
                  src={activitiesData.classActivity[0].image}
                  alt="Activity"
                  className="w-full h-full object-cover transition-transform group-hover:scale-105 duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                  <p className="text-sm font-medium">
                    {activitiesData.classActivity[0].date}
                  </p>
                </div>
              </div>
              <div className="space-y-2">
                <h4 className="font-medium text-gray-800">
                  {activitiesData.classActivity[0].title}
                </h4>
                <p className="text-sm text-gray-600 line-clamp-2">
                  {activitiesData.classActivity[0].content}
                </p>
              </div>
            </>
          )}
          <button
            onClick={() =>
              handleViewMore("Class Activities", activitiesData.classActivity)
            }
            className="mt-4 text-blue-600 text-sm font-medium hover:text-blue-700"
          >
            View more
          </button>
        </div>

        {/* School Events */}
        <div className="bg-white rounded-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center">
                <FaCalendar className="text-xl text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-800">
                School Events
              </h3>
            </div>
          </div>
          {activitiesData.schoolEvents[0] && (
            <>
              <div className="relative h-48 rounded-lg overflow-hidden mb-3 group">
                <img
                  src={activitiesData.schoolEvents[0].image}
                  alt="Event"
                  className="w-full h-full object-cover transition-transform group-hover:scale-105 duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                  <p className="text-sm font-medium">
                    {activitiesData.schoolEvents[0].date}
                  </p>
                </div>
              </div>
              <div className="space-y-2">
                <h4 className="font-medium text-gray-800">
                  {activitiesData.schoolEvents[0].title}
                </h4>
                <p className="text-sm text-gray-600 line-clamp-2">
                  {activitiesData.schoolEvents[0].content}
                </p>
              </div>
            </>
          )}
          <button
            onClick={() =>
              handleViewMore("School Events", activitiesData.schoolEvents)
            }
            className="mt-4 text-blue-600 text-sm font-medium hover:text-blue-700"
          >
            View more
          </button>
        </div>
      </div>
      <ViewMoreModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalContent.title}
        content={modalContent.content}
      />
      ;
    </div>
  );
};

export default DashboardPage;
