import React from "react";
import PropTypes from "prop-types";
import { FiX } from "react-icons/fi";

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, itemName }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-gray-600 rounded-lg w-[400px] max-h-[90vh] overflow-hidden shadow-md">
        {/* Header */}
        <div className="flex items-center justify-between border-b border-[#E5E5E5] px-4 py-2.5">
          <h3 className="font-medium text-white">Confirm Deletion</h3>
          <button
            onClick={onClose}
            className="text-[#888888] hover:text-[#B53D3E] transition-colors"
          >
            <FiX size={18} />
          </button>
        </div>

        {/* Content */}
        <div className="p-4">
          <p className="text-sm text-white mb-4">
            Are you sure you want to delete {itemName}? This action cannot be
            undone.
          </p>

          {/* Actions */}
          <div className="flex justify-end gap-2">
            <button
              onClick={onClose}
              className="px-4 py-2 text-xs font-medium text-white hover:text-[#1E1E1E] transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 text-xs font-medium bg-[#B53D3E] text-white rounded hover:bg-[#8B2E2F] transition-colors"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
};

export default DeleteConfirmationModal;
