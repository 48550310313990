import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaUserFriends,
  FaUserGraduate,
  FaChalkboardTeacher,
  FaBook,
  FaBullhorn,
  FaWallet,
  FaFileInvoice,
  FaRegNewspaper,
  FaTasks,
  FaChartLine,
  FaMicrophoneAlt,
  FaSchool,
  FaClipboardList,
  FaChalkboard,
  FaCalendarCheck,
  FaUmbrellaBeach,
  FaImage,
  FaCar,
  FaUsers,
  FaUserCheck,
  FaBus,
  FaVideo,
  FaRoute,
  FaExclamationCircle,
  FaPaperPlane,
  FaCalendarAlt,
  FaNewspaper,
  FaBasketballBall,
  FaIdBadge,
} from "react-icons/fa";

const Sidebar = ({ activeNav }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedPath, setSelectedPath] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarContents = {
    "My School": [
      { icon: FaUserGraduate, name: "Students", path: "/students" },
      {
        icon: FaUserCheck,
        name: "Students Attendance",
        path: "/studentsattendance",
      },
      {
        icon: FaUserCheck,
        name: "Teachers Attendance",
        path: "/teachersattendance",
      },
      { icon: FaUserFriends, name: "Parents", path: "/parents" },
      { icon: FaChalkboardTeacher, name: "Classes", path: "/classes" },
      { icon: FaMicrophoneAlt, name: "Lectures", path: "/lectures" },
      { icon: FaChartLine, name: "Result", path: "/results" },
      { icon: FaTasks, name: "Homework", path: "/homework" },
      { icon: FaBook, name: "Timetable", path: "/timetable" },
      { icon: FaBullhorn, name: "Notice", path: "/notices" },
      { icon: FaRegNewspaper, name: "Circular", path: "/circulars" },
      {
        icon: FaSchool,
        name: "Co-Curricular Activity",
        path: "/co-curricular",
      },
      { icon: FaChalkboard, name: "Class Activity", path: "/activity" },
      { icon: FaUserFriends, name: "PTM", path: "/ptm" },
      { icon: FaCalendarCheck, name: "Students Leave", path: "/studentsleave" },
      { icon: FaCalendarCheck, name: "Teachers Leave", path: "/teachersleave" },
      { icon: FaUmbrellaBeach, name: "Holiday", path: "/holiday" },
      { icon: FaImage, name: "Banner", path: "/banners" },
      // { icon: FaClipboardList, name: "Expense", path: "/expense" },
    ],
    Dashboard: [
      { icon: FaImage, name: "School Dashboard", path: "/dashboard" },
      { icon: FaImage, name: "Student Dashboard", path: "/studentdashboard" },
      { icon: FaImage, name: "Teacher Dashboard", path: "/teacherdashboard" },
    ],
    Staff: [
      { icon: FaChalkboardTeacher, name: "Teacher", path: "/teachers" },
      { icon: FaCar, name: "Driver", path: "/drivers" },
      { icon: FaUserFriends, name: "Helper", path: "/helpers" },
    ],
    Users: [{ icon: FaUsers, name: "Users", path: "/users" }],
    Fee: [
      { icon: FaWallet, name: "Student Fee", path: "/studentfees" },
      { icon: FaFileInvoice, name: "Fee Generation", path: "/feegeneration" },
    ],

    Buses: [
      { icon: FaBus, name: "All Bus", path: "/buses" },
      { icon: FaVideo, name: "GPS + Camera", path: "/bus-tracking" },
      { icon: FaRoute, name: "Routes", path: "/routes" },
    ],
    Complaints: [
      { icon: FaExclamationCircle, name: "Complain", path: "/complaints" },
    ],
    Expenses: [{ icon: FaClipboardList, name: "Expenses", path: "/expense" }],
    SchoolEG: [
      { icon: FaPaperPlane, name: "e-Request", path: "/e-request" },
      { icon: FaCalendarAlt, name: "Events", path: "/events" },
      { icon: FaNewspaper, name: "News", path: "/news" },
    ],
    SuperAdmin: [
      { icon: FaSchool, name: "All School", path: "/allschools" },
      { icon: FaChalkboardTeacher, name: "Councelling", path: "/councellings" },
      {
        icon: FaBasketballBall,
        name: "Advertisements",
        path: "/advertisements",
      },
      { icon: FaBook, name: "Tuition", path: "/tuitions" },
      { icon: FaIdBadge, name: "Event Enrolled", path: "/enrolledstudents" },
    ],
  };
  const currentSidebarItems = sidebarContents[activeNav] || [];

  useEffect(() => {
    const isMyPathAvailable = currentSidebarItems?.find(
      (item) => item?.path === location.pathname
    );
    if (isMyPathAvailable) {
      navigate(isMyPathAvailable?.path);
    } else {
      navigate(currentSidebarItems[0]?.path);
    }
  }, [activeNav]);
  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  const handleMouseEnter = () => setIsExpanded(true);
  const handleMouseLeave = () => setIsExpanded(false);

  const handleItemClick = (path) => {
    setSelectedPath(path);
    navigate(path);
  };

  return (
    <div
      className={`flex flex-col pt-2 pb-4 flex-shrink-0 border-r border-[#1A252F] drop-shadow-md bg-[#2C3E50] transition-all duration-500 ease-in-out h-screen ${
        isExpanded ? "w-56" : "w-12"
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ transitionProperty: "width" }}
    >
      <div className="flex-1">
        {currentSidebarItems.map(({ icon: Icon, name, path }, index) => (
          <button
            key={index}
            onClick={() => handleItemClick(path)}
            className={`flex items-center rounded-md mb-2 transition-all duration-500 ease-in-out w-full ${
              selectedPath === path
                ? "bg-[#ECF0F1] text-[#2C3E50]" // Reversed color theme for selected items
                : "bg-[#2C3E50] text-[#ECF0F1] hover:bg-[#34495E] hover:text-[#3498DB]" // Default color theme
            }`}
            title={name}
          >
            <div className="flex items-center w-full py-1">
              <Icon
                size={20}
                className={`ml-3 transition-transform duration-500 ease-in-out ${
                  selectedPath === path ? "text-[#2C3E50]" : ""
                }`}
              />
              <span
                className={`ml-3 overflow-hidden transition-all duration-500 ease-in-out ${
                  isExpanded ? "opacity-100 max-w-full" : "opacity-0 max-w-0"
                }`}
                style={{
                  whiteSpace: "nowrap",
                  transition: "max-width 0.5s, opacity 0.5s",
                }}
              >
                {name}
              </span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
