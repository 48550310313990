"use client";
import React, { useState, useEffect } from "react";
import { FiEdit2, FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { format, parseISO } from "date-fns";
import AddExpensePage from "./AddExpensePage";

export default function ExpensesPage() {
  // State Management
  const [selectedYear, setSelectedYear] = useState("2024-25");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [expensesPerPage, setExpensesPerPage] = useState(9);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Get Expense Data
  const getExpenseData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosReq.get(apiEndponts.getCircular, {
        school_id: userDetails?.school_id,
        model: "expense",
        page: currentPage,
        limit: expensesPerPage,
        year: selectedYear,
        month: selectedMonth,
        type: selectedType, // Ensure this is included
      });

      if (response?.data) {
        const formattedExpenses = response?.data.map((item) => ({
          id: item?._id,
          date: format(parseISO(item?.date), "dd/MM/yyyy"),
          amount: item?.amount
            ? `Rs ${item?.amount?.toLocaleString("en-IN")}`
            : "",
          expenseType: item?.expenseType,
          attachment: "View Attachment",
          description: item?.description || "",
          photo: item?.photo || [],
        }));

        setFilteredExpenses(formattedExpenses);
        setTotalExpenses(response?.pagination?.total || 0);
        setTotalPages(response?.pagination?.pages || 0);
        setExpensesPerPage(response?.pagination?.pageSize || 9);
      }
    } catch (err) {
      console.error("Error fetching expenses:", err);
      setFilteredExpenses([]);
      setTotalExpenses(0);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Filter Handlers
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    setCurrentPage(1);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setCurrentPage(1);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setCurrentPage(1);
  };

  // Modal Handlers
  const handleAddExpense = () => {
    setIsAddExpenseModalOpen(true);
  };

  const closeAddExpenseModal = () => {
    setIsAddExpenseModalOpen(false);
  };

  // Pagination handlers
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Effect to fetch data when filters change
  useEffect(() => {
    if (userDetails?.school_id) {
      getExpenseData();
    }
  }, [
    currentPage,
    selectedYear,
    selectedMonth,
    selectedType,
    userDetails?.school_id,
  ]);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Expenses header */}
            <div className="flex justify-between items-center mb-3">
              <div className="text-base font-medium">School Expenses</div>
              <div className="flex items-center space-x-2">
                <select
                  value={selectedType}
                  onChange={handleTypeChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                >
                  <option value="">Select Expense Type</option>
                  <option value="salary">Salary</option>
                  <option value="maintenance">Maintenance</option>
                  <option value="utilities">Utilities</option>
                  {/* Add more expense types as needed */}
                </select>
                <select
                  value={selectedYear}
                  onChange={handleYearChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                >
                  <option value="2024-25">2024-25</option>
                  <option value="2023-24">2023-24</option>
                  <option value="2022-23">2022-23</option>
                </select>
                <select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                >
                  <option value="">Select Month</option>
                  {Array.from({ length: 12 }, (_, i) => {
                    const month = new Date(0, i).toLocaleString("default", {
                      month: "long",
                    });
                    return (
                      <option key={i} value={i + 1}>
                        {month}
                      </option>
                    );
                  })}
                </select>
                <button
                  onClick={handleAddExpense}
                  className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs"
                >
                  + Add Expense
                </button>
              </div>
            </div>

            {/* Expenses table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Date</th>
                    <th className="px-3 py-2">Amount</th>
                    <th className="px-3 py-2">Expense Type</th>
                    <th className="px-3 py-2">Attachment</th>
                    <th className="px-3 py-2">Description</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredExpenses.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        No expenses found
                      </td>
                    </tr>
                  ) : (
                    filteredExpenses.map((expense) => (
                      <tr key={expense.id}>
                        <td className="px-3 py-2">{expense.date}</td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {expense.amount}
                        </td>
                        <td className="px-3 py-2">{expense.expenseType}</td>
                        <td className="px-3 py-2">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              expense?.photo?.forEach((element) => {
                                window.open(element, "_blank");
                              });
                            }}
                            className="text-[#0662C6]"
                          >
                            {expense.attachment}
                          </a>
                        </td>
                        <td className="px-3 py-2">{expense.description}</td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button className="text-[#0662C6]">
                              <FiEdit2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {!isLoading && filteredExpenses.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {(currentPage - 1) * expensesPerPage + 1} to{" "}
                  {Math.min(currentPage * expensesPerPage, totalExpenses)} of{" "}
                  {totalExpenses} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>

                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={`px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? "bg-[#0662C6] text-white"
                          : "bg-white text-[#0662C6] hover:bg-gray-50"
                      } border border-[#C2C2C2]`}
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Add Expense Modal */}
            {isAddExpenseModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Add Expense</h2>
                    <button
                      onClick={closeAddExpenseModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddExpensePage
                    onSubmitSuccess={() => {
                      closeAddExpenseModal();
                      getExpenseData();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
