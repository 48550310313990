"use client";
import React, { useState, useCallback } from "react";
import { FiSearch, FiEdit2, FiTrash2, FiInfo } from "react-icons/fi";
import { Switch } from "@headlessui/react";

import Pagination from "../../components/Pagination";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

// Sample data structure for schools
const schoolsData = Array(6).fill({
  logo: "/school-logo.png",
  name: "Aatreya Academy",
  address: "Rajiv Colony Hno. 56, Sector 14\nGurgaon, Haryana - 122001",
  phoneNo: "8956231589",
  platformFee: "Rs 100",
  students: "1500",
  pricing: "50 Rs",
  expiryDate: "15 Aug 2024",
  whiteLabel: "YES",
  isActive: true,
});

export default function SchoolEGPage() {
  const [activeNav, setActiveNav] = useState("All School");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [schools, setSchools] = useState(schoolsData);
  const [filteredSchools, setFilteredSchools] = useState(schools);
  const schoolsPerPage = 6;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);

  const handleToggle = (index) => {
    const updatedSchools = [...schools];
    updatedSchools[index] = {
      ...updatedSchools[index],
      isActive: !updatedSchools[index].isActive,
    };
    setSchools(updatedSchools);
    setFilteredSchools(updatedSchools);
  };

  const handleDeleteClick = (school) => {
    setSelectedNotice(school);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleteModalOpen(false);
    setSelectedNotice(null);
  };

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Schools header */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Schools: 50
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 bg-[#F4F4F4]"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <button className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200">
                  + Add School
                </button>
              </div>
            </div>

            {/* Schools table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">School Name</th>
                    <th className="px-3 py-2">Phone No.</th>
                    <th className="px-3 py-2">Platform Fee</th>
                    <th className="px-3 py-2">Students</th>
                    <th className="px-3 py-2">Pricing</th>
                    <th className="px-3 py-2">Expiry Date</th>
                    <th className="px-3 py-2">White Label</th>
                    <th className="px-3 py-2">Is Active</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {filteredSchools.map((school, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">
                        <div className="flex items-center space-x-2">
                          <img src={school.logo} alt="" className="w-8 h-8" />
                          <div>
                            <div className="font-medium">{school.name}</div>
                            <div className="text-[#888888] whitespace-pre-line">
                              {school.address}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-3 py-2">
                        <div>{school.phoneNo}</div>
                        <div className="text-[#0662C6] cursor-pointer">
                          Change Password
                        </div>
                      </td>
                      <td className="px-3 py-2 text-[#0662C6]">
                        {school.platformFee}
                      </td>
                      <td className="px-3 py-2">{school.students}</td>
                      <td className="px-3 py-2 text-[#0662C6]">
                        {school.pricing}
                      </td>
                      <td className="px-3 py-2">{school.expiryDate}</td>
                      <td className="px-3 py-2">{school.whiteLabel}</td>
                      <td className="px-3 py-2">
                        <Switch
                          checked={school.isActive}
                          onChange={() => handleToggle(index)}
                          className={`${
                            school.isActive ? "bg-[#00A76F]" : "bg-gray-300"
                          } relative inline-flex h-4 w-8 items-center rounded-full transition-colors focus:outline-none`}
                        >
                          <span
                            className={`${
                              school.isActive
                                ? "translate-x-4"
                                : "translate-x-1"
                            } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
                          />
                        </Switch>
                      </td>
                      <td className="px-3 py-2">
                        <div className="flex space-x-2">
                          <button className="text-[#0662C6]">
                            <FiInfo size={14} />
                          </button>
                          <button className="text-[#0662C6]">
                            <FiEdit2 size={14} />
                          </button>
                          <button
                            className="text-[#B53D3E]"
                            onClick={() => handleDeleteClick(school)}
                          >
                            <FiTrash2 size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredSchools.length / schoolsPerPage)}
              onNextPage={() => setCurrentPage((prev) => prev + 1)}
              onPrevPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              isLastPage={
                currentPage * schoolsPerPage >= filteredSchools.length
              }
              isFirstPage={currentPage === 1}
            />
          </div>
        </main>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        itemName={selectedNotice ? "this school" : ""}
      />
    </div>
  );
}
