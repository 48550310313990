import React, { useState } from "react";
import { showToast } from "../../utils/toast";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";

const toastOptions = {
  success: {
    style: {
      background: "#4CAF50",
      color: "white",
    },
    duration: 3000,
  },
  error: {
    style: {
      background: "#EF4444",
      color: "white",
    },
    duration: 3000,
  },
};

export default function EditUserForm({ user, onSubmitSuccess }) {
  const [formData, setFormData] = useState({
    name: user.name,
    phone: user.phone,
    role: user.role || "User",
    access: user.originalAccess,
    isActive: user.isActive,
    isDeleted: user.isDeleted || false,
  });

  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAccessChange = (module) => {
    setFormData((prev) => ({
      ...prev,
      access: {
        ...prev.access,
        [module]: !prev.access[module],
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      await axiosReq.put(`${apiEndponts.updateUser}/${user.id}`, formData);
      showToast.success("User updated successfully", toastOptions.success);
      onSubmitSuccess();
    } catch (err) {
      const errorMessage = err.response?.data?.message || "Error updating user";
      setError(errorMessage);
      showToast.error(errorMessage, toastOptions.error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Phone</label>
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Access Rights
        </label>
        <div className="mt-2 grid grid-cols-2 gap-4">
          {Object.entries(formData.access).map(([module, value]) => (
            <div key={module} className="flex items-center">
              <input
                type="checkbox"
                checked={value}
                onChange={() => handleAccessChange(module)}
                className="h-4 w-4 text-blue-600"
              />
              <label className="ml-2 text-sm text-gray-700 capitalize">
                {module.replace(/_/g, " ")}
              </label>
            </div>
          ))}
        </div>
      </div>

      {error && <div className="text-red-600 text-sm">{error}</div>}

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={() => onSubmitSuccess()}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
        >
          {isSubmitting ? "Updating..." : "Update User"}
        </button>
      </div>
    </form>
  );
}
