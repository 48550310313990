import React, { useState, useCallback } from "react";
import { FiSearch } from "react-icons/fi";

import Pagination from "../../components/Pagination";

// Sample data structure for events
const eventsData = Array(9).fill({
  eventName: "Annual Function",
  eventDate: "23 Dec 2024",
  enrolledStudents: "56",
  paymentStatus: "23",
});

export default function EnrolledPage() {
  const [activeNav, setActiveNav] = useState("All School");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("Total Event"); // New state for tabs
  const eventsPerPage = 9;
  const [filteredEvents, setFilteredEvents] = useState(eventsData);

  const handleSearch = useCallback(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filtered = eventsData.filter((event) => {
      return Object.keys(event).some((key) =>
        event[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredEvents(filtered);
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Event stats and tabs */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-x-2">
                <button
                  onClick={() => setActiveTab("Total Event")}
                  className={`px-2 py-1 ${
                    activeTab === "Total Event"
                      ? "bg-[#E7F7FF] text-[#113870] border border-[#C2C2C2]"
                      : "text-[#888888]"
                  } font-medium text-xs`}
                >
                  Total Event 50
                </button>
                <button
                  onClick={() => setActiveTab("Enrolled Student")}
                  className={`px-2 py-1 ${
                    activeTab === "Enrolled Student"
                      ? "bg-[#E7F7FF] text-[#113870] border border-[#C2C2C2]"
                      : "text-[#888888]"
                  } font-medium text-xs`}
                >
                  Enrolled Student 50
                </button>
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <button className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs">
                  + Add Event
                </button>
              </div>
            </div>

            {/* Events table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Event Name</th>
                    <th className="px-3 py-2">Event Date</th>
                    <th className="px-3 py-2">Enrolled Students</th>
                    <th className="px-3 py-2">Payment Status</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {filteredEvents.map((event, index) => (
                    <tr key={index} className="cursor-pointer hover:bg-gray-50">
                      <td className="px-3 py-2">{event.eventName}</td>
                      <td className="px-3 py-2">{event.eventDate}</td>
                      <td className="px-3 py-2">{event.enrolledStudents}</td>
                      <td className="px-3 py-2">{event.paymentStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredEvents.length / eventsPerPage)}
              onNextPage={() => setCurrentPage((prev) => prev + 1)}
              isLastPage={currentPage * eventsPerPage >= filteredEvents.length}
              isFirstPage={currentPage === 1}
              onPrevPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
