import React, { useState } from "react";
import { showToast } from "../../utils/toast";
import "react-toastify/dist/ReactToastify.css";
import logo from "./Schooleg9.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../Slices/userDetailsSlice";

const LoginPage = () => {
  const [loginType, setLoginType] = useState(null);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://schooleg.com/Schooleg/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone,
          password,
          role: loginType === "school" ? "School" : "User",
        }),
      });

      const data = await response.json();

      if (response.ok && data.token) {
        dispatch(setUserDetails(data));
        localStorage.setItem("token", data.token);
        localStorage.setItem("userId", data.Id);
        localStorage.setItem("contactPersonName", data.contactPersonName);
        localStorage.setItem("role", data.role);
        localStorage.setItem("userName", data.Name);
        localStorage.setItem("schoolId", data.school_id);
        localStorage.setItem("sessionId", data.session_id);
        localStorage.setItem("schoolName", data.school_name);

        localStorage.setItem(
          "userData",
          JSON.stringify({
            id: data.Id,
            role: data.role,
            name: data.Name,
            schoolId: data.school_id,
            contactPersonName: data.contactPersonName,
            sessionId: data.session_id,
            schoolName: data.schoolName,
            photo: data.photo,
          })
        );

        showToast.success("Login successful!");
        navigate("/students");
      } else {
        showToast.error(
          data.message === "Invalid credentials"
            ? "Invalid phone number or password"
            : data.message === "User not found"
            ? "User not found. Please check your credentials."
            : data.message || "Login failed. Please try again.",
          {}
        );
      }
    } catch (error) {
      showToast.error("Network error. Please check your connection.", {});
      console.error("Login error:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#f0f4f8] via-white to-[#e6eaf3] flex items-center justify-center p-4 relative overflow-hidden">
      {/* Subtle Background Patterns */}
      <div className="absolute inset-0 opacity-10 pointer-events-none">
        <div className="absolute top-0 left-0 w-1/2 h-full bg-gradient-to-br from-blue-50 to-transparent"></div>
        <div className="absolute bottom-0 right-0 w-1/2 h-full bg-gradient-to-bl from-cyan-50 to-transparent"></div>
      </div>

      <div className="max-w-md w-full bg-white/80 backdrop-blur-lg rounded-3xl shadow-[0_35px_60px_-15px_rgba(0,0,0,0.1)] border border-white/30 p-10 relative z-10">
        {/* Decorative Gradient Border */}
        <div className="absolute inset-0.5 bg-gradient-to-br from-white/30 to-white/10 rounded-3xl -z-10 opacity-50"></div>

        <div className="text-center mb-10">
          <div className="mb-6 flex justify-center">
            <div className="p-1.5 bg-gradient-to-br from-blue-100/50 to-cyan-100/50 rounded-full">
              <img
                src={logo}
                alt="School Logo"
                width={120}
                height={120}
                className="mx-auto rounded-full border-4 border-white shadow-[0_15px_35px_-10px_rgba(0,0,0,0.15)]"
              />
            </div>
          </div>
          <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-500 tracking-tight mb-3">
            School-EG Portal
          </h2>
          <p className="text-gray-500 font-medium text-lg">
            Secure Digital Management
          </p>
        </div>

        {!loginType ? (
          <div className="space-y-5">
            <button
              onClick={() => setLoginType("school")}
              className="w-full py-4 px-6 bg-gradient-to-r from-blue-600 to-cyan-500 text-white rounded-2xl transition-all duration-300 ease-in-out 
              shadow-xl hover:shadow-2xl focus:outline-none focus:ring-4 focus:ring-blue-300 focus:ring-opacity-50 
              transform hover:-translate-y-1 font-semibold text-lg"
            >
              School Login
            </button>
            <button
              onClick={() => setLoginType("user")}
              className="w-full py-4 px-6 bg-white text-blue-600 border border-blue-100 rounded-2xl 
              transition-all duration-300 ease-in-out shadow-md hover:shadow-xl 
              focus:outline-none focus:ring-4 focus:ring-blue-300 focus:ring-opacity-50 
              transform hover:-translate-y-1 font-semibold text-lg"
            >
              User Login
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full px-4 py-3.5 rounded-xl border border-gray-200 bg-gray-50/30 
                focus:bg-white focus:ring-4 focus:ring-blue-300 focus:border-transparent 
                shadow-sm transition-all duration-300 placeholder-gray-400"
                placeholder="Enter your phone number"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2">
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3.5 rounded-xl border border-gray-200 bg-gray-50/30 
                focus:bg-white focus:ring-4 focus:ring-blue-300 focus:border-transparent 
                shadow-sm transition-all duration-300 placeholder-gray-400"
                placeholder="Enter your password"
                required
              />
            </div>

            <div className="flex items-center justify-between pt-2">
              <button
                type="button"
                onClick={() => setLoginType(null)}
                className="text-sm font-medium text-gray-500 hover:text-blue-600 
                transition-colors duration-300 flex items-center gap-1"
              >
                <span>←</span> Back to Options
              </button>
              <Link
                to="/forgot-password"
                className="text-sm font-medium text-blue-600 hover:text-blue-800 
                transition-colors duration-300"
              >
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              className="w-full py-4 px-6 bg-gradient-to-r from-blue-600 to-cyan-500 
              text-white rounded-2xl transition-all duration-300 ease-in-out 
              shadow-xl hover:shadow-2xl focus:outline-none focus:ring-4 
              focus:ring-blue-300 focus:ring-opacity-50 
              transform hover:-translate-y-1 font-semibold text-lg"
            >
              Sign In
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
