"use client";
import React, { useState } from "react";
import { FiSearch, FiBell, FiDownload } from "react-icons/fi";
import { GiSteeringWheel, GiSpanner } from "react-icons/gi";
import { BsFuelPump, BsBatteryFull } from "react-icons/bs";
import { MdShare } from "react-icons/md";

import VehicleDetailsPopup from "./VehicleDetailsPopup";

// Sample data structure for vehicles
const vehiclesData = Array(3).fill({
  vehicleNo: "HR XXXX 123",
  location: "Rajiv Chowk, Haryana",
  lastUpdate: "05:46 PM",
  speed: "200 kmph",
  todayDistance: "100 Km Today",
  status: "Running",
  driver: {
    name: "Ravi Kumar",
    phone: "8545652545",
  },
  helper: {
    name: "Ravi Kumar",
    phone: "8545652545",
  },
});

export default function BusTrackingPage() {
  const [activeNav, setActiveNav] = useState("My School");
  const [searchTerm, setSearchTerm] = useState("");
  const [vehicles, setVehicles] = useState(vehiclesData);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);

  const handleViewInfo = () => {
    setShowDetailsPopup(true);
  };

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Vehicle stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-x-0">
                <div className="bg-[#E7F7FF] text-[#113870] px-3 py-1 border border-[#C2C2C2] font-medium">
                  All Vehicle: 50
                </div>
                <div className="bg-[#E7FFE7] text-[#00A76F] px-3 py-1 border border-[#C2C2C2] font-medium">
                  Running: 15
                </div>
                <div className="bg-[#FFE7E7] text-[#B53D3E] px-3 py-1 border border-[#C2C2C2] font-medium">
                  Stopped: 15
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 bg-[#F4F4F4]"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <button className="flex items-center space-x-1 text-[#0662C6]">
                  <FiBell size={14} />
                  <span>GPS Alert</span>
                </button>
                <button className="flex items-center space-x-1 bg-[#0662C6] text-white px-2 py-1 rounded-md">
                  <FiDownload size={14} />
                  <span>Download</span>
                </button>
              </div>
            </div>

            {/* Main content area with split view */}
            <div className="flex space-x-3">
              {/* Vehicle list */}
              <div
                className="w-1/3 space-y-3 overflow-y-auto"
                style={{ maxHeight: "calc(100vh - 180px)" }}
              >
                {vehicles.map((vehicle, index) => (
                  <div
                    key={index}
                    className="bg-white p-3 rounded-lg border border-[#C2C2C2]"
                  >
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h3 className="text-sm font-medium text-[#0662C6]">
                          {vehicle.vehicleNo}
                        </h3>
                        <div className="flex items-center space-x-1 text-[#888888] text-xs">
                          <span>📍 {vehicle.location}</span>
                        </div>
                        <div className="text-[#888888] text-xs">
                          Last Update: {vehicle.lastUpdate}
                        </div>
                        <div className="text-[#888888] text-xs">
                          <span>ℹ️ {vehicle.speed}</span> |{" "}
                          <span>{vehicle.todayDistance}</span>
                        </div>
                      </div>
                      <span className="px-2 py-1 bg-[#E7FFE7] text-[#00A76F] rounded-md text-xs">
                        {vehicle.status}
                      </span>
                    </div>

                    <div className="flex space-x-4 mb-2">
                      <div>
                        <div className="text-xs text-[#888888]">Driver:</div>
                        {vehicle.driver.name ? (
                          <>
                            <div className="text-xs">{vehicle.driver.name}</div>
                            <div className="text-xs text-[#888888]">
                              {vehicle.driver.phone}
                            </div>
                          </>
                        ) : (
                          <button className="text-[#0662C6] text-xs">
                            +Add
                          </button>
                        )}
                      </div>
                      <div>
                        <div className="text-xs text-[#888888]">Helper:</div>
                        {vehicle.helper.name ? (
                          <>
                            <div className="text-xs">{vehicle.helper.name}</div>
                            <div className="text-xs text-[#888888]">
                              {vehicle.helper.phone}
                            </div>
                          </>
                        ) : (
                          <button className="text-[#0662C6] text-xs">
                            +Add
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="flex space-x-3">
                        <GiSteeringWheel className="text-[#888888]" size={16} />
                        <GiSpanner className="text-[#888888]" size={16} />
                        <BsFuelPump className="text-[#888888]" size={16} />
                        <BsBatteryFull className="text-[#888888]" size={16} />
                        <MdShare className="text-[#888888]" size={16} />
                      </div>
                      <button
                        className="bg-[#0662C6] text-white px-2 py-1 rounded-md text-xs"
                        onClick={handleViewInfo}
                      >
                        View Info
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {/* Map and Vehicle Details */}
              <div className="w-2/3">
                {selectedVehicle && (
                  <VehicleDetailsPopup
                    vehicle={selectedVehicle}
                    onClose={() => setSelectedVehicle(null)}
                  />
                )}

                <div className="bg-white rounded-lg border border-[#C2C2C2] overflow-hidden mt-3">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224346.54004834822!2d77.06889754725781!3d28.52758200617607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1709825058844!5m2!1sen!2sin"
                    width="100%"
                    height="700"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="rounded-lg"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {showDetailsPopup && (
        <VehicleDetailsPopup onClose={() => setShowDetailsPopup(false)} />
      )}
    </div>
  );
}
