import React from 'react';

export const NavLink = ({ href, children }) => {
  return (
    <a 
      href={href} 
      className="text-gray-600 hover:text-indigo-600 transition-colors"
    >
      {children}
    </a>
  );
}; 