import axios from "axios";


const axiosReq = {
    'get': async (endPoint, params) => {
        return axios.get(endPoint, {headers: {}, params}, ).then(resp => resp.data).catch(err =>{ throw (err)})
    },
    'post': async (endPoint, data, headers, params) => {
        return axios.post(endPoint, data, {headers: {}, params}, ).then(resp => resp.data).catch(err =>{ throw (err)})
    },
    put: async (endPoint, data, headers = {}, params) => {
        return axios
          .put(endPoint, data, { headers, params })
          .then(resp => resp.data)
          .catch(err => { throw err });
    },
    patch: async (endPoint, data, headers = {}, params) => {
        return axios
            .patch(endPoint, data, { headers, params })
            .then(resp => resp.data)
            .catch(err => { throw err });
        },
    delete: async (endPoint, headers = {}, params) => {
        return axios
            .delete(endPoint, { headers, params })
            .then(resp => resp.data)
            .catch(err => { throw err });
        },
}
export default axiosReq;