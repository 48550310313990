"use client";
import React, { useState, useCallback, useEffect } from "react";
import {
  FiSearch,
  FiChevronDown,
  FiPlus,
  FiCamera,
  FiDownload,
  FiX,
} from "react-icons/fi";
import AddClassActivity from "./AddClassActivity";
import { format, isAfter, isBefore, isEqual, parseISO } from "date-fns";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";
import { MONTHS } from "../../constants/AppConstant";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function ActivityPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024-25");
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [originalActivities, setOriginalActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("all");
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pages: 1,
    pageSize: 8,
    total: 0,
  });

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  const allMonths = [{ id: "all", name: "All Months" }, ...MONTHS];
  useEffect(() => {
    if (userDetails?.school_id) {
      getCoCurricularData();
    }
  }, [userDetails?.school_id, selectedMonth]);

  const getCoCurricularData = (page = 1) => {
    setIsLoading(true);

    // Create the query object
    const query = JSON.stringify({
      school_id: userDetails.school_id,
      activityFor: "Class",
    });

    // Construct params with the correct query format
    const params = {
      query,
      page,
    };

    axiosReq
      .get(apiEndponts.getCoCurricular, params)
      .then((resp) => {
        const activities = resp?.records?.map((item) => {
          const dateToCompare = parseISO(item?.date);
          const currentDate = new Date();
          let status = "";

          if (isBefore(dateToCompare, currentDate)) {
            status = "Expired";
          } else if (isEqual(dateToCompare, currentDate)) {
            status = "Active";
          } else {
            status = "Upcoming";
          }

          return {
            ...item,
            id: item?._id,
            status,
            title: item?.name || "",
            date: format(parseISO(item?.date), "dd/MM/yyyy"),
            description: item?.details || "",
            image: item?.photo?.length > 0 ? item?.photo[0] : "",
          };
        });

        setFilteredActivities(activities || []);
        setOriginalActivities(activities || []);
        setPaginationInfo({
          current: resp?.currentPage || 1,
          pages: resp?.totalPages || 1,
          pageSize: Math.ceil(resp?.totalCount / resp?.totalPages) || 8,
          total: resp?.totalCount || 0,
        });
      })
      .catch((err) => {
        console.error("Error fetching activities:", err);
        showToast.error("Failed to fetch activities");
        setFilteredActivities([]);
        setOriginalActivities([]);
        setPaginationInfo({
          current: 1,
          pages: 1,
          pageSize: 8,
          total: 0,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = useCallback(
    debounce((value) => {
      if (!value.trim()) {
        setFilteredActivities(originalActivities);
      } else {
        const lowercasedFilter = value.toLowerCase();
        const filtered = originalActivities.filter((activity) => {
          return Object.keys(activity).some((key) =>
            String(activity[key]).toLowerCase().includes(lowercasedFilter)
          );
        });
        setFilteredActivities(filtered);
      }
      setPaginationInfo((prev) => ({
        ...prev,
        current: 1,
      }));
    }, 300),
    [originalActivities]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleMonthChange = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);

    const query = {
      school_id: userDetails.school_id,
      activityFor: "Class",
    };

    if (value !== "all") {
      query.month = value;
    }

    setPaginationInfo((prev) => ({
      ...prev,
      current: 1,
    }));

    getCoCurricularData(1);
  };

  const handlePrevPage = () => {
    const newPage = paginationInfo.current - 1;
    if (newPage >= 1) {
      setPaginationInfo((prev) => ({
        ...prev,
        current: newPage,
      }));
      getCoCurricularData(newPage);
    }
  };

  const handleNextPage = () => {
    const newPage = paginationInfo.current + 1;
    if (newPage <= paginationInfo.pages) {
      setPaginationInfo((prev) => ({
        ...prev,
        current: newPage,
      }));
      getCoCurricularData(newPage);
    }
  };

  const toggleDetails = (activity) => {
    setSelectedActivity(activity);
    setShowDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedActivity(null);
  };

  const renderPagination = () => {
    if (!filteredActivities.length || isLoading) return null;

    const pageNumbers = [];
    for (let i = 1; i <= paginationInfo.pages; i++) {
      pageNumbers.push(i);
    }

    // Calculate correct start and end entries based on current page data
    const startEntry =
      (paginationInfo.current - 1) * paginationInfo.pageSize + 1;
    const endEntry = startEntry + filteredActivities.length - 1;

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {startEntry} to {endEntry} of {paginationInfo.total} entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={handlePrevPage}
            disabled={paginationInfo.current === 1}
            className={`px-2 py-1 text-xs rounded ${
              paginationInfo.current === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => {
                setPaginationInfo((prev) => ({
                  ...prev,
                  current: number,
                }));
                getCoCurricularData(number);
              }}
              className={`px-2 py-1 text-xs rounded ${
                paginationInfo.current === number
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={handleNextPage}
            disabled={paginationInfo.current === paginationInfo.pages}
            className={`px-2 py-1 text-xs rounded ${
              paginationInfo.current === paginationInfo.pages
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs overflow-hidden text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-2 overflow-hidden bg-[#FEFEFE] p-4 flex flex-col">
          <div className="w-full mx-auto flex-1 flex flex-col">
            {/* Activity stats and controls */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] rounded-md font-medium text-xs">
                Total Activities: {paginationInfo.total}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                >
                  {allMonths.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Activity cards */}
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 flex-1 overflow-y-auto">
              {isLoading ? (
                <div className="col-span-full text-center py-4">Loading...</div>
              ) : filteredActivities.length === 0 ? (
                <div className="col-span-full text-center py-4">
                  No activities found
                </div>
              ) : (
                filteredActivities.map((activity) => (
                  <div
                    key={activity.id}
                    className="bg-[#FFFFFF] shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2] transition-all duration-300 flex flex-col h-full"
                  >
                    <div className="relative h-32">
                      <img
                        src={activity.image || "/placeholder-image.jpg"}
                        alt={activity.title}
                        className="w-full h-full object-cover"
                      />
                      <span
                        className={`absolute top-1 right-1 px-1.5 py-0.5 rounded-full text-[10px] ${
                          activity.status === "Upcoming"
                            ? "bg-[#E7F7FF] text-[#0662C6]"
                            : activity.status === "Active"
                            ? "bg-[#E7FFE7] text-[#066206]"
                            : "bg-[#FFE7E7] text-[#620606]"
                        }`}
                      >
                        {activity.status}
                      </span>
                    </div>
                    <div className="p-2 flex flex-col flex-grow">
                      <h2 className="text-sm font-bold mb-1">
                        {activity.title}
                      </h2>
                      <span className="text-xs text-[#0662C6] mb-1">
                        Date: {activity.date}
                      </span>
                      <div className="flex justify-end items-end mt-auto">
                        <button
                          onClick={() => toggleDetails(activity)}
                          className="bg-[#0662C6] text-[#FFFFFF] px-2 py-0.5 rounded text-[10px] hover:bg-[#113870] transition-colors duration-200"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            {/* Pagination */}
            {renderPagination()}

            {/* Details Modal */}
            {showDetailsModal && selectedActivity && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-full max-w-md mx-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Activity Details</h2>
                    <button
                      onClick={closeDetailsModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <div>
                    <img
                      src={selectedActivity.image || "/placeholder-image.jpg"}
                      alt={selectedActivity.title}
                      className="w-full h-48 object-cover rounded-lg mb-4"
                    />
                    <h3 className="text-lg font-semibold mb-2">
                      {selectedActivity.title}
                    </h3>
                    <p className="text-sm mb-2">
                      Date: {selectedActivity.date}
                    </p>
                    <p className="text-sm text-[#0662C6]">
                      {selectedActivity.description}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default ActivityPage;
