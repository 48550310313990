import { toast } from "react-hot-toast";

export const toastOptions = {
  success: {
    style: {
      background: "#4CAF50",
      color: "white",
    },
    duration: 3000,
  },
  error: {
    style: {
      background: "#EF4444",
      color: "white",
    },
    duration: 3000,
  },
  warning: {
    style: {
      background: "#FB923C",
      color: "white",
    },
    duration: 3000,
  },
  info: {
    style: {
      background: "#0EA5E9",
      color: "white",
    },
    duration: 3000,
  },
};

export const showToast = {
  success: (message) => toast.success(message, toastOptions.success),
  error: (message) => toast.error(message, toastOptions.error),
  warning: (message) => toast(message, { ...toastOptions.warning, icon: "⚠️" }),
  info: (message) => toast(message, { ...toastOptions.info, icon: "ℹ️" }),
};

export const promiseToast = (
  promise,
  loadingMessage,
  successMessage,
  errorMessage
) => {
  return toast.promise(
    promise,
    {
      loading: loadingMessage || "Loading...",
      success: successMessage || "Success!",
      error: errorMessage || "Error occurred",
    },
    {
      loading: {
        style: {
          background: "#1E293B",
          color: "white",
        },
      },
      success: toastOptions.success,
      error: toastOptions.error,
    }
  );
};

// Custom toast styles for specific actions
export const customToasts = {
  deletion: (message) =>
    toast(message, {
      ...toastOptions.error,
      icon: "🗑️",
      style: {
        ...toastOptions.error.style,
        border: "1px solid #DC2626",
      },
    }),

  update: (message) =>
    toast(message, {
      ...toastOptions.success,
      icon: "✏️",
      style: {
        ...toastOptions.success.style,
        border: "1px solid #059669",
      },
    }),

  loading: (message) =>
    toast.loading(message, {
      style: {
        background: "#1E293B",
        color: "white",
      },
    }),
};
