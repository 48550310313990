"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiEdit2, FiTrash2, FiX } from "react-icons/fi";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";
import AddDriverForm from "./AddDriverForm";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function DriversPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [driversPerPage] = useState(8);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [driverEditData, setDriverEditData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
  const [totalDrivers, setTotalDrivers] = useState(0);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  const getDriversInfo = (page = 1) => {
    setIsLoading(true);
    const url = `${apiEndponts.getDrivers}${userDetails?.school_id}?page=${page}`;
    axiosReq
      .get(url)
      .then((resp) => {
        if (resp?.data) {
          const drivers = resp?.data?.drivers?.map((driver) => {
            return {
              ...driver,
              phoneNo: driver?.phone || "",
              convertedSalary: driver?.salary
                ? `Rs ${driver?.salary?.toLocaleString("en-IN")}`
                : "",
              routes: driver?.busNo
                ? [{ name: driver.routeNo || "Bus", busNo: driver.busNo }]
                : [],
            };
          });

          setTotalDrivers(resp.data.pagination.totalDrivers);
          setFilteredDrivers(drivers || []);
          setTotalPages(resp.data.pagination.totalPages);
          setCurrentPage(resp.data.pagination.currentPage);
        }
      })
      .catch((err) => {
        setFilteredDrivers([]);
        setTotalPages(1);
        setTotalDrivers(0);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (userDetails?.school_id) {
      getDriversInfo(currentPage);
    }
  }, [currentPage, userDetails?.school_id]);

  // Debounced search handler
  const handleSearch = useCallback(
    debounce((value) => {
      const lowercasedFilter = value.toLowerCase();
      const filtered = filteredDrivers.filter((driver) => {
        return Object.keys(driver).some((key) =>
          driver[key].toString().toLowerCase().includes(lowercasedFilter)
        );
      });
      setFilteredDrivers(filtered);
      setCurrentPage(1);
    }, 300),
    [filteredDrivers]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleDeleteClick = (driver) => {
    setSelectedDriver(driver);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    const updatedDrivers = filteredDrivers.filter(
      (driver) => driver !== selectedDriver
    );
    setFilteredDrivers(updatedDrivers);
    setIsDeleteModalOpen(false);
    setSelectedDriver(null);
  };

  const handleAddDriver = () => {
    setIsAddDriverModalOpen(true);
  };

  const closeAddDriverModal = () => {
    setIsAddDriverModalOpen(false);
    setDriverEditData({});
  };

  const handleEditClick = (driver) => {
    setDriverEditData(driver);
    handleAddDriver();
  };

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Driver stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Drivers: {totalDrivers}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search drivers..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>2023-24</option>
                </select>
                <button
                  className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs"
                  onClick={handleAddDriver}
                >
                  + Add Drivers
                </button>
              </div>
            </div>

            {/* Drivers table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Name</th>
                    <th className="px-3 py-2">Phone No.</th>
                    <th className="px-3 py-2">Route</th>
                    <th className="px-3 py-2">Licence No.</th>
                    <th className="px-3 py-2">Salary</th>
                    <th className="px-3 py-2">Address</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredDrivers.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        No drivers found
                      </td>
                    </tr>
                  ) : (
                    filteredDrivers.map((driver, index) => (
                      <tr
                        key={driver._id || index}
                        className="hover:bg-gray-50"
                      >
                        <td className="px-3 py-2">{driver.name}</td>
                        <td className="px-3 py-2">{driver.phoneNo}</td>
                        <td className="px-3 py-2">
                          {driver.routes.length > 0 ? (
                            driver.routes.map((route, i) => (
                              <div
                                key={i}
                                className="flex items-center space-x-1"
                              >
                                {route.name && (
                                  <span className="text-[#0662C6]">
                                    {route.name}
                                  </span>
                                )}
                                {route.busNo && (
                                  <span className="text-[#888888]">
                                    {route.name
                                      ? `(${route.busNo})`
                                      : route.busNo}
                                  </span>
                                )}
                              </div>
                            ))
                          ) : (
                            <span className="text-gray-400">
                              No route/bus assigned
                            </span>
                          )}
                        </td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {driver.licenseNo}
                        </td>
                        <td className="px-3 py-2">{driver.convertedSalary}</td>
                        <td className="px-3 py-2">{driver.address}</td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button
                              className="text-[#0662C6] hover:text-[#113870] transition-colors duration-200"
                              onClick={() => handleEditClick(driver)}
                            >
                              <FiEdit2 size={14} />
                            </button>
                            <button
                              className="text-[#B53D3E] hover:text-red-700 transition-colors duration-200"
                              onClick={() => handleDeleteClick(driver)}
                            >
                              <FiTrash2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {!isLoading && filteredDrivers.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {(currentPage - 1) * driversPerPage + 1} to{" "}
                  {Math.min(currentPage * driversPerPage, totalDrivers)} of{" "}
                  {totalDrivers} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>

                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => setCurrentPage(index + 1)}
                      className={`px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? "bg-[#0662C6] text-white"
                          : "bg-white text-[#0662C6] hover:bg-gray-50"
                      } border border-[#C2C2C2]`}
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Add/Edit Driver Modal */}
            {isAddDriverModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">
                      {Object.keys(driverEditData)?.length > 0
                        ? "Edit Driver"
                        : "Add New Driver"}
                    </h2>
                    <button
                      onClick={closeAddDriverModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddDriverForm
                    isEdit={Object.keys(driverEditData)?.length > 0}
                    onSubmitSuccess={closeAddDriverModal}
                    getDriversInfo={getDriversInfo}
                    driverEditData={driverEditData}
                  />
                </div>
              </div>
            )}

            {/* Delete Confirmation Modal */}
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onConfirm={handleDeleteConfirm}
              itemName={selectedDriver ? "this driver" : ""}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
