"use client";
import React, { useState } from "react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";

import Pagination from "../../components/Pagination";

// Sample data structure for online classes
const classesData = Array(10).fill({
  period: "Period 1",
  subject: "Maths",
  teacher: "Suresh Agarwal",
  time: "9:00 AM - 9:40 AM",
  meetLink: "nadsmvbvb/meet.com",
  notes: "ID - jksddvjunv\nPass - 2345",
});

export default function LecturePage() {
  const [activeNav, setActiveNav] = useState("My School");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDay, setSelectedDay] = useState("Mon");
  const classesPerPage = 10;
  const [filteredClasses, setFilteredClasses] = useState(classesData);

  const days = ["Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Calculate the classes to display on the current page
  const indexOfLastClass = currentPage * classesPerPage;
  const indexOfFirstClass = indexOfLastClass - classesPerPage;
  const currentClasses = filteredClasses.slice(
    indexOfFirstClass,
    indexOfLastClass
  );

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Day selection and filters */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-x-2">
                {days.map((day) => (
                  <button
                    key={day}
                    className={`px-3 py-1 border border-[#C2C2C2] rounded-md ${
                      selectedDay === day
                        ? "bg-[#0662C6] text-white"
                        : "bg-white"
                    }`}
                    onClick={() => setSelectedDay(day)}
                  >
                    {day}
                  </button>
                ))}
              </div>
              <div className="flex items-center space-x-2">
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>9th Class</option>
                </select>
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>2023-24</option>
                </select>
              </div>
            </div>

            {/* Classes table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Period</th>
                    <th className="px-3 py-2">Subject</th>
                    <th className="px-3 py-2">Teacher</th>
                    <th className="px-3 py-2">Time</th>
                    <th className="px-3 py-2">Meet Link</th>
                    <th className="px-3 py-2">Notes</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {currentClasses.map((class_, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">{class_.period}</td>
                      <td className="px-3 py-2">{class_.subject}</td>
                      <td className="px-3 py-2 text-[#0662C6]">
                        {class_.teacher}
                      </td>
                      <td className="px-3 py-2">{class_.time}</td>
                      <td className="px-3 py-2">
                        <a href="#" className="text-[#0662C6] underline">
                          {class_.meetLink}
                        </a>
                      </td>
                      <td className="px-3 py-2 whitespace-pre-line">
                        {class_.notes}
                      </td>
                      <td className="px-3 py-2">
                        <div className="flex space-x-2">
                          <button className="text-[#0662C6]">
                            <FiEdit2 size={14} />
                          </button>
                          <button className="text-[#B53D3E]">
                            <FiTrash2 size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredClasses.length / classesPerPage)}
              onNextPage={handleNextPage}
              isLastPage={indexOfLastClass >= filteredClasses.length}
              isFirstPage={currentPage === 1}
              onPrevPage={handlePrevPage}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
