import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { auth } from "../utils/auth";
import { toast } from "react-toastify";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import LoadingSpinner from "../components/LoadingSpinner";

const Layout = () => {
  const [activeNav, setActiveNav] = useState("My School");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    // Verify authentication on mount
    const checkAuth = async () => {
      try {
        if (!auth.isAuthenticated()) {
          throw new Error("Not authenticated");
        }
        const userData = auth.getUserData();
        if (!userData) {
          throw new Error("No user data");
        }
        setIsLoading(false);
      } catch (error) {
        toast.error("Session expired. Please login again.");
        navigate("/login", { state: { from: location.pathname } });
      }
    };

    checkAuth();
  }, [navigate, location]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col h-screen">
      <Header
        activeNav={activeNav}
        setActiveNav={setActiveNav}
        userDetails={userDetails}
      />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar activeNav={activeNav} userRole={userDetails?.role} />
        <main className="flex-1 overflow-auto bg-white p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
