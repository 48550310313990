import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

// Store
import { store } from "./store";

// Components
import App from "./App";

// Styles
import "./index.css";

// Toast Configuration
const toastOptions = {
  success: {
    style: {
      background: "#4CAF50",
      color: "white",
    },
    duration: 3000,
  },
  error: {
    style: {
      background: "#EF4444",
      color: "white",
    },
    duration: 3000,
  },
  warning: {
    style: {
      background: "#FB923C",
      color: "white",
    },
    duration: 3000,
  },
  info: {
    style: {
      background: "#0EA5E9",
      color: "white",
    },
    duration: 3000,
  },
};

// Create root element
const container = document.getElementById("root");
const root = createRoot(container);

// Render app
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <Toaster
          position="top-right"
          toastOptions={{
            success: toastOptions.success,
            error: toastOptions.error,
            warning: toastOptions.warning,
            info: toastOptions.info,
            style: {
              borderRadius: "8px",
              boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
            },
          }}
          containerStyle={{
            top: 20,
            right: 20,
          }}
          gutter={8}
        />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// Optional: Add error boundary
window.onerror = function (message, source, lineno, colno, error) {
  console.error("Global error:", { message, source, lineno, colno, error });
  return false;
};
