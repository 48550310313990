const baseUrl = "https://schooleg.com/";

const apiEndponts = {
  getBanner: baseUrl + "Schooleg/banners",
  addBanner: baseUrl + "Schooleg/banner",
  deleteBanner: baseUrl + "Schooleg/banner/",
  getHolidays: baseUrl + "Schooleg/holidays/",
  deleteHolidays: baseUrl + "Schooleg/holiday/",
  getParents: baseUrl + "Schooleg/parents-by-school/",
  getStudents: baseUrl + "Schooleg/students-by-school/",
  getDrivers: baseUrl + "Schooleg/drivers-by-school/",
  getHelpers: baseUrl + "Schooleg/helpers-by-school/",
  getTeachers: baseUrl + "Schooleg/teachers-by-school",
  getClassOfSchool: baseUrl + "Schooleg/get-all-classes",
  getParentSearch: baseUrl + "Schooleg/parents-by-school-phone",
  addStudents: baseUrl + "Schooleg/students",
  addParents: baseUrl + "Schooleg/parents",
  addDrivers: baseUrl + "Schooleg/driver",
  addHelpers: baseUrl + "Schooleg/helper",
  addTeacher: baseUrl + "Schooleg/teacher",
  getClasses: baseUrl + "Schooleg/",
  createClasses: baseUrl + "Schooleg/create-class",
  getTeacherList: baseUrl + "Schooleg/teachers/list/",
  addTimeTable: baseUrl + "Schooleg/timetable",
  addCoCurricular: baseUrl + "Schooleg/school-activity",
  getTimeTable: baseUrl + "Schooleg/timetable/",
  getCircular: baseUrl + "Schooleg/get-items",
  getCoCurricular: baseUrl + "Schooleg/school-activities",
  addHoliday: baseUrl + "Schooleg/holiday",
  getEvents: baseUrl + "Schooleg/events",
  getNews: baseUrl + "Schooleg/news",
  addERequests: baseUrl + "Schooleg/request",
  addExpenses: baseUrl + "Schooleg/expense",
  getFeeSummary: baseUrl + "Schooleg/school-fee-summary",
  getStatusSummary: baseUrl + "Schooleg/status-summary",
  addCircular: baseUrl + "Schooleg/circular",
  updatePeriod: baseUrl + "Schooleg/",
  addPeriod: baseUrl + "Schooleg/",
  addUser: baseUrl + "Schooleg/register-user",
  editTeacher: baseUrl + "Schooleg/update-teacher",
  updateDriver: baseUrl + "Schooleg/update-driver",
  updateHelper: baseUrl + "Schooleg/update-helper",
  updateParent: baseUrl + "Schooleg/update-parent",
  getStudentAttandance: baseUrl + "Schooleg/school-student-attendance",
  getTeacherAttendance: baseUrl + "Schooleg/school-teacher-attendance",
  getDashboard: baseUrl + "Schooleg/school-dashboard",
  updateUser: baseUrl + `Schooleg/users`,
  updateLeaveStatus: baseUrl + `Schooleg/leave-request-teacher`,
  updateStudentLeaveStatus: baseUrl + `Schooleg/leave-request-student`,
};

export default apiEndponts;
