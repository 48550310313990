const LoadingSpinner = ({
  size = "md",
  variant = "primary",
  className = "",
}) => {
  const sizeMap = {
    sm: "w-4 h-4 border-2",
    md: "w-8 h-8 border-3",
    lg: "w-12 h-12 border-4",
  };

  const variantMap = {
    primary: "border-blue-600 border-t-transparent",
    secondary: "border-gray-600 border-t-transparent",
    white: "border-white border-t-transparent",
  };

  return (
    <div
      role="status"
      className={`
          ${sizeMap[size]}
          ${variantMap[variant]}
          animate-spin 
          rounded-full
          ${className}
        `}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;
