import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { stateAndDistrict } from "../../constants/AppConstant";
import { format } from "date-fns";
import { showToast } from "../../utils/toast";

function AddTeacherForm({
  onSubmitSuccess,
  editMode,
  getTeacherInfo,
  isEditMode,
  teacherEditStats,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [photoPreview, setPhotoPreview] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    if (isEditMode) {
      let {
        name,
        email,
        phone,
        qualification,
        designation,
        subject,
        addressObj,
        salary,
        aadhar,
      } = teacherEditStats;
      selectDistrictOptions(addressObj?.state);
      reset({
        name,
        phone,
        qualification,
        designation,
        subject,
        salary,
        mail: email,
        aadhar,
        pincode: addressObj?.pincode,
        state: addressObj?.state,
        address: addressObj?.name,
        district: addressObj?.district,
      });
    }
  }, [isEditMode]);

  const onSubmit = (data) => {
    const payload = {
      name: data?.name,
      session_id: userDetails?.session_id || "",
      route_id: "64c11f38e3c0c7b289ed23f7",
      school_id: userDetails?.school_id || "",
      address: {
        name: data?.address || "",
        state: data?.state,
        district: data?.district,
        pincode: data?.pincode,
      },
      phone: data?.phone || "",
      mail: data?.mail || "",
      password: data?.password || "",
      aadhar: data?.aadhar || "",
      salary: data?.salary || "",
      qualification: data?.qualification || "",
      designation: data?.designation || "",
      subject: data?.subject || "",
    };
    const payloadData = new FormData();
    if (data?.photo?.[0]) {
      payloadData.append("photo", data?.photo?.[0]);
    }
    payloadData.append("Data", JSON.stringify(payload));
    if (isEditMode) {
      const url = `${apiEndponts?.editTeacher}/${teacherEditStats?.id}`;
      axiosReq
        .put(url, payloadData)
        .then((resp) => {
          showToast.success("Teacher info Update successfully");
          onSubmitSuccess();
          getTeacherInfo();
        })
        .catch((err) => console.log(err));
    } else {
      axiosReq
        .post(apiEndponts?.addTeacher, payloadData)
        .then((resp) => {
          showToast.success("New Teacher created successfully");
          onSubmitSuccess();
          getTeacherInfo();
        })
        .catch((err) => console.log(err));
    }
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const selectDistrictOptions = (state_name) => {
    const districts = stateAndDistrict?.find(
      (item) => item?.state === state_name
    )?.districts;
    setDistrictOptions(districts);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="name"
          >
            Name
          </label>
          <input
            {...register("name", { required: "Name is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
            type="text"
            placeholder="Name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="mail"
          >
            Email
          </label>
          <input
            {...register("mail", {
              required: "Email is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="mail"
            type="email"
            placeholder="Email"
          />
          {errors.mail && (
            <p className="text-red-500 text-sm mt-1">{errors.mail.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
            {...register("phone", { required: "Phone is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="phone"
            type="text"
            maxLength={10}
            placeholder="Phone"
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              setValue("phone", value);
            }}
          />
          {errors.phone && (
            <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="password"
          >
            Password
          </label>
          <input
            {...register("password", {
              required: isEditMode ? false : "Password is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="password"
            type="text"
            // maxLength={10}
            placeholder="Password"
          />
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">
              {errors.password.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="aadhar"
          >
            Aadhar No
          </label>
          <input
            {...register("aadhar", { required: "Aadhar No is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="aadhar"
            type="text"
            // maxLength={10}
            placeholder="Aadhar No"
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              setValue("aadhar", value);
            }}
          />
          {errors.aadhar && (
            <p className="text-red-500 text-sm mt-1">{errors.aadhar.message}</p>
          )}
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="designation"
          >
            Designation
          </label>
          <input
            {...register("designation", {
              required: "Designation is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="designation"
            type="text"
            // maxLength={10}
            placeholder="Designation"
          />
          {errors.designation && (
            <p className="text-red-500 text-sm mt-1">
              {errors.designation.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="salary"
          >
            Salary
          </label>
          <input
            {...register("salary", { required: "Salary is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="salary"
            type="text"
            // maxLength={10}
            placeholder="Salary"
          />
          {errors.salary && (
            <p className="text-red-500 text-sm mt-1">{errors.salary.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="subject"
          >
            Subject
          </label>
          <input
            {...register("subject", { required: "Subject is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="subject"
            type="text"
            // maxLength={10}
            placeholder="Subject"
          />
          {errors.subject && (
            <p className="text-red-500 text-sm mt-1">
              {errors.subject.message}
            </p>
          )}
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="photo"
          >
            Photo
          </label>
          <input
            {...register("photo")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="photo"
            type="file"
            onChange={handlePhotoChange}
          />
          {photoPreview && (
            <img
              src={photoPreview}
              alt="Preview"
              className="mt-2 w-16 h-16 rounded-full"
            />
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="qualification"
          >
            Qualification
          </label>
          <input
            {...register("qualification", {
              required: "Qualification is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="qualification"
            type="text"
            // maxLength={10}
            placeholder="Qualification"
          />
          {errors.qualification && (
            <p className="text-red-500 text-sm mt-1">
              {errors.qualification.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="route_id"
          >
            Route
          </label>
          <select
            {...register("route_id", {
              // required: "Route is required"
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="route_id"
          >
            <option value="">Select Route</option>
            {/* Add route options here */}
          </select>
          {errors.route_id && (
            <p className="text-red-500 text-sm mt-1">
              {errors.route_id.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="stoppage_id"
          >
            Stoppage Point
          </label>
          <select
            {...register("stoppage_id", {
              // required: "Stoppage Point is required"
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="stoppage_id"
          >
            <option value="">Select Stoppage Point</option>
            {/* Add route options here */}
          </select>
          {errors.stoppage_id && (
            <p className="text-red-500 text-sm mt-1">
              {errors.stoppage_id.message}
            </p>
          )}
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="address"
          >
            Address
          </label>
          <input
            {...register("address", {
              // required: "Class Roll No. is required",
              // valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="address"
            type="text"
            placeholder="Address"
          />
          {/* {errors.address && (
            <p className="text-red-500 text-sm mt-1">
              {errors.address.message}
            </p>
          )} */}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="state"
          >
            State
          </label>
          <select
            {...register("state", { required: "State is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="state"
            onChange={(e) => {
              const val = e.target.value;
              selectDistrictOptions(val);
            }}
          >
            <option value="">Select State</option>
            {stateAndDistrict?.map((item) => {
              return <option value={item?.state}>{item?.state}</option>;
            })}
            {/* Add route options here */}
          </select>
          {errors.state && (
            <p className="text-red-500 text-sm mt-1">{errors.state.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="district"
          >
            District
          </label>
          <select
            {...register("district", { required: "District is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="district"
          >
            <option value="">Select District</option>
            {districtOptions?.map((item) => {
              return <option value={item}>{item}</option>;
            })}
            {/* Add route options here */}
          </select>
          {errors.district && (
            <p className="text-red-500 text-sm mt-1">
              {errors.district.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="pincode"
          >
            Pincode
          </label>
          <input
            {...register("pincode", {
              required: "Pincode is required",
              // valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="pincode"
            type="text"
            placeholder="Pincode"
            maxLength={6}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              setValue("pincode", value);
            }}
          />
          {errors.pincode && (
            <p className="text-red-500 text-sm mt-1">
              {errors.pincode.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {isEditMode ? "Update Teacher" : "Add Teacher"}
        </button>
      </div>
    </form>
  );
}

export default AddTeacherForm;
