"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiCalendar, FiEdit2, FiTrash2, FiEye } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { format, parseISO } from "date-fns";
import { getRank } from "../../constants/AppConstant";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function ResultsPage() {
  const [activeNav, setActiveNav] = useState("My School");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const resultsPerPage = 9;
  const [filteredResults, setFilteredResults] = useState([]);
  const [originalResults, setOriginalResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({
    total: 0,
    pass: 0,
    fail: 0,
  });

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  useEffect(() => {
    if (userDetails?.school_id) {
      getResultData();
    }
  }, [userDetails?.school_id]);

  const getResultData = (page = 1) => {
    setIsLoading(true);
    axiosReq
      .get(apiEndponts.getCircular, {
        school_id: userDetails.school_id || "",
        model: "result",
        page,
      })
      .then((resp) => {
        const results = resp?.data?.map((item) => ({
          ...item,
          score: `${item?.score}/${item?.totalMarks}`,
          position: getRank(item?.rank),
          date: format(parseISO(item?.examDate), "dd/MM/yyyy"),
          status:
            parseInt(item?.score) >= parseInt(item?.totalMarks) / 2
              ? "Pass"
              : "Fail",
        }));

        setFilteredResults(results || []);
        setOriginalResults(results || []);
        setTotalPage(resp?.pagination?.pages);

        // Calculate stats
        const total = results.length;
        const passCount = results.filter(
          (item) => item.status === "Pass"
        ).length;
        const failCount = results.filter(
          (item) => item.status === "Fail"
        ).length;

        setStats({
          total,
          pass: passCount,
          fail: failCount,
        });
      })
      .catch((err) => {
        console.error("Error fetching results:", err);
        setFilteredResults([]);
        setOriginalResults([]);
        setStats({ total: 0, pass: 0, fail: 0 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = useCallback(
    debounce((value) => {
      if (!value.trim()) {
        setFilteredResults(originalResults);
      } else {
        const lowercasedFilter = value.toLowerCase();
        const filtered = originalResults.filter((result) => {
          return Object.keys(result).some((key) =>
            String(result[key]).toLowerCase().includes(lowercasedFilter)
          );
        });
        setFilteredResults(filtered);
      }
      setCurrentPage(1);
    }, 300),
    [originalResults]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handlePrevPage = () => {
    const newPage = currentPage - 1;
    if (newPage >= 1) {
      setCurrentPage(newPage);
      getResultData(newPage);
    }
  };

  const handleNextPage = () => {
    const newPage = currentPage + 1;
    if (newPage <= totalPage) {
      setCurrentPage(newPage);
      getResultData(newPage);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Add date filtering logic here if needed
  };

  const renderPagination = () => {
    if (!filteredResults.length || isLoading) return null;

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {(currentPage - 1) * resultsPerPage + 1} to{" "}
          {Math.min(currentPage * resultsPerPage, filteredResults.length)} of{" "}
          {filteredResults.length} entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => {
                setCurrentPage(number);
                getResultData(number);
              }}
              className={`px-2 py-1 text-xs rounded ${
                currentPage === number
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPage}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === totalPage
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Results stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-0">
                <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Total Results: {stats.total}
                </div>
                <div className="bg-white text-[#0A8F47] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Pass: {stats.pass}
                </div>
                <div className="bg-white text-[#B53D3E] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Fail: {stats.fail}
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <div className="relative">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yy"
                    placeholderText="Select date"
                    className="border border-[#C2C2C2] rounded-md pl-2 pr-6 py-1 w-24 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiCalendar
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#888888] pointer-events-none"
                    size={12}
                  />
                </div>
                <button className="bg-[#0662C6] text-[#FFFFFF] px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs">
                  + Add Result
                </button>
              </div>
            </div>

            {/* Results table */}
            <div className="bg-[#FFFFFF] shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Class Name</th>
                    <th className="px-3 py-2">Exam Type</th>
                    <th className="px-3 py-2">Student Name</th>
                    <th className="px-3 py-2">Score</th>
                    <th className="px-3 py-2">Positions</th>
                    <th className="px-3 py-2">Status</th>
                    <th className="px-3 py-2">Remarks</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs text-left">
                  {isLoading ? (
                    <tr>
                      <td colSpan="8" className="px-3 py-4 text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredResults.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="px-3 py-4 text-center">
                        No results found
                      </td>
                    </tr>
                  ) : (
                    filteredResults.map((result, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-2 text-[#0662C6]">
                          {result.className}
                        </td>
                        <td className="px-3 py-2 text-black">
                          {result.examType}
                          <br />
                          <span className="text-[#0662C6]">{result.date}</span>
                        </td>
                        <td className="px-3 py-2 font-medium text-black">
                          {result.studentName}
                        </td>
                        <td className="px-3 py-2 text-black">{result.score}</td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {result.position}
                        </td>
                        <td
                          className={`px-3 py-2 ${
                            result.status === "Pass"
                              ? "text-[#0A8F47]"
                              : "text-[#B53D3E]"
                          }`}
                        >
                          {result.status}
                        </td>
                        <td className="px-3 py-2 text-black">
                          {result.remarks}
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button
                              className="text-[#29A7E4] hover:text-[#113870]"
                              onClick={() => {
                                result?.photo?.forEach((element) => {
                                  window.open(element);
                                });
                              }}
                            >
                              <FiEye size={14} />
                            </button>
                            <button className="text-[#29A7E4] hover:text-[#113870]">
                              <FiEdit2 size={14} />
                            </button>
                            <button className="text-[#B53D3E] hover:text-[#113870]">
                              <FiTrash2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {renderPagination()}
          </div>
        </main>
      </div>
    </div>
  );
}
