import React, { useState, useCallback } from "react";
import { FiSearch, FiEdit2, FiTrash2, FiImage } from "react-icons/fi";
import Pagination from "../../components/Pagination";

// Sample data structure for tuitions
const tuitionsData = Array(6).fill({
  name: "Dronacharya Classes",
  teacherName: "Ravi Singh",
  timing: "5:00 Pm - 8:00 Pm",
  address: "Rajiv Calony , Hno 90 Sector 41",
  location: "Gurgaon, Haryana, 122021",
  phoneNo: "8545652132",
  tuitionFor: "Boys",
  subject: "All",
  class: "9th-12th",
  tuitionFee: "1500",
  photos: "5",
});

export default function TuitionPage() {
  const [activeNav, setActiveNav] = useState("All School");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const tuitionsPerPage = 6;
  const [filteredTuitions, setFilteredTuitions] = useState(tuitionsData);

  const handleSearch = useCallback(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filtered = tuitionsData.filter((tuition) => {
      return Object.keys(tuition).some((key) =>
        tuition[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredTuitions(filtered);
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Tuition stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Tuition: 50
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <button className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs">
                  + Add Tuition
                </button>
              </div>
            </div>

            {/* Tuitions table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Tuition Name</th>
                    <th className="px-3 py-2">Address</th>
                    <th className="px-3 py-2">Phone No.</th>
                    <th className="px-3 py-2">Tuition For</th>
                    <th className="px-3 py-2">Subject</th>
                    <th className="px-3 py-2">Class</th>
                    <th className="px-3 py-2">Tuition fee</th>
                    <th className="px-3 py-2">Photos</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {filteredTuitions.map((tuition, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">
                        <div className="text-[#0662C6]">{tuition.name}</div>
                        <div>{tuition.teacherName}</div>
                        <div className="text-[#888888]">{tuition.timing}</div>
                      </td>
                      <td className="px-3 py-2">
                        <div>{tuition.address}</div>
                        <div className="text-[#888888]">{tuition.location}</div>
                      </td>
                      <td className="px-3 py-2">{tuition.phoneNo}</td>
                      <td className="px-3 py-2">{tuition.tuitionFor}</td>
                      <td className="px-3 py-2">{tuition.subject}</td>
                      <td className="px-3 py-2">{tuition.class}</td>
                      <td className="px-3 py-2">{tuition.tuitionFee}</td>
                      <td className="px-3 py-2">
                        <div className="flex items-center">
                          <span>{tuition.photos}</span>
                          <FiImage className="ml-1 text-[#0662C6]" size={14} />
                        </div>
                      </td>
                      <td className="px-3 py-2">
                        <div className="flex space-x-2">
                          <button className="text-[#0662C6]">
                            <FiEdit2 size={14} />
                          </button>
                          <button className="text-[#B53D3E]">
                            <FiTrash2 size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredTuitions.length / tuitionsPerPage)}
              onNextPage={() => setCurrentPage((prev) => prev + 1)}
              isLastPage={
                currentPage * tuitionsPerPage >= filteredTuitions.length
              }
              isFirstPage={currentPage === 1}
              onPrevPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
