import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { stateAndDistrict } from "../../constants/AppConstant";
import { format } from "date-fns";
import { showToast } from "../../utils/toast";

function AddStudentForm({
  onSubmitSuccess,
  editMode,
  getStudents,
  isEditMode,
  editData,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const [photoPreview, setPhotoPreview] = useState(null);
  const [classOptions, setClassOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  const [filteredParents, setFilteredParents] = useState([]);
  const [search, setSearch] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      let { name, phone, originalAddress, guardianName } = editData;
      // selectDistrictOptions(originalAddress?.state)
      setSearch(guardianName);
      reset({
        name,
        class_id: editData?.class,
        phone,
        address: originalAddress?.name || "",
        district: originalAddress?.district || "",
        state: originalAddress?.state || "",
        pincode: originalAddress?.pincode,
      });
    }
  }, [isEditMode]);

  useEffect(() => {
    getClassNames();
  }, []);

  const getClassNames = () => {
    const query = JSON.stringify({
      school_id: userDetails?.school_id,
      session_id: userDetails?.session_id,
    });
    axiosReq
      .get(apiEndponts.getClassOfSchool, { query })
      .then((resp) => {
        const classOptions = resp?.records?.map((item) => ({
          name: item?.name,
          id: item?._id,
        }));
        setClassOptions(classOptions);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (data) => {
    // onSubmitSuccess();

    const payload = {
      name: data?.name,
      class_id: data?.class_id || "",
      session_id: userDetails?.session_id || "",
      admissionNo: data?.admissionNo || "",
      classRollNo: data?.classRollNo,
      parent_id: data?.parent_id,
      route_id: "64c11f38e3c0c7b289ed23f7",
      school_id: userDetails?.school_id || "",
      address: {
        name: data?.address || "",
        state: data?.state,
        district: data?.district,
        pincode: data?.pincode,
      },
      feeDiscountPercent: data?.feeDiscountPercent,
      remainingFee: 1500,
      hasPaidOneTimeFees: true,
      admissionDate: format(data.admissionDate, "yyyy-MM-dd"),
      DOB: format(data.DOB, "yyyy-MM-dd"),
      transportationCharges: data?.transportationCharges,
    };
    const payloadData = new FormData();
    payloadData.append("photo", data?.photo?.[0]);
    payloadData.append("Data", JSON.stringify(payload));

    axiosReq
      .post(apiEndponts?.addStudents, payloadData)
      .then((resp) => {
        console.log(resp, "resp");
        showToast.success("Student created successfully");
        onSubmitSuccess();
        getStudents();
      })
      .catch((err) => console.log(err));
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClassSelection = (option) => {};

  const onSearchChange = (value) => {
    value = value.replace(/\D/g, "");
    setSearch(value);
    if (value?.length === 10) {
      axiosReq
        .get(apiEndponts.getParentSearch, {
          school_id: userDetails?.school_id,
          phone: value,
        })
        .then((resp) => {
          if (!resp?.error) {
            setFilteredParents([resp]);
          }
          setShowDropdown(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const onSelect = (parent) => {
    setValue("parent_id", parent.id); // Update the selected value in the form
    setSearch(`${parent.name}`); // Display selected parent in input
    setShowDropdown(false); // Hide dropdown
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="name"
          >
            Student Name
          </label>
          <input
            {...register("name", { required: "Name is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
            type="text"
            placeholder="Student Name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="class_id"
          >
            Class
          </label>
          <select
            {...register("class_id", { required: "Class is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="class_id"
          >
            <option value="">Select Class</option>
            {/* Add class options here */}
            {classOptions?.map((item) => {
              return (
                <option value={item.id} onClick={handleClassSelection}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {errors.class_id && (
            <p className="text-red-500 text-sm mt-1">
              {errors.class_id.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="DOB"
          >
            Date of Birth
          </label>
          <Controller
            control={control}
            name="DOB"
            render={({ field }) => (
              <DatePicker
                placeholderText="Select date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                wrapperClassName="w-full"
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          />
          {errors.DOB && (
            <p className="text-red-500 text-sm mt-1">{errors.DOB.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="photo"
          >
            Photo
          </label>
          <input
            {...register("photo")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="photo"
            type="file"
            onChange={handlePhotoChange}
          />
          {photoPreview && (
            <img
              src={photoPreview}
              alt="Preview"
              className="mt-2 w-16 h-16 rounded-full"
            />
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="admissionNo"
          >
            Admission No.
          </label>
          <input
            {...register("admissionNo", {
              required: "Admission No. is required",
              valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="admissionNo"
            type="number"
            placeholder="Admission No."
          />
          {errors.admissionNo && (
            <p className="text-red-500 text-sm mt-1">
              {errors.admissionNo.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="admissionDate"
          >
            Admission Date
          </label>
          <Controller
            control={control}
            name="admissionDate"
            render={({ field }) => (
              <DatePicker
                placeholderText="Select date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                wrapperClassName="w-full"
                showMonthDropdown
                showYearDropdown
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          />
          {errors.admissionDate && (
            <p className="text-red-500 text-sm mt-1">
              {errors.admissionDate.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="aadharNo"
          >
            Aadhar No.
          </label>
          <input
            {...register("aadharNo", {
              required: "Aadhar No. is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="aadharNo"
            type="text"
            placeholder="Aadhar No."
            maxLength={12}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              setValue("aadharNo", value);
            }}
          />
          {errors.aadharNo && (
            <p className="text-red-500 text-sm mt-1">
              {errors.aadharNo.message}
            </p>
          )}
        </div>
        <div className="relative">
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="parent_id"
          >
            Parent
          </label>
          <input
            type="text"
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Search parent by mobile number"
            value={search}
            onChange={(e) => onSearchChange(e.target.value)}
          />

          <div
            className={`absolute bg-white border rounded-lg shadow-md w-full mt-1 max-h-40 overflow-y-auto ${
              showDropdown ? "block" : "hidden"
            }`}
          >
            {filteredParents.length > 0 ? (
              filteredParents.map((parent) => (
                <div
                  key={parent.id}
                  className="cursor-pointer px-4 py-2 hover:bg-blue-100"
                  onClick={() => onSelect(parent)}
                >
                  {parent.name}
                </div>
              ))
            ) : (
              <div className="px-4 py-2 text-gray-500">
                No matching parents found
              </div>
            )}
          </div>
          {/* <select
            {...register("parent_id", {
              required: "Parent/Guardian is required",
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="parent_id"
          >
            <option value="">Select Parent/Guardian</option>
            {/* Add parent options here 
          </select> */}
          {errors.parent_id && (
            <p className="text-red-500 text-sm mt-1">
              {errors.parent_id.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="route_id"
          >
            Route
          </label>
          <select
            {...register("route_id", {
              // required: "Route is required"
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="route_id"
          >
            <option value="">Select Route</option>
            {/* Add route options here */}
          </select>
          {errors.route_id && (
            <p className="text-red-500 text-sm mt-1">
              {errors.route_id.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="transportationCharges"
          >
            Transporation Charge
          </label>
          <input
            {...register("transportationCharges", {
              // required: "Aadhar No. is required",
              // valueAsNumber: true,
              maxLength: 16,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="transportationCharges"
            type="number"
            placeholder="Transporation Charge"
          />
          {/* {errors.aadharNo && (
            <p className="text-red-500 text-sm mt-1">
              {errors.aadharNo.message}
            </p>
          )} */}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="feeDiscountPercent"
          >
            Fee Discount (%)
          </label>
          <input
            {...register("feeDiscountPercent", {
              required: "Fee Discount is required",
              valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="feeDiscountPercent"
            type="number"
            placeholder="Fee Discount"
          />
          {errors.feeDiscountPercent && (
            <p className="text-red-500 text-sm mt-1">
              {errors.feeDiscountPercent.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="classRollNo"
          >
            Class Roll No.
          </label>
          <input
            {...register("classRollNo", {
              required: "Class Roll No. is required",
              valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="classRollNo"
            type="number"
            placeholder="Class Roll No."
          />
          {errors.classRollNo && (
            <p className="text-red-500 text-sm mt-1">
              {errors.classRollNo.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="address"
          >
            Address
          </label>
          <input
            {...register("address", {
              // required: "Class Roll No. is required",
              // valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="address"
            type="text"
            placeholder="Address"
          />
          {errors.classRollNo && (
            <p className="text-red-500 text-sm mt-1">
              {errors.classRollNo.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="state"
          >
            State
          </label>
          <select
            {...register("state", { required: "State is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="state"
            onChange={(e) => {
              const val = e.target.value;
              const districts = stateAndDistrict?.find(
                (item) => item?.state === val
              )?.districts;
              setDistrictOptions(districts);
            }}
          >
            <option value="">Select State</option>
            {stateAndDistrict?.map((item) => {
              return <option value={item?.state}>{item?.state}</option>;
            })}
            {/* Add route options here */}
          </select>
          {errors.state && (
            <p className="text-red-500 text-sm mt-1">{errors.state.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="district"
          >
            District
          </label>
          <select
            {...register("district", { required: "District is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="district"
          >
            <option value="">Select District</option>
            {districtOptions?.map((item) => {
              return <option value={item}>{item}</option>;
            })}
            {/* Add route options here */}
          </select>
          {errors.district && (
            <p className="text-red-500 text-sm mt-1">
              {errors.district.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="pincode"
          >
            Pincode
          </label>
          <input
            {...register("pincode", {
              required: "Pincode is required",
              // valueAsNumber: true,
            })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="pincode"
            type="text"
            placeholder="Pincode"
          />
          {errors.pincode && (
            <p className="text-red-500 text-sm mt-1">
              {errors.pincode.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add Student
        </button>
      </div>
    </form>
  );
}

export default AddStudentForm;
