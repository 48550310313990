import React, { useEffect, useState } from "react";
import { FiEdit2, FiTrash2, FiImage } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { showToast } from "../../utils/toast";

export default function SchoolBannersPage() {
  const [activeNav, setActiveNav] = useState("My School");
  const [bannerData, setBannerData] = useState([]);
  const [selectFile, setSelectFile] = useState(null);
  const [selectEditFile, setSelectEditFile] = useState({
    file: null,
    bannerId: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const bannersPerPage = 6;
  const totalPages = Math.ceil((bannerData.length + 3) / bannersPerPage);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);

  useEffect(() => {
    getBanners();
  }, []);
  useEffect(() => {
    if (selectFile) {
      uploadBanner();
    }
  }, [selectFile]);

  useEffect(() => {
    if (selectEditFile.file) {
      editBanner();
    }
  }, [selectEditFile]);

  const getBanners = () => {
    axiosReq
      .get(apiEndponts.getBanner, {
        query: JSON.stringify({ school_id: localStorage.getItem("schoolId") }),
      })
      .then((resp) => {
        const banners = resp?.records; //?.filter(item =>item?.photo?.length);
        const modifiedBannerData = [];
        banners?.forEach((banner) => {
          const obj = {
            id: banner?._id,
            image: banner?.photo?.[0] || "",
            title: "",
            date: "",
          };
          modifiedBannerData?.push(obj);
        });
        setBannerData(modifiedBannerData);
      })
      .catch((err) => {});
  };

  const uploadBanner = () => {
    const data = new FormData();
    data.append("photo", selectFile);
    data.append(
      "Data",
      JSON.stringify({
        school_id: localStorage.getItem("schoolId"),
        name: "School Banner",
        uploadedAt: new Date(),
        expiryDate: new Date(),
      })
    );
    axiosReq
      .post(apiEndponts.addBanner, data)
      .then((resp) => {
        getBanners();
      })
      .catch((err) => console.log(err.response));
  };
  const editBanner = () => {
    const data = new FormData();
    data.append("photo", selectEditFile.file);
    data.append(
      "Data",
      JSON.stringify({
        name: "Banner",
      })
    );
    const url = `${apiEndponts.addBanner}/${selectEditFile.bannerId}`;
    axiosReq
      .put(url, data)
      .then((resp) => {
        getBanners();
      })
      .catch((err) => console.log(err.response));
  };

  const createSequentialArray = (length) => {
    return Array.from({ length }, (_, index) => index + 1);
  };

  const handleDelete = (id) => {
    const url = `${apiEndponts.deleteBanner}${selectedBanner?.id}`;
    axiosReq
      .delete(url)
      .then((resp) => {
        getBanners();
        setIsDeleteModalOpen(false);
        showToast.success("Banner deleted");
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteClick = (banner) => {
    setSelectedBanner(banner);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    const updatedBanners = bannerData.filter(
      (banner) => banner !== selectedBanner
    );
    setIsDeleteModalOpen(false);
    setSelectedBanner(null);
  };

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs overflow-hidden text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                School Banners
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {bannerData.map((banner) => (
                <div
                  key={banner.id}
                  className="relative rounded-lg overflow-hidden shadow-md"
                >
                  <img
                    src={banner.image}
                    alt={banner.title}
                    className="w-full h-15 object-cover"
                  />
                  {/* <div className="absolute bottom-2 left-2 bg-white px-2 py-1 rounded text-xs">
                    {banner.title} | {banner.date}
                  </div> */}
                  <div className="absolute bottom-2 right-2 flex space-x-1">
                    <input
                      type="file"
                      id={`file-input-${banner.id}`}
                      accept=".jpg, .jpeg, .png"
                      className="hidden"
                      onChange={(e) => {
                        const file = e.target?.files?.[0];
                        setSelectEditFile({ file, bannerId: banner.id });
                        e.target.value = "";
                      }}
                    />
                    <label
                      htmlFor={`file-input-${banner.id}`}
                      className="p-1 bg-white rounded-full shadow-md cursor-pointer"
                    >
                      <FiEdit2 className="text-[#0662C6]" size={16} />
                    </label>
                    {/* <button className="p-1 bg-white rounded-full shadow-md" onClick={() =>handleDelete(banner.id)}> */}

                    <button
                      className="p-1 bg-white rounded-full shadow-md"
                      onClick={() => handleDeleteClick(banner)}
                    >
                      <FiTrash2 className="text-red-500" size={16} />
                    </button>
                  </div>
                </div>
              ))}
              {createSequentialArray(
                bannerData?.length < 6 ? 6 - bannerData?.length : 0
              ).map((placeholder) => (
                <div
                  key={`placeholder-${placeholder}`}
                  className="border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center h-15"
                >
                  <input
                    type="file"
                    id={`file-input-${placeholder}`}
                    accept=".jpg, .jpeg, .png"
                    className="hidden"
                    onChange={(e) => {
                      const file = e.target?.files?.[0];
                      setSelectFile(file);
                      e.target.value = "";
                    }}
                  />
                  <label
                    htmlFor={`file-input-${placeholder}`}
                    className="flex items-center justify-center text-[#0662C6] font-medium cursor-pointer"
                  >
                    {/* <button className="flex items-center justify-center text-[#0662C6] font-medium"> */}
                    <FiImage className="mr-2" size={20} />
                    Add Banner
                    {/* </button> */}
                  </label>
                </div>
              ))}
            </div>

            {/* Pagination */}
            {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNextPage={handleNextPage}
              onPrevPage={handlePrevPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
            /> */}
          </div>
        </main>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
        itemName={selectedBanner ? "this banner" : ""}
      />
    </div>
  );
}
