import React from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

const Pagination = ({
  currentPage,
  totalPages,
  onNextPage,
  onPrevPage,
  isFirstPage,
  isLastPage,
}) => {
  return (
    <div className="mt-4 flex justify-center items-center">
      <div className="flex items-center space-x-4">
        <button
          onClick={onPrevPage}
          className={`flex items-center justify-center p-2 bg-[#0662C6] text-white rounded-full transition-colors duration-200 ${
            isFirstPage ? "opacity-50 cursor-not-allowed" : "hover:bg-[#113870]"
          }`}
          disabled={isFirstPage}
        >
          <FiChevronLeft size={16} />
        </button>
        <span className="text-[#888888]">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={onNextPage}
          className={`flex items-center justify-center p-2 bg-[#0662C6] text-white rounded-full transition-colors duration-200 ${
            isLastPage ? "opacity-50 cursor-not-allowed" : "hover:bg-[#113870]"
          }`}
          disabled={isLastPage}
        >
          <FiChevronRight size={16} />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
