"use client";
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { FiSearch, FiCalendar } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import apiEndpoints from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { format } from "date-fns";
import { toast } from "react-hot-toast";

// Action Types
const FILTER_ACTIONS = {
  SET_SEARCH: "SET_SEARCH",
  SET_DATE: "SET_DATE",
  SET_MONTH: "SET_MONTH",
  SET_CLASS: "SET_CLASS",
  SET_SECTION: "SET_SECTION",
  SET_PAGE: "SET_PAGE",
  RESET_FILTERS: "RESET_FILTERS",
};

const DATA_ACTIONS = {
  SET_DATA: "SET_DATA",
  SET_LOADING: "SET_LOADING",
  SET_CLASSES: "SET_CLASSES",
  SET_SECTIONS: "SET_SECTIONS",
  UPDATE_REQUEST: "UPDATE_REQUEST",
};

// Initial States
const initialFilterState = {
  searchTerm: "",
  selectedDate: null,
  selectedMonth: null,
  selectedClassId: "",
  selectedSectionId: "",
  currentPage: 1,
};

const initialDataState = {
  requests: [],
  originalRequests: [],
  classes: [],
  sections: [],
  isLoading: false,
  totalPages: 1,
  stats: {
    total: 0,
    approved: 0,
    rejected: 0,
    pending: 0,
  },
};

// Reducers
const filterReducer = (state, action) => {
  switch (action.type) {
    case FILTER_ACTIONS.SET_SEARCH:
      return { ...state, searchTerm: action.payload, currentPage: 1 };
    case FILTER_ACTIONS.SET_DATE:
      return { ...state, selectedDate: action.payload, currentPage: 1 };
    case FILTER_ACTIONS.SET_MONTH:
      return {
        ...state,
        selectedMonth: action.payload,
        selectedDate: null,
        currentPage: 1,
      };
    case FILTER_ACTIONS.SET_CLASS:
      return {
        ...state,
        selectedClassId: action.payload,
        selectedSectionId: "",
        currentPage: 1,
      };
    case FILTER_ACTIONS.SET_SECTION:
      return { ...state, selectedSectionId: action.payload, currentPage: 1 };
    case FILTER_ACTIONS.SET_PAGE:
      return { ...state, currentPage: action.payload };
    case FILTER_ACTIONS.RESET_FILTERS:
      return initialFilterState;
    default:
      return state;
  }
};

const dataReducer = (state, action) => {
  switch (action.type) {
    case DATA_ACTIONS.SET_DATA:
      return {
        ...state,
        requests: action.payload.requests,
        originalRequests: action.payload.requests,
        totalPages: action.payload.totalPages,
        stats: action.payload.stats,
      };
    case DATA_ACTIONS.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case DATA_ACTIONS.SET_CLASSES:
      return { ...state, classes: action.payload };
    case DATA_ACTIONS.SET_SECTIONS:
      return { ...state, sections: action.payload };
    case DATA_ACTIONS.UPDATE_REQUEST:
      const { requestId, status } = action.payload;
      const updatedRequests = state.requests.map((request) =>
        request.id === requestId ? { ...request, status } : request
      );
      return {
        ...state,
        requests: updatedRequests,
        originalRequests: updatedRequests,
        stats: calculateStats(updatedRequests),
      };
    default:
      return state;
  }
};

// Utility functions
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const calculateStats = (requests) => {
  return requests.reduce(
    (acc, req) => ({
      total: acc.total + 1,
      approved: acc.approved + (req.status === "Approved" ? 1 : 0),
      rejected: acc.rejected + (req.status === "Rejected" ? 1 : 0),
      pending: acc.pending + (req.status === "Pending" ? 1 : 0),
    }),
    { total: 0, approved: 0, rejected: 0, pending: 0 }
  );
};

const formatRequests = (requests) => {
  return requests.map((item) => ({
    id: item._id,
    studentName: item.studentName,
    photo: item.studentPhoto?.[0] || null,
    rollNo: item.studentRollNo,
    className: item.className,
    duration: formatDuration(item.dateRange.from, item.dateRange.to),
    reason: item.reason,
    status:
      item.approved === undefined
        ? "Pending"
        : item.approved
        ? "Approved"
        : "Rejected",
    dateRange: item.dateRange,
  }));
};

const formatDuration = (fromDate, toDate) => {
  if (!fromDate || !toDate) return "N/A";
  const from = format(new Date(fromDate), "dd/MM/yyyy");
  const to = format(new Date(toDate), "dd/MM/yyyy");
  return from === to ? from : `${from} - ${to}`;
};
export default function StudentLeavePage() {
  const [filterState, filterDispatch] = useReducer(
    filterReducer,
    initialFilterState
  );
  const [dataState, dataDispatch] = useReducer(dataReducer, initialDataState);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // API parameters
  const getApiParams = useMemo(
    () => ({
      school_id: userDetails?.school_id || "",
      model: "studentleave",
      page: filterState.currentPage,
      month: filterState.selectedMonth
        ? filterState.selectedMonth.getMonth() + 1
        : null,
      date: filterState.selectedDate
        ? format(filterState.selectedDate, "yyyy-MM-dd")
        : "",
      class_id: filterState.selectedClassId,
      section_id: filterState.selectedSectionId,
    }),
    [filterState, userDetails?.school_id]
  );

  // Data fetching
  const fetchData = useCallback(async () => {
    dataDispatch({ type: DATA_ACTIONS.SET_LOADING, payload: true });
    try {
      const response = await axiosReq.get(
        apiEndpoints.getCircular,
        getApiParams
      );
      if (response.data) {
        const formattedRequests = formatRequests(response.data);
        dataDispatch({
          type: DATA_ACTIONS.SET_DATA,
          payload: {
            requests: formattedRequests,
            totalPages: response.pagination.pages,
            stats: calculateStats(formattedRequests),
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch leave requests");
    } finally {
      dataDispatch({ type: DATA_ACTIONS.SET_LOADING, payload: false });
    }
  }, [getApiParams]);

  const fetchClasses = useCallback(async () => {
    try {
      const query = JSON.stringify({
        school_id: userDetails?.school_id,
        session_id: userDetails?.session_id,
      });
      const response = await axiosReq.get(apiEndpoints.getClassOfSchool, {
        query,
      });
      const classOptions = response?.records?.map((item) => ({
        name: item?.name,
        id: item?._id,
      }));
      dataDispatch({ type: DATA_ACTIONS.SET_CLASSES, payload: classOptions });
    } catch (error) {
      console.error("Error fetching classes:", error);
      toast.error("Failed to fetch classes");
    }
  }, [userDetails]);

  // Initial data load
  useEffect(() => {
    if (userDetails?.school_id) {
      fetchData();
      fetchClasses();
    }
  }, [userDetails?.school_id, fetchData, fetchClasses]);

  // Event handlers
  const handleSearch = useCallback(
    debounce((value) => {
      if (!value.trim()) {
        dataDispatch({
          type: DATA_ACTIONS.SET_DATA,
          payload: {
            requests: dataState.originalRequests,
            totalPages: Math.ceil(dataState.originalRequests.length / 10),
            stats: calculateStats(dataState.originalRequests),
          },
        });
        return;
      }

      const filtered = dataState.originalRequests.filter((request) =>
        Object.values(request)
          .join(" ")
          .toLowerCase()
          .includes(value.toLowerCase())
      );

      dataDispatch({
        type: DATA_ACTIONS.SET_DATA,
        payload: {
          requests: filtered,
          totalPages: Math.ceil(filtered.length / 10),
          stats: calculateStats(filtered),
        },
      });
    }, 300),
    [dataState.originalRequests]
  );

  const handleFilterChange = useCallback(
    (type, value) => {
      filterDispatch({ type, payload: value });
      if (type !== FILTER_ACTIONS.SET_SEARCH) {
        fetchData();
      }
    },
    [fetchData]
  );

  const handleLeaveAction = async (requestId, action) => {
    try {
      const formData = new FormData();
      formData.append(
        "Data",
        JSON.stringify({
          approved: action === "Approved",
        })
      );
      await axiosReq.put(
        `${apiEndpoints.updateStudentLeaveStatus}/${requestId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      dataDispatch({
        type: DATA_ACTIONS.UPDATE_REQUEST,
        payload: { requestId, status: action },
      });
      toast.success(`Leave request ${action.toLowerCase()} successfully`);
    } catch (error) {
      console.error(`Error ${action.toLowerCase()}ing leave:`, error);
      toast.error(`Failed to ${action.toLowerCase()} leave request`);
      fetchData();
    }
  };

  // Memoized render components
  const renderStats = useMemo(
    () => (
      <div className="flex">
        <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium">
          Total Requests: {dataState.stats.total}
        </div>
        <div className="bg-white text-[#0A8F47] px-2 py-1 border border-[#C2C2C2] font-medium">
          Approved: {dataState.stats.approved}
        </div>
        <div className="bg-white text-[#B53D3E] px-2 py-1 border border-[#C2C2C2] font-medium">
          Rejected: {dataState.stats.rejected}
        </div>
        <div className="bg-white text-[#F0AD4E] px-2 py-1 border border-[#C2C2C2] font-medium">
          Pending: {dataState.stats.pending}
        </div>
      </div>
    ),
    [dataState.stats]
  );

  const renderFilters = useMemo(
    () => (
      <div className="flex items-center space-x-2">
        <select
          value={filterState.selectedClassId}
          onChange={(e) =>
            handleFilterChange(FILTER_ACTIONS.SET_CLASS, e.target.value)
          }
          className="border border-[#C2C2C2] rounded-md px-2 py-1 w-40 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
        >
          <option value="">All Classes</option>
          {dataState.classes.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.name}
            </option>
          ))}
        </select>

        <select
          value={filterState.selectedSectionId}
          onChange={(e) =>
            handleFilterChange(FILTER_ACTIONS.SET_SECTION, e.target.value)
          }
          className="border border-[#C2C2C2] rounded-md px-2 py-1 w-40 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
          disabled={!filterState.selectedClassId}
        >
          <option value="">All Sections</option>
          {dataState.sections.map((section) => (
            <option key={section.id} value={section.id}>
              {section.name}
            </option>
          ))}
        </select>

        <div className="relative">
          <DatePicker
            selected={filterState.selectedMonth}
            onChange={(date) =>
              handleFilterChange(FILTER_ACTIONS.SET_MONTH, date)
            }
            dateFormat="MMMM"
            showMonthYearPicker
            placeholderText="Select month"
            className="border border-[#C2C2C2] rounded-md pl-2 pr-6 py-1 w-32 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4]"
          />
          <FiCalendar
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#888888] pointer-events-none"
            size={12}
          />
        </div>

        <div className="relative">
          <DatePicker
            selected={filterState.selectedDate}
            onChange={(date) =>
              handleFilterChange(FILTER_ACTIONS.SET_DATE, date)
            }
            dateFormat="dd/MM/yy"
            placeholderText="Select date"
            className="border border-[#C2C2C2] rounded-md pl-2 pr-6 py-1 w-24 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4]"
          />
          <FiCalendar
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#888888] pointer-events-none"
            size={12}
          />
        </div>

        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            value={filterState.searchTerm}
            onChange={(e) => {
              const value = e.target.value;
              handleFilterChange(FILTER_ACTIONS.SET_SEARCH, value);
              handleSearch(value);
            }}
            className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4]"
          />
          <FiSearch
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
            size={12}
          />
        </div>
      </div>
    ),
    [
      filterState,
      dataState.classes,
      dataState.sections,
      handleFilterChange,
      handleSearch,
    ]
  );

  const renderTable = useMemo(
    () => (
      <div className="bg-[#FFFFFF] shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
        <table className="w-full">
          <thead>
            <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
              <th className="px-3 py-2">Photo</th>
              <th className="px-3 py-2">Student Name</th>
              <th className="px-3 py-2">Roll No</th>
              <th className="px-3 py-2">Class</th>
              <th className="px-3 py-2">Duration</th>
              <th className="px-3 py-2">Reason</th>
              <th className="px-3 py-2">Status</th>
              <th className="px-3 py-2">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-[#C2C2C2] text-xs">
            {dataState.isLoading ? (
              <tr>
                <td colSpan="8" className="px-3 py-4 text-center">
                  Loading...
                </td>
              </tr>
            ) : dataState.requests.length === 0 ? (
              <tr>
                <td colSpan="8" className="px-3 py-4 text-center">
                  No leave requests found
                </td>
              </tr>
            ) : (
              dataState.requests.map((request) => (
                <tr key={request.id} className="hover:bg-gray-50">
                  <td className="px-3 py-2">
                    <img
                      src={request.photo || "/default-avatar.png"}
                      alt={request.studentName}
                      className="w-8 h-8 rounded-full object-cover"
                      onError={(e) => {
                        e.target.src = "/default-avatar.png";
                      }}
                    />
                  </td>
                  <td className="px-3 py-2">
                    <span className="font-medium text-[#0662C6]">
                      {request.studentName}
                    </span>
                  </td>
                  <td className="px-3 py-2">{request.rollNo}</td>
                  <td className="px-3 py-2">{request.className}</td>
                  <td className="px-3 py-2">{request.duration}</td>
                  <td className="px-3 py-2">{request.reason}</td>
                  <td className="px-3 py-2">
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        request.status === "Approved"
                          ? "bg-[#E8F5E9] text-[#0A8F47]"
                          : request.status === "Rejected"
                          ? "bg-[#FFEBEE] text-[#B53D3E]"
                          : "bg-[#FFF3E0] text-[#F0AD4E]"
                      }`}
                    >
                      {request.status}
                    </span>
                  </td>
                  <td className="px-3 py-2">
                    {request.status === "Pending" && (
                      <div className="flex space-x-2">
                        <button
                          onClick={() =>
                            handleLeaveAction(request.id, "Approved")
                          }
                          className="px-2 py-1 bg-white text-[#0A8F47] rounded-md border border-[#0A8F47] hover:bg-[#0A8F47] hover:text-white transition-colors duration-200"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() =>
                            handleLeaveAction(request.id, "Rejected")
                          }
                          className="px-2 py-1 bg-white text-[#B53D3E] rounded-md border border-[#B53D3E] hover:bg-[#B53D3E] hover:text-white transition-colors duration-200"
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    ),
    [dataState.isLoading, dataState.requests, handleLeaveAction]
  );

  const renderPagination = useMemo(() => {
    if (!dataState.requests.length || dataState.isLoading) return null;

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {(filterState.currentPage - 1) * 10 + 1} to{" "}
          {Math.min(filterState.currentPage * 10, dataState.requests.length)} of{" "}
          {dataState.total} entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={() =>
              handleFilterChange(
                FILTER_ACTIONS.SET_PAGE,
                filterState.currentPage - 1
              )
            }
            disabled={filterState.currentPage === 1}
            className={`px-2 py-1 text-xs rounded ${
              filterState.currentPage === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {[...Array(dataState.totalPages)].map((_, i) => (
            <button
              key={i + 1}
              onClick={() => handleFilterChange(FILTER_ACTIONS.SET_PAGE, i + 1)}
              className={`px-2 py-1 text-xs rounded ${
                filterState.currentPage === i + 1
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() =>
              handleFilterChange(
                FILTER_ACTIONS.SET_PAGE,
                filterState.currentPage + 1
              )
            }
            disabled={filterState.currentPage === dataState.totalPages}
            className={`px-2 py-1 text-xs rounded ${
              filterState.currentPage === dataState.totalPages
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  }, [
    filterState.currentPage,
    dataState.totalPages,
    dataState.requests.length,
    dataState.isLoading,
    handleFilterChange,
  ]);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            <div className="flex justify-between items-center mb-3">
              {renderStats}
              {renderFilters}
            </div>
            {renderTable}
            {renderPagination}
          </div>
        </main>
      </div>
    </div>
  );
}
