import React from 'react';

export const FeatureCard = ({ icon: Icon, title, description }) => {
  return (
    <div className="p-6 rounded-xl border border-gray-200 hover:border-indigo-600 transition-colors group">
      <Icon className="h-12 w-12 text-indigo-600 mb-4" />
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}; 