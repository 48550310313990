import React, { useState, useEffect } from "react";
import { FaPhone, FaMapMarkerAlt, FaIdCard, FaBuilding } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const SchoolProfile = ({ activeNav, setActiveNav }) => {
  const navigate = useNavigate();
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const schoolId = localStorage.getItem("schoolId");
        if (!schoolId) {
          throw new Error("School ID not found");
        }
        const response = await fetch(
          `https://schooleg.com/Schooleg/schools/${schoolId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch school details");
        }
        const data = await response.json();
        setSchoolDetails(data);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching school details:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchSchoolDetails();
  }, []);
  const handleEditClick = () => {
    const schoolId = localStorage.getItem("schoolId");
    navigate(`/school/edit/${schoolId}`);
  };
  const FeatureCard = ({ title, enabled }) => (
    <div className="flex items-center gap-2 bg-white p-2 rounded-lg shadow-sm border border-gray-100">
      <div
        className={`w-2 h-2 rounded-full ${
          enabled ? "bg-green-500" : "bg-red-500"
        }`}
      ></div>
      <span className="text-gray-700 text-xs">{title}</span>
    </div>
  );
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return (
    <div className="flex h-full text-left">
      <div className="flex-1 flex flex-col">
        <main className="flex-1 p-8 bg-white overflow-hidden">
          <div className="h-full">
            {/* Hero Section*/}
            <div className="bg-white rounded-lg shadow-sm">
              <div className="h-32 bg-gradient-to-r from-blue-500 to-cyan-400 relative">
                {schoolDetails?.photo?.[0] && (
                  <img
                    src={schoolDetails.photo[0]}
                    alt="School Cover"
                    className="w-full h-full object-fill"
                  />
                )}
                <div className="absolute -bottom-12 left-6">
                  <div className="w-24 h-24 rounded-full border-4 border-white bg-white shadow-lg overflow-hidden">
                    <img
                      src={
                        schoolDetails?.photo?.[0] || "/default-school-logo.png"
                      }
                      alt={schoolDetails?.schoolName}
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-14 px-6 pb-4 flex justify-between items-start">
                <div>
                  <h1 className="text-xl font-bold text-gray-900">
                    {schoolDetails?.schoolName}
                  </h1>
                  <p className="mt-1 text-gray-600 text-sm">
                    {schoolDetails?.about}
                  </p>
                </div>
                <button
                  onClick={handleEditClick}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-sm"
                >
                  Edit School
                </button>
              </div>
            </div>
            {/* Details Grid */}
            <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-3">
              {/* Contact Information */}
              <div className="bg-white rounded-lg shadow-sm p-4">
                <h2 className="text-lg font-semibold mb-3 text-gray-800">
                  Contact Information
                </h2>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <FaBuilding className="text-blue-500 w-4 h-4" />
                    <div>
                      <p className="text-xs text-gray-500">Contact Person</p>
                      <p className="text-sm text-gray-700">
                        {schoolDetails?.contactPersonName}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <FaPhone className="text-blue-500 w-4 h-4" />
                    <div>
                      <p className="text-xs text-gray-500">Phone</p>
                      <p className="text-sm text-gray-700">
                        {schoolDetails?.phone}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <FaMapMarkerAlt className="text-blue-500 w-4 h-4" />
                    <div>
                      <p className="text-xs text-gray-500">Address</p>
                      <p className="text-sm text-gray-700">
                        {schoolDetails?.address?.name},{" "}
                        {schoolDetails?.address?.district},
                        {schoolDetails?.address?.state} -{" "}
                        {schoolDetails?.address?.pincode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Features & Services */}
              <div className="bg-white rounded-lg shadow-sm p-4">
                <h2 className="text-lg font-semibold mb-3 text-gray-800">
                  Features & Services
                </h2>
                <div className="grid grid-cols-2 gap-2">
                  <FeatureCard
                    title="GPS Tracking"
                    enabled={schoolDetails?.gpsRequired}
                  />
                  <FeatureCard
                    title="Bus Camera"
                    enabled={schoolDetails?.busCameraRequired}
                  />
                  <FeatureCard
                    title="School Camera"
                    enabled={schoolDetails?.schoolCameraRequired}
                  />
                  <FeatureCard
                    title="SMS Service"
                    enabled={schoolDetails?.smsRequired}
                  />
                  <FeatureCard
                    title="Fee Management"
                    enabled={schoolDetails?.feeManagement}
                  />
                  <FeatureCard
                    title="Trip Management"
                    enabled={schoolDetails?.tripManagement}
                  />
                </div>
              </div>
              {/* Additional Information */}
              <div className="bg-white rounded-lg shadow-sm p-4 md:col-span-2">
                <h2 className="text-lg font-semibold mb-3 text-gray-800">
                  Additional Information
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                  <div className="p-3 bg-gray-50 rounded-lg">
                    <p className="text-xs text-gray-500">Fee Due Date</p>
                    <p className="text-sm font-medium text-gray-800">
                      {schoolDetails?.feeDueDate}th of every month
                    </p>
                  </div>
                  <div className="p-3 bg-gray-50 rounded-lg">
                    <p className="text-xs text-gray-500">Platform Fee</p>
                    <p className="text-sm font-medium text-gray-800">
                      ₹{schoolDetails?.platformFee || "N/A"}
                    </p>
                  </div>
                  <div className="p-3 bg-gray-50 rounded-lg">
                    <p className="text-xs text-gray-500">Subscription Type</p>
                    <p className="text-sm font-medium text-gray-800">
                      {schoolDetails?.yearly ? "Yearly" : "Monthly"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default SchoolProfile;
