"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiDownload, FiInfo } from "react-icons/fi";
import apiEndpoints from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";

export default function FeesPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFees, setFilteredFees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [feesPerPage] = useState(10);
  const [feesStats, setFeesStats] = useState({
    total: 0,
    totalConcessionGiven: 0,
    totalNetFee: 0,
    received: 0,
    pending: 0,
  });

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get current fees
  const indexOfLastFee = currentPage * feesPerPage;
  const indexOfFirstFee = indexOfLastFee - feesPerPage;
  const currentFees = filteredFees.slice(indexOfFirstFee, indexOfLastFee);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle month change
  const handleMonthChange = (e) => {
    const month = e.target.value;
    setSelectedMonth(month);
    setCurrentPage(1); // Reset to first page when filter changes
    if (month) {
      getFees(month, selectedYear);
    } else {
      getFees();
    }
  };

  // Function to handle year change
  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    setCurrentPage(1); // Reset to first page when filter changes
    if (selectedMonth) {
      getFees(selectedMonth, year);
    }
  };

  const getFees = async (month = "", year = "") => {
    setIsLoading(true);
    try {
      const params = {
        school_id: userDetails?.school_id,
        session_id: userDetails?.session_id,
      };

      if (month && year) {
        params.month = `${month} ${year}`;
      }

      const response = await axiosReq.get(apiEndpoints.getFeeSummary, params);
      if (response?.success && response?.data) {
        const invoices =
          response.data.invoices?.map((invoice) => ({
            photo: invoice.studentPhoto?.[0] || "/student-avatar.jpg",
            studentName: invoice.studentName || "",
            rollNo: invoice.rollNumber || "",
            class: invoice.className || "",
            date: invoice.payment_details?.paymentDate
              ? new Date(
                  invoice.payment_details.paymentDate
                ).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })
              : invoice.month || "",
            feeType: invoice.feeType || "",
            totalFee: `Rs ${invoice.totalFee?.toLocaleString() || 0}`,
            submitted: `Rs ${invoice.paidAmount?.toLocaleString() || 0}`,
            pending: `Rs ${invoice.pendingAmount?.toLocaleString() || 0}`,
            invoice: invoice.payment_details?.transactionId || "-",
          })) || [];

        setFilteredFees(invoices);

        const stats = response.data.totals || {};
        setFeesStats({
          total: stats.total || 0,
          totalConcessionGiven: stats.totalConcessionGiven || 0,
          totalNetFee: stats.totalNetFee || 0,
          received: stats.received || 0,
          pending: stats.pending || 0,
        });
      }
    } catch (err) {
      console.error("Error fetching fees:", err);
      setFilteredFees([]);
      setFeesStats({
        total: 0,
        totalConcessionGiven: 0,
        totalNetFee: 0,
        received: 0,
        pending: 0,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Search function
  const handleSearch = useCallback(
    (e) => {
      const searchValue = e.target.value;
      setSearchTerm(searchValue);
      setCurrentPage(1); // Reset to first page when searching

      if (!searchValue.trim()) {
        getFees(selectedMonth, selectedYear);
        return;
      }

      const filtered = filteredFees.filter((fee) => {
        const searchStr = searchValue.toLowerCase();
        return (
          fee.studentName.toLowerCase().includes(searchStr) ||
          fee.rollNo.toString().includes(searchStr) ||
          fee.class.toLowerCase().includes(searchStr) ||
          fee.invoice.toLowerCase().includes(searchStr)
        );
      });
      setFilteredFees(filtered);
    },
    [selectedMonth, selectedYear]
  );

  // Initial load
  useEffect(() => {
    if (userDetails?.school_id && userDetails?.session_id) {
      getFees();
    }
  }, [userDetails?.school_id, userDetails?.session_id]);
  // ... continued from Part 1

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Fee stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-x-0">
                <div className="bg-[#E7F7FF] text-[#113870] px-3 py-2 border border-[#C2C2C2] font-medium">
                  <div>Total Receiving</div>
                  <div>Rs {feesStats.totalNetFee.toLocaleString()}</div>
                </div>
                <div className="bg-[#E7FFE7] text-[#00A76F] px-3 py-2 border border-[#C2C2C2] font-medium">
                  <div>Received</div>
                  <div>Rs {feesStats.received.toLocaleString()}</div>
                </div>
                <div className="bg-[#FFE7E7] text-[#B53D3E] px-3 py-2 border border-[#C2C2C2] font-medium">
                  <div>Pending</div>
                  <div>Rs {feesStats.pending.toLocaleString()}</div>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4]"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4]"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  <option value={currentYear}>{`${currentYear}-${String(
                    currentYear + 1
                  ).slice(2)}`}</option>
                </select>
                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4]"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option value="">All Months</option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                <button className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200">
                  + Add Fees
                </button>
              </div>
            </div>

            {/* Fees table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Photo</th>
                    <th className="px-3 py-2">Student Name</th>
                    <th className="px-3 py-2">Date</th>
                    <th className="px-3 py-2">Fee Type</th>
                    <th className="px-3 py-2">Total Fee</th>
                    <th className="px-3 py-2">Submitted</th>
                    <th className="px-3 py-2">Pending</th>
                    <th className="px-3 py-2">Invoice</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="9" className="px-3 py-4 text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : currentFees.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="px-3 py-4 text-center">
                        No fees data available
                      </td>
                    </tr>
                  ) : (
                    currentFees.map((fee, index) => (
                      <tr key={index}>
                        <td className="px-3 py-2">
                          <img
                            src={fee.photo}
                            alt=""
                            className="w-8 h-8 rounded-full"
                          />
                        </td>
                        <td className="px-3 py-2">
                          <div>
                            {fee.studentName}{" "}
                            <span className="text-[#888888]">
                              (Roll No: {fee.rollNo})
                            </span>
                          </div>
                          <div className="text-[#0662C6]">
                            Class - {fee.class}
                          </div>
                        </td>
                        <td className="px-3 py-2">{fee.paymentDate}</td>
                        <td className="px-3 py-2">{fee.feeType}</td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {fee.totalFee}
                        </td>
                        <td className="px-3 py-2 text-[#00A76F]">
                          {fee.submitted}
                        </td>
                        <td className="px-3 py-2 text-[#B53D3E]">
                          {fee.pending}
                        </td>
                        <td className="px-3 py-2">{fee.invoice}</td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button className="text-[#0662C6]">
                              <FiDownload size={14} />
                            </button>
                            <button className="text-[#0662C6]">
                              <FiInfo size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {!isLoading && filteredFees.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {indexOfFirstFee + 1} to{" "}
                  {Math.min(indexOfLastFee, filteredFees.length)} of{" "}
                  {filteredFees.length} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>
                  {[...Array(Math.ceil(filteredFees.length / feesPerPage))].map(
                    (_, i) => (
                      <button
                        key={i + 1}
                        onClick={() => paginate(i + 1)}
                        className={`px-3 py-1 rounded ${
                          currentPage === i + 1
                            ? "bg-[#0662C6] text-white"
                            : "bg-white text-[#0662C6] hover:bg-gray-50"
                        } border border-[#C2C2C2]`}
                      >
                        {i + 1}
                      </button>
                    )
                  )}
                  <button
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil(filteredFees.length / feesPerPage)
                    }
                    className={`px-3 py-1 rounded ${
                      currentPage ===
                      Math.ceil(filteredFees.length / feesPerPage)
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
