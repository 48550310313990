"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiEdit2, FiTrash2, FiUser, FiX } from "react-icons/fi";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { useSelector } from "react-redux";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import AddTeacherForm from "./AddTeacherForm";
import { format } from "date-fns";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function TeachersPage() {
  // State Management
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [teachersPerPage] = useState(9);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [teacherEditStats, setTeacherEditStats] = useState({});
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false);
  const [totalTeachers, setTotalTeachers] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [attendanceStats, setAttendanceStats] = useState({});
  const [selectedSession, setSelectedSession] = useState("2023-24");

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // API Call Function
  const getTeacherInfo = async (page = 1, search = "", date = "") => {
    setIsLoading(true);
    try {
      const params = {
        school_id: userDetails?.school_id,
        session_id: userDetails?.session_id,
        page,
        limit: teachersPerPage,
        search,
        ...(date && { date }),
      };

      const response = await axiosReq.get(apiEndponts.getTeachers, params);

      if (response?.data) {
        const teachers = response.data.teachers?.map((teacher) => ({
          ...teacher,
          addressObj: teacher?.address,
          phoneNo: teacher?.phone || "",
          convertedSalary: teacher?.salary
            ? `Rs ${teacher?.salary?.toLocaleString("en-IN")}`
            : "",
          photo: Array.isArray(teacher?.photo)
            ? teacher?.photo[0]
            : teacher?.photo,
          address: `${teacher?.address?.name || ""}, ${
            teacher?.address?.district || ""
          }, ${teacher?.address?.state || ""}, ${
            teacher?.address?.pincode || ""
          }`,
          status: teacher?.attendanceStatus || "NOT_MARKED",
        }));

        setAttendanceStats(response.data.stats);
        setFilteredTeachers(teachers || []);
        setTotalPages(response?.pagination?.pages || 1);
        setTotalTeachers(response?.pagination?.total || 0);
      }
    } catch (err) {
      console.error("Error fetching teachers:", err);
      setFilteredTeachers([]);
      setTotalPages(1);
      setTotalTeachers(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    if (userDetails?.school_id) {
      getTeacherInfo(currentPage, searchTerm);
    }
  }, [currentPage, userDetails?.school_id]);

  // Event Handlers
  const handleSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      getTeacherInfo(1, value, selectedDate);
    }, 300),
    [selectedDate]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    setSelectedDate(value);
    setCurrentPage(1);
    getTeacherInfo(1, searchTerm, value);
  };

  const handleSessionChange = (e) => {
    setSelectedSession(e.target.value);
    setCurrentPage(1);
    getTeacherInfo(1, searchTerm, selectedDate);
  };

  const handleDeleteClick = (teacher) => {
    setSelectedTeacher(teacher);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosReq.delete(
        `${apiEndponts.deleteTeacher}/${selectedTeacher._id}`
      );
      getTeacherInfo(currentPage, searchTerm, selectedDate);
      setIsDeleteModalOpen(false);
      setSelectedTeacher(null);
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  const handleAddTeacher = () => {
    setIsAddStudentModalOpen(true);
  };

  const closeAddTeacherModal = () => {
    setIsAddStudentModalOpen(false);
    setTeacherEditStats({});
  };

  const handleEdit = (data) => {
    setTeacherEditStats(data);
    handleAddTeacher();
  };
  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Teacher stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-x-0">
                <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Total Teachers: {attendanceStats?.totalTeachers || 0}
                </div>
                <div className="text-[#0A8F47] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Present: {attendanceStats?.presentTeachers || 0}
                </div>
                <div className="text-[#B53D3E] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Absent: {attendanceStats?.absentTeachers || 0}
                </div>
                <div className="text-[#F3B807] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  On Leave: {attendanceStats?.onLeaveTeachers || 0}
                </div>
                <div className="text-orange-500 px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Half Day: {attendanceStats?.halfDayTeachers || 0}
                </div>
                <div className="text-gray-500 px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Not Marked: {attendanceStats?.notMarkedTeachers || 0}
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search teachers..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                />
                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                  value={selectedSession}
                  onChange={handleSessionChange}
                >
                  <option>2023-24</option>
                  <option>2024-25</option>
                </select>
                <button
                  className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs"
                  onClick={handleAddTeacher}
                >
                  + Add Teachers
                </button>
              </div>
            </div>

            {/* Teachers table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Photo</th>
                    <th className="px-3 py-2">Name</th>
                    <th className="px-3 py-2">Phone No.</th>
                    <th className="px-3 py-2">E-mail</th>
                    <th className="px-3 py-2">Salary</th>
                    <th className="px-3 py-2">Designation</th>
                    <th className="px-3 py-2">Address</th>
                    <th className="px-3 py-2">Attendance Status</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="9" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredTeachers.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="text-center py-4">
                        No teachers found
                      </td>
                    </tr>
                  ) : (
                    filteredTeachers.map((teacher, index) => (
                      <tr
                        key={teacher.id || index}
                        className="hover:bg-gray-50"
                      >
                        <td className="px-3 py-2">
                          <div className="w-8 h-8 rounded-full bg-gray-200 overflow-hidden">
                            <img
                              src={teacher.photo || "/default-avatar.png"}
                              alt={teacher.name}
                              className="w-full h-full object-cover"
                              onError={(e) => {
                                if (
                                  e.target.src !==
                                  window.location.origin + "/default-avatar.png"
                                ) {
                                  e.target.src = "/default-avatar.png";
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td className="px-3 py-2">{teacher.name}</td>
                        <td className="px-3 py-2">{teacher.phoneNo}</td>
                        <td className="px-3 py-2">
                          <a
                            href={`mailto:${teacher.email}`}
                            className="text-[#0662C6] hover:underline"
                          >
                            {teacher.email}
                          </a>
                        </td>
                        <td className="px-3 py-2">{teacher.convertedSalary}</td>
                        <td className="px-3 py-2">{teacher.designation}</td>
                        <td className="px-3 py-2">{teacher.address}</td>
                        <td className="px-3 py-2">
                          <span
                            className={`${
                              teacher.status === "PRESENT"
                                ? "text-[#0A8F47]"
                                : teacher.status === "ABSENT"
                                ? "text-[#B53D3E]"
                                : teacher.status === "ONLEAVE"
                                ? "text-[#F3B807]"
                                : teacher.status === "HALFDAY"
                                ? "text-orange-500"
                                : "text-gray-500"
                            }`}
                          >
                            {teacher.status.replace("_", " ")}
                          </span>
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button
                              className="text-[#0662C6] hover:text-[#113870]"
                              title="View Profile"
                            >
                              <FiUser size={14} />
                            </button>
                            <button
                              className="text-[#0662C6] hover:text-[#113870]"
                              title="Edit"
                              onClick={() => handleEdit(teacher)}
                            >
                              <FiEdit2 size={14} />
                            </button>
                            <button
                              className="text-[#B53D3E] hover:text-red-700"
                              title="Delete"
                              onClick={() => handleDeleteClick(teacher)}
                            >
                              <FiTrash2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            {!isLoading && filteredTeachers.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {(currentPage - 1) * teachersPerPage + 1} to{" "}
                  {Math.min(currentPage * teachersPerPage, totalTeachers)} of{" "}
                  {totalTeachers} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={() => {
                      setCurrentPage((prev) => Math.max(prev - 1, 1));
                      getTeacherInfo(currentPage - 1, searchTerm, selectedDate);
                    }}
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>

                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => {
                        setCurrentPage(index + 1);
                        getTeacherInfo(index + 1, searchTerm, selectedDate);
                      }}
                      className={`px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? "bg-[#0662C6] text-white"
                          : "bg-white text-[#0662C6] hover:bg-gray-50"
                      } border border-[#C2C2C2]`}
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    onClick={() => {
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages));
                      getTeacherInfo(currentPage + 1, searchTerm, selectedDate);
                    }}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Add/Edit Teacher Modal */}
            {isAddStudentModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">
                      {Object.keys(teacherEditStats)?.length > 0
                        ? "Edit Teacher"
                        : "Add New Teacher"}
                    </h2>
                    <button
                      onClick={closeAddTeacherModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddTeacherForm
                    isEdit={Object.keys(teacherEditStats)?.length > 0}
                    onSubmitSuccess={closeAddTeacherModal}
                    getTeacherInfo={getTeacherInfo}
                    teacherEditStats={teacherEditStats}
                  />
                </div>
              </div>
            )}

            {/* Delete Confirmation Modal */}
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onConfirm={handleDeleteConfirm}
              itemName={selectedTeacher ? "this teacher" : ""}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
