import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { auth } from "../utils/auth";
import LoadingSpinner from "./LoadingSpinner";

const ProtectedRoute = ({
  children,
  allowedRoles = [],
  requireAuth = true,
}) => {
  const location = useLocation();
  const userDetails = useSelector((state) => state.userDetails?.userDetails);
  const loading = useSelector((state) => state.userDetails?.loading);

  // Show loading state while checking authentication
  if (loading) {
    return <LoadingSpinner />;
  }

  // Check authentication
  if (requireAuth && !auth.isAuthenticated()) {
    // Store the attempted URL for redirect after login
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  // Check role-based access
  if (allowedRoles.length > 0) {
    const userRole = auth.getUserRole();
    if (!allowedRoles.includes(userRole)) {
      return (
        <Navigate
          to="/unauthorized"
          state={{
            requiredRoles: allowedRoles,
            currentRole: userRole,
          }}
          replace
        />
      );
    }
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  requireAuth: PropTypes.bool,
};

export default ProtectedRoute;
