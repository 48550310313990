"use client";
import { useState, useCallback, useEffect } from "react";
import { FiSearch, FiCalendar, FiEdit2, FiTrash2, FiX } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddClassForm from "./AddClassForm";
import AddTimeTableForm from "./AddTimeTable";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function ClassPage() {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("My School");
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const classesPerPage = 14;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [classesCount, setClassCount] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [originalClasses, setOriginalClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  const [isAddClassModalOpen, setIsAddClassModalOpen] = useState(false);
  const [isAddTimeTableModalOpen, setIsAddTimeTableModalOpen] = useState(false);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (userDetails?.school_id) {
      getClassInfo();
    }
  }, [userDetails?.school_id]);

  const getClassInfo = () => {
    setIsLoading(true);
    const url = `${apiEndponts.getClasses}${userDetails?.school_id}/${userDetails?.session_id}`;
    axiosReq
      .get(url)
      .then((resp) => {
        const classes = resp?.data?.classes?.map((classDetails) => {
          return {
            ...classDetails,
            name: classDetails?.name || "",
            incharge: classDetails?.incharge?.name || "",
            totalStudents: classDetails?.studentCount,
          };
        });
        setClassCount(resp?.data?.totalClasses);
        setFilteredClasses(classes || []);
        setOriginalClasses(classes || []);
      })
      .catch((err) => {
        console.error("Error fetching classes:", err);
        setFilteredClasses([]);
        setOriginalClasses([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = useCallback(
    debounce((value) => {
      if (!value.trim()) {
        // If search is empty, restore original data
        setFilteredClasses(originalClasses);
      } else {
        const lowercasedFilter = value.toLowerCase();
        const filtered = originalClasses.filter((item) => {
          return Object.keys(item).some((key) =>
            String(item[key]).toLowerCase().includes(lowercasedFilter)
          );
        });
        setFilteredClasses(filtered);
      }
      setCurrentPage(1);
    }, 300),
    [originalClasses]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };
  const handleDeleteClick = (classItem) => {
    setSelectedClass(classItem);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosReq.delete(`${apiEndponts.deleteClass}/${selectedClass.id}`);
      getClassInfo();
      setIsDeleteModalOpen(false);
      setSelectedClass(null);
    } catch (error) {
      console.error("Error deleting class:", error);
    }
  };

  const handleNavigation = (path, classId) => {
    navigate(path, { state: { classId } });
  };

  const handleAddClass = () => {
    setIsAddClassModalOpen(true);
  };

  const closeAddTeacherModal = () => {
    setIsAddClassModalOpen(false);
    getClassInfo();
  };

  const closeAddTimeTableModal = () => {
    setIsAddTimeTableModalOpen(false);
  };

  const handleEssentials = (item, classId) => {
    if (item === "Time Table") {
      setIsAddTimeTableModalOpen(true);
    } else if (item === "Homework") {
      navigate("/homework", { state: { classId } });
    } else if (item === "Assignment") {
      navigate("/assignments", { state: { classId } });
    } else if (item === "Subjects") {
      navigate("/subjects", { state: { classId } });
    }
  };

  const renderPagination = () => {
    if (!filteredClasses.length || isLoading) return null;

    const pageNumbers = [];
    const totalPages = Math.ceil(filteredClasses.length / classesPerPage);

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    const indexOfLastClass = currentPage * classesPerPage;
    const indexOfFirstClass = indexOfLastClass - classesPerPage;

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {indexOfFirstClass + 1} to{" "}
          {Math.min(indexOfLastClass, filteredClasses.length)} of{" "}
          {filteredClasses.length} entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => setCurrentPage(number)}
              className={`px-2 py-1 text-xs rounded ${
                currentPage === number
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === totalPages
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  // Calculate current page data
  const indexOfLastClass = currentPage * classesPerPage;
  const indexOfFirstClass = indexOfLastClass - classesPerPage;
  const currentClasses = filteredClasses?.slice(
    indexOfFirstClass,
    indexOfLastClass
  );

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Classes: {classesCount}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <div className="relative">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yy"
                    placeholderText="Select date"
                    className="border border-[#C2C2C2] rounded-md pl-2 pr-6 py-1 w-24 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiCalendar
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#888888] pointer-events-none"
                    size={12}
                  />
                </div>
                <button
                  onClick={handleAddClass}
                  className="bg-[#0662C6] text-[#FFFFFF] px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs"
                >
                  + Add Class
                </button>
              </div>
            </div>

            {/* Classes table */}
            <div className="bg-[#FFFFFF] shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Class Name</th>
                    <th className="px-3 py-2">Incharge</th>
                    <th className="px-3 py-2">Total Students</th>
                    <th className="px-3 py-2">Attendence</th>
                    <th className="px-3 py-2">Lectures</th>
                    <th className="px-3 py-2">Results</th>
                    <th className="px-3 py-2">Essentials</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs text-left">
                  {isLoading ? (
                    <tr>
                      <td colSpan="8" className="px-3 py-4 text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : currentClasses.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="px-3 py-4 text-center">
                        No classes found
                      </td>
                    </tr>
                  ) : (
                    currentClasses.map((cls, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-2 font-medium text-[#0662C6]">
                          {cls.name}
                        </td>
                        <td className="px-3 py-2 text-[#1E1E1E]">
                          {cls.incharge}
                        </td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {cls.totalStudents}
                        </td>
                        <td className="px-3 py-2">
                          <button
                            onClick={() =>
                              handleNavigation("/studentsattendance", cls.id)
                            }
                            className="text-[#0662C6] hover:underline"
                          >
                            View Attendance
                          </button>
                        </td>
                        <td className="px-3 py-2">
                          <button
                            onClick={() =>
                              handleNavigation("/lectures", cls.id)
                            }
                            className="text-[#0662C6] hover:underline"
                          >
                            View Lectures
                          </button>
                        </td>
                        <td className="px-3 py-2">
                          <button
                            onClick={() => handleNavigation("/results", cls.id)}
                            className="text-[#0662C6] hover:underline"
                          >
                            View Results
                          </button>
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-1">
                            {["Homework", "Time Table", "Assignment"].map(
                              (item, i) => (
                                <button
                                  key={i}
                                  onClick={() => handleEssentials(item, cls.id)}
                                  className="px-1.5 py-0.5 border border-[#0662C6] rounded-md text-[12px] text-[#0662C6] hover:bg-[#0662C6] hover:text-[#FFFFFF] transition-colors duration-200"
                                >
                                  {item}
                                </button>
                              )
                            )}
                          </div>
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button className="text-[#29A7E4] hover:text-[#113870]">
                              <FiEdit2 size={14} />
                            </button>
                            <button
                              className="text-[#B53D3E] hover:text-[#113870]"
                              onClick={() => handleDeleteClick(cls)}
                            >
                              <FiTrash2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {renderPagination()}
            {/* Add Class Modal */}
            {isAddClassModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Add New Class</h2>
                    <button
                      onClick={closeAddTeacherModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddClassForm
                    onSubmitSuccess={closeAddTeacherModal}
                    getClassInfo={getClassInfo}
                  />
                </div>
              </div>
            )}

            {/* Add Time Table Modal */}
            {isAddTimeTableModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Add Timetable</h2>
                    <button
                      onClick={closeAddTimeTableModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddTimeTableForm
                    onSubmitSuccess={closeAddTimeTableModal}
                    getClassInfo={getClassInfo}
                  />
                </div>
              </div>
            )}

            {/* Delete Confirmation Modal */}
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onConfirm={handleDeleteConfirm}
              itemName={selectedClass ? "this class" : ""}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
