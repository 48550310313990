import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { stateAndDistrict } from "../../constants/AppConstant";
import { format } from "date-fns";
import { showToast } from "../../utils/toast";

function AddUserForm({ onSubmitSuccess, editMode, getTeacherInfo }) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [photoPreview, setPhotoPreview] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  const onSubmit = (data) => {
    const selectedAccess = watch("access"); // Access all selected checkboxe
    const payload = {
      school_id: userDetails?.school_id || "",
      session_id: userDetails?.session_id || "",
      name: data?.name || "",
      phone: data?.phone || "",
      password: data?.password || "",
      access: {
        ...selectedAccess,
      },
    };

    axiosReq
      .post(apiEndponts?.addUser, payload)
      .then((resp) => {
        showToast.success("New User created successfully");
        onSubmitSuccess();
        getTeacherInfo();
      })
      .catch((err) => console.log(err));
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="name"
          >
            Name
          </label>
          <input
            {...register("name", { required: "Name is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
            type="text"
            placeholder="Name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
            {...register("phone", { required: "Phone is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="phone"
            type="text"
            maxLength={10}
            placeholder="Phone"
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              setValue("phone", value);
            }}
          />
          {errors.phone && (
            <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="password"
          >
            Password
          </label>
          <input
            {...register("password", { required: "Password is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="password"
            type="text"
            // maxLength={10}
            placeholder="Password"
          />
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">
              {errors.password.message}
            </p>
          )}
        </div>
        <div className="col-span-2">
          <label className="block text-gray-700 font-semibold mb-2">
            Access
          </label>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 text-left">
            {[
              { label: "My School", value: "mySchool" },
              { label: "HR", value: "HR" },
              { label: "Fee", value: "fee" },
              { label: "Bus", value: "bus" },
              { label: "Dashboard", value: "dashboard" },
              { label: "User", value: "user" },
              { label: "Complain", value: "complain" },
              { label: "School", value: "school" },
              { label: "news", value: "news" },
            ].map((item) => (
              <div className="flex items-center space-x-2 ">
                <input
                  {...register(`access.${item.value}`)}
                  className="shadow-sm border rounded-lg py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  id={item?.label}
                  type="checkbox"
                  // maxLength={10}
                  placeholder="Password"
                  // value={item?.value}
                />
                <label htmlFor={item?.label}>{item?.label}</label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add User
        </button>
      </div>
    </form>
  );
}

export default AddUserForm;
