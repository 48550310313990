import React, { useState, useCallback } from "react";
import { FiSearch, FiCalendar } from "react-icons/fi";
import DatePicker from "react-datepicker";
import Pagination from "../../components/Pagination";
import "react-datepicker/dist/react-datepicker.css";

// Sample data
const routesData = Array(10).fill({
  routeNo: "Route 12",
  routeId: "HR 3456890",
  routeType: "Pick up & Drop",
  driver: "Rahul Singh",
  helper: "Shubham Singh",
  startTime: "08:00 AM",
  endTime: "10:00 AM",
  polyline: "View on map",
  stoppagePoint: "5",
  activeDays: "5",
});

function RoutesPage() {
  const [activeNav, setActiveNav] = useState("My School");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const routesPerPage = 10;
  const [filteredRoutes, setFilteredRoutes] = useState(routesData);
  const [selectedYear, setSelectedYear] = useState("2024-25");

  // Custom input component for the DatePicker
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="relative">
      <input
        value={value}
        onClick={onClick}
        readOnly
        ref={ref}
        className="border border-[#C2C2C2] rounded-md pl-2 pr-8 py-1 bg-[#F4F4F4] text-xs cursor-pointer w-32"
      />
      <FiCalendar
        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#888888] pointer-events-none"
        size={12}
      />
    </div>
  ));

  const handleSearch = useCallback(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filtered = routesData.filter((route) => {
      return Object.keys(route).some((key) =>
        route[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredRoutes(filtered);
    setCurrentPage(1);
  }, [searchTerm]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Route stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Route: 23
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>

                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  <option value="2024-25">2024-25</option>
                  <option value="2023-24">2023-24</option>
                  <option value="2022-23">2022-23</option>
                </select>

                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  customInput={<CustomDateInput />}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="text-xs"
                />

                <button className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs">
                  + Add Route
                </button>
              </div>
            </div>

            {/* Routes table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Route No.</th>
                    <th className="px-3 py-2">Route Type</th>
                    <th className="px-3 py-2">Driver</th>
                    <th className="px-3 py-2">Helper</th>
                    <th className="px-3 py-2">Start Time</th>
                    <th className="px-3 py-2">End Time</th>
                    <th className="px-3 py-2">Polyline</th>
                    <th className="px-3 py-2">Stoppage Point</th>
                    <th className="px-3 py-2">Active Days</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {filteredRoutes.map((route, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">
                        <div className="text-[#0662C6]">{route.routeNo}</div>
                        <div className="text-[#888888]">{route.routeId}</div>
                      </td>
                      <td className="px-3 py-2">{route.routeType}</td>
                      <td className="px-3 py-2">{route.driver}</td>
                      <td className="px-3 py-2">{route.helper}</td>
                      <td className="px-3 py-2">{route.startTime}</td>
                      <td className="px-3 py-2">{route.endTime}</td>
                      <td className="px-3 py-2">
                        <a href="#" className="text-[#0662C6]">
                          {route.polyline}
                        </a>
                      </td>
                      <td className="px-3 py-2">
                        <span>{route.stoppagePoint}</span>
                        <a href="#" className="text-[#0662C6] ml-1">
                          View Info
                        </a>
                      </td>
                      <td className="px-3 py-2">
                        <span>{route.activeDays}</span>
                        <a href="#" className="text-[#0662C6] ml-1">
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredRoutes.length / routesPerPage)}
              onNextPage={() => setCurrentPage((prev) => prev + 1)}
              isLastPage={currentPage * routesPerPage >= filteredRoutes.length}
              isFirstPage={currentPage === 1}
              onPrevPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default RoutesPage;
