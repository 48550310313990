"use client";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { FiEye, FiEdit2, FiTrash2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { format, parseISO } from "date-fns";
import { MONTHS } from "../../constants/AppConstant";

// Action Types
const FILTER_ACTIONS = {
  SET_YEAR: "SET_YEAR",
  SET_MONTH: "SET_MONTH",
  SET_PAGE: "SET_PAGE",
  RESET_FILTERS: "RESET_FILTERS",
};

const DATA_ACTIONS = {
  SET_DATA: "SET_DATA",
  SET_LOADING: "SET_LOADING",
};

// Initial States
const initialFilterState = {
  selectedYear: new Date().getFullYear(),
  selectedMonth: "all",
  currentPage: 1,
};

const initialDataState = {
  holidays: [],
  isLoading: false,
  totalPages: 1,
  totalHolidays: 0,
};

// Reducers
const filterReducer = (state, action) => {
  switch (action.type) {
    case FILTER_ACTIONS.SET_YEAR:
      return { ...state, selectedYear: action.payload, currentPage: 1 };
    case FILTER_ACTIONS.SET_MONTH:
      return { ...state, selectedMonth: action.payload, currentPage: 1 };
    case FILTER_ACTIONS.SET_PAGE:
      return { ...state, currentPage: action.payload };
    case FILTER_ACTIONS.RESET_FILTERS:
      return initialFilterState;
    default:
      return state;
  }
};

const dataReducer = (state, action) => {
  switch (action.type) {
    case DATA_ACTIONS.SET_DATA:
      return {
        ...state,
        holidays: action.payload.holidays,
        totalPages: action.payload.totalPages,
        totalHolidays: action.payload.totalHolidays,
      };
    case DATA_ACTIONS.SET_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

// Utility function
const formatHolidayData = (data) => {
  return data.map((item) => ({
    ...item,
    formattedDate: format(parseISO(item.date), "dd/MM/yyyy"),
    studentInfo: item?.allClass
      ? "For all Class"
      : getStudentsNameAndCount(item?.students),
    subjectName: item?.periodInfo?.subject || "",
    periodNumber: item?.periodInfo?.periodNumber || "",
  }));
};

const getStudentsNameAndCount = (students) => {
  const firstStdName = students?.[0]?.name;
  const studentCount = students?.length;
  return studentCount > 1
    ? `${firstStdName} + ${studentCount - 1} more`
    : firstStdName;
};

export default function HolidayPage() {
  const [filterState, filterDispatch] = useReducer(
    filterReducer,
    initialFilterState
  );
  const [dataState, dataDispatch] = useReducer(dataReducer, initialDataState);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // API parameters
  const getApiParams = useMemo(
    () => ({
      school_id: userDetails?.school_id || "",
      model: "homework",
      page: filterState.currentPage,
      year: filterState.selectedYear,
      ...(filterState.selectedMonth !== "all" && {
        month: filterState.selectedMonth,
      }),
    }),
    [filterState, userDetails?.school_id]
  );

  // Data fetching
  const fetchHolidays = useCallback(async () => {
    dataDispatch({ type: DATA_ACTIONS.SET_LOADING, payload: true });
    try {
      const response = await axiosReq.get(
        apiEndponts.getCircular,
        getApiParams
      );
      const formattedHolidays = formatHolidayData(response?.data || []);

      dataDispatch({
        type: DATA_ACTIONS.SET_DATA,
        payload: {
          holidays: formattedHolidays,
          totalPages: response?.pagination?.pages || 1,
          totalHolidays: response?.pagination?.total || 0,
        },
      });
    } catch (error) {
      console.error("Error fetching holidays:", error);
      dataDispatch({
        type: DATA_ACTIONS.SET_DATA,
        payload: { holidays: [], totalPages: 1, totalHolidays: 0 },
      });
    } finally {
      dataDispatch({ type: DATA_ACTIONS.SET_LOADING, payload: false });
    }
  }, [getApiParams]);

  useEffect(() => {
    if (userDetails?.school_id) {
      fetchHolidays();
    }
  }, [userDetails?.school_id, fetchHolidays]);

  // Event handlers
  const handleFilterChange = useCallback((type, value) => {
    filterDispatch({ type, payload: value });
  }, []);

  // Memoized render components
  const renderStats = useMemo(
    () => (
      <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
        Total Holidays: {dataState.totalHolidays}
      </div>
    ),
    [dataState.totalHolidays]
  );

  const renderFilters = useMemo(
    () => (
      <div className="flex items-center space-x-2">
        <select
          value={filterState.selectedYear}
          onChange={(e) =>
            handleFilterChange(FILTER_ACTIONS.SET_YEAR, e.target.value)
          }
          className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
        >
          <option value={2024}>2024</option>
          <option value={2023}>2023</option>
        </select>
        <select
          value={filterState.selectedMonth}
          onChange={(e) =>
            handleFilterChange(FILTER_ACTIONS.SET_MONTH, e.target.value)
          }
          className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
        >
          <option value="all">All Months</option>
          {MONTHS.map((month) => (
            <option key={month.id} value={month.id}>
              {month.name}
            </option>
          ))}
        </select>
      </div>
    ),
    [filterState.selectedYear, filterState.selectedMonth, handleFilterChange]
  );

  const renderTable = useMemo(
    () => (
      <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
        <table className="w-full">
          <thead>
            <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
              <th className="px-3 py-2">Date</th>
              <th className="px-3 py-2">Subject</th>
              <th className="px-3 py-2">Class</th>
              <th className="px-3 py-2">Period</th>
              <th className="px-3 py-2">Students</th>
              <th className="px-3 py-2">Description</th>
              <th className="px-3 py-2">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-[#C2C2C2] text-xs">
            {dataState.isLoading ? (
              <tr>
                <td colSpan="7" className="px-3 py-4 text-center">
                  Loading...
                </td>
              </tr>
            ) : dataState.holidays.length === 0 ? (
              <tr>
                <td colSpan="7" className="px-3 py-4 text-center">
                  No holidays found
                </td>
              </tr>
            ) : (
              dataState.holidays.map((holiday, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-3 py-2">{holiday.formattedDate}</td>
                  <td className="px-3 py-2">{holiday.subjectName}</td>
                  <td className="px-3 py-2">{holiday.className}</td>
                  <td className="px-3 py-2">{holiday.periodNumber}</td>
                  <td className="px-3 py-2 text-[#0662C6]">
                    {holiday.studentInfo}
                  </td>
                  <td className="px-3 py-2">{holiday.homework}</td>
                  <td className="px-3 py-2">
                    <div className="flex space-x-2">
                      {holiday.photo?.length > 0 && (
                        <button
                          className="text-[#0662C6] hover:text-[#0550a0]"
                          onClick={() =>
                            holiday.photo.forEach((url) => window.open(url))
                          }
                        >
                          <FiEye size={14} />
                        </button>
                      )}
                      <button className="text-[#0662C6] hover:text-[#0550a0]">
                        <FiEdit2 size={14} />
                      </button>
                      <button className="text-[#B53D3E] hover:text-[#922f30]">
                        <FiTrash2 size={14} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    ),
    [dataState.isLoading, dataState.holidays]
  );

  const renderPagination = useMemo(() => {
    if (!dataState.holidays.length || dataState.isLoading) return null;

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {(filterState.currentPage - 1) * 10 + 1} to{" "}
          {Math.min(filterState.currentPage * 10, dataState.holidays.length)} of{" "}
          {dataState.totalHolidays} entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={() =>
              handleFilterChange(
                FILTER_ACTIONS.SET_PAGE,
                filterState.currentPage - 1
              )
            }
            disabled={filterState.currentPage === 1}
            className={`px-2 py-1 text-xs rounded ${
              filterState.currentPage === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {[...Array(dataState.totalPages)].map((_, i) => (
            <button
              key={i + 1}
              onClick={() => handleFilterChange(FILTER_ACTIONS.SET_PAGE, i + 1)}
              className={`px-2 py-1 text-xs rounded ${
                filterState.currentPage === i + 1
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() =>
              handleFilterChange(
                FILTER_ACTIONS.SET_PAGE,
                filterState.currentPage + 1
              )
            }
            disabled={filterState.currentPage === dataState.totalPages}
            className={`px-2 py-1 text-xs rounded ${
              filterState.currentPage === dataState.totalPages
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  }, [
    filterState.currentPage,
    dataState.totalPages,
    dataState.totalHolidays,
    dataState.holidays.length,
    dataState.isLoading,
    handleFilterChange,
  ]);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            <div className="flex justify-between items-center mb-3">
              {renderStats}
              {renderFilters}
            </div>
            {renderTable}
            {renderPagination}
          </div>
        </main>
      </div>
    </div>
  );
}
