"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiEdit2, FiTrash2, FiPlus, FiX } from "react-icons/fi";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import AddHoliday from "./AddHoliday";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { showToast } from "../../utils/toast";
import { MONTHS } from "../../constants/AppConstant";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function HolidayPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [originalHolidays, setOriginalHolidays] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [isAddHolidayModalOpen, setIsAddHolidayModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10,
  });

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Add "All" option to months
  const allMonths = [{ id: "all", name: "All Months" }, ...MONTHS];
  useEffect(() => {
    if (userDetails?.school_id) {
      getHolidays();
    }
  }, [userDetails?.school_id, selectedMonth]);

  const getHolidays = () => {
    setIsLoading(true);
    const url = `${apiEndponts.getHolidays}${userDetails?.school_id}/${userDetails?.session_id}`;

    axiosReq
      .get(url)
      .then((resp) => {
        // Map holidays according to the response structure
        const holidays = resp.holidays.map((holiday) => ({
          month: holiday.month || "",
          date: format(parseISO(holiday.date), "dd/MM/yyyy"),
          occasion: holiday.name || "",
        }));

        // Filter by month if selected
        let filteredData = holidays;
        if (selectedMonth !== "all") {
          filteredData = holidays.filter(
            (holiday) =>
              holiday.month.toLowerCase() === selectedMonth.toLowerCase()
          );
        }

        setFilteredHolidays(filteredData);
        setOriginalHolidays(holidays);
        setPaginationInfo(resp.pagination);
      })
      .catch((err) => {
        console.error("Error fetching holidays:", err);
        showToast.error("Failed to fetch holidays");
        setFilteredHolidays([]);
        setOriginalHolidays([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = useCallback(
    debounce((value) => {
      if (!value.trim()) {
        setFilteredHolidays(originalHolidays);
      } else {
        const lowercasedFilter = value.toLowerCase();
        const filteredResults = originalHolidays.filter((holiday) => {
          return Object.values(holiday).some((val) =>
            String(val).toLowerCase().includes(lowercasedFilter)
          );
        });
        setFilteredHolidays(filteredResults);
      }
      setCurrentPage(1);
    }, 300),
    [originalHolidays]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleMonthChange = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      getHolidays(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < paginationInfo.totalPages) {
      setCurrentPage((prev) => prev + 1);
      getHolidays(currentPage + 1);
    }
  };

  const handleDeleteClick = (holiday) => {
    setSelectedHoliday(holiday);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    const url = `${apiEndponts.deleteHolidays}${selectedHoliday.id}`;
    axiosReq
      .delete(url)
      .then((resp) => {
        getHolidays();
        showToast.success("Holiday deleted successfully");
        setIsDeleteModalOpen(false);
        setSelectedHoliday(null);
      })
      .catch((err) => {
        showToast.error("Something went wrong");
        console.error(err);
      });
  };

  const handleAddHoliday = () => {
    setIsAddHolidayModalOpen(true);
  };

  const closeAddHolidayModal = () => {
    setIsAddHolidayModalOpen(false);
    getHolidays();
  };
  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Holiday stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Holidays: {paginationInfo.totalItems}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                >
                  {allMonths.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <button
                  className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs flex items-center gap-1"
                  onClick={handleAddHoliday}
                >
                  <FiPlus size={14} />
                  Add Holiday
                </button>
              </div>
            </div>

            {/* Holidays table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Month</th>
                    <th className="px-3 py-2">Date</th>
                    <th className="px-3 py-2">Occasion</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="4" className="px-3 py-4 text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredHolidays.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="px-3 py-4 text-center">
                        No holidays found
                      </td>
                    </tr>
                  ) : (
                    filteredHolidays.map((holiday, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-2">{holiday.month}</td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {holiday.date}
                        </td>
                        <td className="px-3 py-2">{holiday.occasion}</td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button className="text-[#0662C6] hover:text-[#113870]">
                              <FiEdit2 size={14} />
                            </button>
                            <button
                              className="text-[#B53D3E] hover:text-[#113870]"
                              onClick={() => handleDeleteClick(holiday)}
                            >
                              <FiTrash2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="flex justify-between items-center mt-4">
              <div className="text-sm text-[#888888]">
                Showing{" "}
                {(paginationInfo.currentPage - 1) *
                  paginationInfo.itemsPerPage +
                  1}{" "}
                to{" "}
                {Math.min(
                  paginationInfo.currentPage * paginationInfo.itemsPerPage,
                  paginationInfo.totalItems
                )}{" "}
                of {paginationInfo.totalItems} entries
              </div>
              <div className="flex items-center gap-1">
                <button
                  onClick={handlePrevPage}
                  disabled={paginationInfo.currentPage === 1}
                  className={`px-2 py-1 text-xs rounded ${
                    paginationInfo.currentPage === 1
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-white text-[#113870] hover:bg-gray-50"
                  } border border-[#C2C2C2]`}
                >
                  Previous
                </button>
                <span className="px-2 py-1 text-xs">
                  Page {paginationInfo.currentPage} of{" "}
                  {paginationInfo.totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={
                    paginationInfo.currentPage === paginationInfo.totalPages
                  }
                  className={`px-2 py-1 text-xs rounded ${
                    paginationInfo.currentPage === paginationInfo.totalPages
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-white text-[#113870] hover:bg-gray-50"
                  } border border-[#C2C2C2]`}
                >
                  Next
                </button>
              </div>
            </div>

            {/* Add Holiday Modal */}
            {isAddHolidayModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Add Holiday</h2>
                    <button
                      onClick={closeAddHolidayModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddHoliday
                    onSubmitSuccess={closeAddHolidayModal}
                    getHolidays={getHolidays}
                  />
                </div>
              </div>
            )}

            {/* Delete Confirmation Modal */}
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onConfirm={handleDeleteConfirm}
              itemName="this holiday"
            />
          </div>
        </main>
      </div>
    </div>
  );
}
