import { TestimonialCard } from "../ui/TestimonialCard";
import { SectionTitle } from "../ui/SectionTitle";

const testimonials = [
  {
    name: "Sarah Johnson",
    role: "School Principal",
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80",
    content:
      "EduManage has revolutionized how we run our school. The automation and analytics have saved us countless hours of administrative work.",
  },
  {
    name: "Michael Chen",
    role: "IT Administrator",
    image:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80",
    content:
      "The platform is incredibly intuitive and secure. Technical support is responsive and always helpful.",
  },
  {
    name: "Emily Rodriguez",
    role: "Teacher",
    image:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80",
    content:
      "Managing assignments and tracking student progress has never been easier. It has transformed my teaching experience.",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <SectionTitle
          title="Trusted by Educators Worldwide"
          subtitle="See what education professionals are saying about EduManage"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
