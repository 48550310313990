import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";
import DatePicker from "react-datepicker";
import { MONTHS } from "../../constants/AppConstant";
import { format } from "date-fns";

function AddHoliday({ onSubmitSuccess, editMode, getHolidays }) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  const onSubmit = (data) => {
    // onSubmitSuccess();
    const payload = {
      school_id: userDetails?.school_id || "",
      session_id: userDetails?.session_id || "",
      months: {
        [data?.month]: [
          { date: format(data?.date, "yyyy-MM-dd"), name: data?.name },
        ],
      },
    };
    const payloadData = new FormData();
    payloadData.append("Data", JSON.stringify(payload));
    axiosReq
      .post(apiEndponts?.addHoliday, payloadData)
      .then((resp) => {
        showToast.success("Holiday created successfully");
        onSubmitSuccess();
        getHolidays();
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="month"
          >
            Month
          </label>
          <select
            {...register("month", { required: "Incharge is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="month"
          >
            <option value="">Select Month</option>
            {/* Add class options here */}
            {MONTHS?.map((month) => (
              <option value={month?.name}>{month?.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="date"
          >
            Date
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DatePicker
                placeholderText="Select date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                wrapperClassName="w-full"
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          />
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="name"
          >
            Name
          </label>
          <input
            {...register("name", { required: "Name is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
            type="text"
            placeholder="Name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-2 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add Holiday
        </button>
      </div>
    </form>
  );
}

export default AddHoliday;
