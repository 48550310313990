export const auth = {
  isAuthenticated: () => {
    return !!localStorage.getItem("token");
  },

  getUserRole: () => {
    return localStorage.getItem("role");
  },

  getUserData: () => {
    const userData = localStorage.getItem("userData");
    return userData ? JSON.parse(userData) : null;
  },

  logout: () => {
    const itemsToClear = [
      "token",
      "userId",
      "contactPersonName",
      "role",
      "userName",
      "schoolId",
      "sessionId",
      "schoolName",
      "userData",
    ];

    itemsToClear.forEach((item) => localStorage.removeItem(item));
  },
};
