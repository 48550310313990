import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "../utils/toast";
import {
  FaUserCircle,
  FaSignOutAlt,
  FaUser,
  FaCog,
  FaChevronDown,
} from "react-icons/fa";
import logoImage from "../Assets/logoImage.png";
const Header = ({ activeNav, setActiveNav }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const navItems = [
    "My School",
    "Staff",
    "Dashboard",
    "Buses",
    "Fee",
    "Users",
    "Complaints",
    // "Payroll",
    "Expenses",
    "SchoolEG",
    // "SuperAdmin",
  ];
  const handleLogout = async () => {
    try {
      setShowDropdown(false);
      const userId = localStorage.getItem("userId");
      const role = localStorage.getItem("role");
      const response = await fetch("https://schooleg.com/Schooleg/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          role: role,
        }),
      });
      if (!response.ok) throw new Error("Logout failed");
      const data = await response.json();
      if (data.message === "Logout successful") {
        localStorage.clear();
        navigate("/", { replace: true });
        setTimeout(() => {
          showToast.success("Logged out successfully");
        }, 100);
      } else {
        showToast.error("Logout failed");
      }
    } catch (error) {
      console.error("Logout error:", error);
      showToast.error("Error during logout");
    }
  };
  return (
    <header className="bg-[#FFFFFF] flex-shrink-0 border-b border-[#C2C2C2] drop-shadow-md relative z-[100]">
      <div className="max-w-full mx-auto px-3 py-2 flex justify-between items-center">
        {/* School Logo and Name */}
        <div className="flex items-center space-x-3">
          <div className="w-12 h-12 rounded-full overflow-hidden bg-white flex items-center justify-center border border-[#C2C2C2]">
            <img
              src={logoImage}
              alt="Logo"
              className="w-12 h-12 object-contain"
            />
          </div>
        </div>
        {/* Navigation Menu */}
        <nav className="flex-1 flex justify-center flex-space-x-2">
          {navItems.map((item) => (
            <a
              key={item}
              href="#"
              onClick={() => setActiveNav(item)}
              className={`text-lg font-sans px-3 py-2 transition-colors duration-200 ${
                activeNav === item
                  ? "text-[#29A7E4] border-b-2 border-[#29A7E4]"
                  : "text-gray-700 hover:text-[#29A7E4]"
              }`}
            >
              {item}
            </a>
          ))}
        </nav>
        {/* Right Section - User Profile */}
        <div className="flex items-center">
          <div
            className="relative"
            ref={dropdownRef}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <button
              onClick={() => setShowDropdown(!showDropdown)}
              className="flex items-center space-x-2 focus:outline-none"
            >
              <div className="w-8 h-8 bg-[#2C3E50] rounded-full flex items-center justify-center text-[#FFFFFF] text-xs font-medium hover:bg-[#34495E] transition-colors duration-200">
                <FaUserCircle size={30} />
              </div>
              <FaChevronDown
                className={`text-gray-500 transition-transform duration-200 ${
                  showDropdown ? "transform rotate-180" : ""
                }`}
                size={12}
              />
            </button>
            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 border border-gray-200">
                <div className="px-4 py-2 border-b border-gray-200">
                  <p className="text-sm font-medium text-gray-700">
                    {userDetails?.schoolName || "User Name"}
                  </p>
                </div>
                <button
                  onClick={() => {
                    setShowDropdown(false);
                    navigate("/profile");
                  }}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  <FaUser className="mr-2" size={14} />
                  Profile
                </button>
                <button
                  onClick={() => {
                    setShowDropdown(false);
                    navigate("/schoolprofile");
                  }}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  <FaCog className="mr-2" size={14} />
                  Settings
                </button>
                <hr className="my-1" />
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                >
                  <FaSignOutAlt className="mr-2" size={14} />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
