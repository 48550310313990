"use client";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";
import "react-toastify/dist/ReactToastify.css";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";

// Constants
const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const ACADEMIC_YEAR = "2023-24";

// Modal Component
const Modal = ({ children, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg max-w-2xl w-full mx-4">
      <div className="relative">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        {children}
      </div>
    </div>
  </div>
);

export default function TimeTablePage() {
  // State Management
  const [timetableState, setTimetableState] = useState({
    selectedClass: "",
    timetableData: [],
    timetableId: null,
    classOptions: [],
  });

  const [modalState, setModalState] = useState({
    isEditModalOpen: false,
    isAddModalOpen: false,
    selectedPeriod: null,
  });

  const [teachers, setTeachers] = useState([]);
  const [isLoadingTeachers, setIsLoadingTeachers] = useState(false);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Data Conversion Utility
  const convertData = useCallback((inputData) => {
    const result = [];
    if (inputData?.length > 0) {
      setTimetableState((prev) => ({
        ...prev,
        timetableId: inputData[0].timetable_id,
      }));
    }

    inputData.forEach((dayData) => {
      dayData.periods.forEach((period) => {
        let periodObj = result.find(
          (p) => p.periodNumber === period.periodNumber
        );

        if (!periodObj) {
          periodObj = {
            periodNumber: period.periodNumber,
            ...WEEK_DAYS.reduce((acc, day) => ({ ...acc, [day]: {} }), {}),
          };
          result.push(periodObj);
        }

        periodObj[dayData.day] = {
          period_id: period.period_id,
          subject: period.subject,
          teacher_id: period.teacher_id,
          teacherName: period.teacherName,
          startTime: period.startTime,
          endTime: period.endTime,
          meet_Info: period.meet_Info,
          periodDays: period.periodDays,
        };
      });
    });

    return result.sort((a, b) => a.periodNumber - b.periodNumber);
  }, []);

  // API Calls
  const fetchTimeTableData = useCallback(async () => {
    try {
      const response = await axiosReq.get(
        `${apiEndponts.getTimeTable}${timetableState.selectedClass}`
      );
      const convertedData = convertData(response);
      setTimetableState((prev) => ({ ...prev, timetableData: convertedData }));
    } catch (error) {
      showToast.error("Failed to fetch timetable data");
      setTimetableState((prev) => ({ ...prev, timetableData: [] }));
    }
  }, [timetableState.selectedClass, convertData]);

  const fetchTeachers = useCallback(async () => {
    setIsLoadingTeachers(true);
    try {
      const response = await axiosReq.get(
        `${apiEndponts.getTeacherList}${userDetails?.school_id}/${userDetails?.session_id}`
      );
      setTeachers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    } finally {
      setIsLoadingTeachers(false);
    }
  }, [userDetails]);

  const fetchClassNames = useCallback(async () => {
    try {
      const query = JSON.stringify({
        school_id: userDetails?.school_id,
        session_id: userDetails?.session_id,
      });
      const response = await axiosReq.get(apiEndponts.getClassOfSchool, {
        query,
      });
      const classOptions = response?.records?.map((item) => ({
        name: item?.name,
        id: item?._id,
      }));
      setTimetableState((prev) => ({
        ...prev,
        classOptions,
        selectedClass: classOptions[0]?.id || "",
      }));
    } catch (error) {
      showToast.error("Failed to fetch class list");
      console.error(error);
    }
  }, [userDetails]);

  // Effects
  useEffect(() => {
    if (timetableState.selectedClass) {
      fetchTimeTableData();
    }
  }, [timetableState.selectedClass, fetchTimeTableData]);

  useEffect(() => {
    if (userDetails) {
      fetchTeachers();
      fetchClassNames();
    }
  }, [userDetails, fetchTeachers, fetchClassNames]);
  // EditPeriodForm Component
  const EditPeriodForm = ({ period, onClose }) => {
    const [formData, setFormData] = useState({
      teacher_id: period.teacher_id || "",
      subject: period.subject || "",
      startTime: period.startTime || "",
      endTime: period.endTime || "",
      meet_Info: {
        Link: period.meet_Info?.Link || "",
        Note: period.meet_Info?.Note || "",
      },
      periodDays: period.periodDays || [],
    });
    const [isUpdating, setIsUpdating] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsUpdating(true);

      try {
        if (!timetableState.timetableId || !period.period_id) {
          throw new Error("Missing timetable or period ID");
        }

        const response = await axiosReq.patch(
          `${apiEndponts.updatePeriod}${timetableState.timetableId}/period/${period.period_id}`,
          {
            teacher_id: formData.teacher_id,
            subject: formData.subject,
            startTime: formData.startTime,
            endTime: formData.endTime,
            meet_Info: formData.meet_Info,
            periodDays: formData.periodDays,
          }
        );

        if (response.status === "success") {
          showToast.success("Period updated successfully");
          fetchTimeTableData();
          onClose();
        }
      } catch (error) {
        console.error("Error updating period:", error);
        showToast.error(
          error.response?.data?.message || "Failed to update period"
        );
      } finally {
        setIsUpdating(false);
      }
    };

    return (
      <form onSubmit={handleSubmit} className="space-y-4 p-6">
        <div>
          <label className="block mb-1">Teacher</label>
          <select
            className="border rounded-md w-full p-2"
            value={formData.teacher_id}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, teacher_id: e.target.value }))
            }
            required
            disabled={isLoadingTeachers}
          >
            <option value="">Select Teacher</option>
            {teachers.map((teacher) => (
              <option key={teacher._id} value={teacher._id}>
                {teacher.name}
              </option>
            ))}
          </select>
          {isLoadingTeachers && (
            <span className="text-xs text-gray-500">Loading teachers...</span>
          )}
        </div>

        <div>
          <label className="block mb-1">Subject</label>
          <input
            type="text"
            className="border rounded-md w-full p-2"
            value={formData.subject}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, subject: e.target.value }))
            }
            required
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block mb-1">Start Time</label>
            <input
              type="time"
              className="border rounded-md w-full p-2"
              value={formData.startTime}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, startTime: e.target.value }))
              }
              required
            />
          </div>
          <div>
            <label className="block mb-1">End Time</label>
            <input
              type="time"
              className="border rounded-md w-full p-2"
              value={formData.endTime}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, endTime: e.target.value }))
              }
              required
            />
          </div>
        </div>

        <div>
          <label className="block mb-1">Meet Link (Optional)</label>
          <input
            type="url"
            className="border rounded-md w-full p-2"
            value={formData.meet_Info.Link}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                meet_Info: { ...prev.meet_Info, Link: e.target.value },
              }))
            }
          />
        </div>

        <div>
          <label className="block mb-1">Notes (Optional)</label>
          <textarea
            className="border rounded-md w-full p-2"
            value={formData.meet_Info.Note}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                meet_Info: { ...prev.meet_Info, Note: e.target.value },
              }))
            }
          />
        </div>

        <div>
          <label className="block mb-1">Days</label>
          <div className="flex flex-wrap gap-2">
            {WEEK_DAYS.map((day) => (
              <label key={day} className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.periodDays.includes(day)}
                  onChange={(e) => {
                    const updatedDays = e.target.checked
                      ? [...formData.periodDays, day]
                      : formData.periodDays.filter((d) => d !== day);
                    setFormData((prev) => ({
                      ...prev,
                      periodDays: updatedDays,
                    }));
                  }}
                  className="mr-1"
                />
                {day}
              </label>
            ))}
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border rounded-md"
            disabled={isUpdating}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-md disabled:bg-blue-300"
            disabled={isUpdating}
          >
            {isUpdating ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </form>
    );
  };
  // AddPeriodForm Component
  const AddPeriodForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
      teacher_id: "",
      periodNumber: "",
      subject: "",
      startTime: "",
      endTime: "",
      meet_Info: {
        Link: "",
        Note: "",
      },
      periodDays: [],
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = useCallback(() => {
      if (!timetableState.timetableId) {
        showToast.error("No timetable found. Please select a class first.");
        return false;
      }

      if (!formData.periodNumber || formData.periodNumber < 1) {
        showToast.error("Please enter a valid period number");
        return false;
      }

      if (!formData.teacher_id || !formData.subject.trim()) {
        showToast.error("Teacher and Subject are required");
        return false;
      }

      if (!formData.startTime || !formData.endTime) {
        showToast.error("Start time and end time are required");
        return false;
      }

      if (formData.periodDays.length === 0) {
        showToast.error("Please select at least one day");
        return false;
      }

      const startTime = new Date(`2000-01-01T${formData.startTime}`);
      const endTime = new Date(`2000-01-01T${formData.endTime}`);
      if (endTime <= startTime) {
        showToast.error("End time must be after start time");
        return false;
      }
      return true;
    }, [formData, timetableState.timetableId, timetableState.timetableData]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!validateForm()) return;

      setIsSubmitting(true);
      try {
        const selectedTeacher = teachers.find(
          (t) => t._id === formData.teacher_id
        );
        if (!selectedTeacher) throw new Error("Invalid teacher selection");

        const payload = {
          teacher_id: selectedTeacher._id,
          subject: formData.subject,
          periodNumber: parseInt(formData.periodNumber),
          startTime: formData.startTime,
          endTime: formData.endTime,
          periodDays: formData.periodDays,
          ...(formData.meet_Info.Link || formData.meet_Info.Note
            ? {
                meet_Info: {
                  ...(formData.meet_Info.Link && {
                    Link: formData.meet_Info.Link,
                  }),
                  ...(formData.meet_Info.Note && {
                    Note: formData.meet_Info.Note,
                  }),
                },
              }
            : {}),
        };

        const response = await axiosReq.post(
          `${apiEndponts.addPeriod}${timetableState.timetableId}/period`,
          payload,
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.status === "success" || response.status === 201) {
          showToast.success("Period added successfully");
          fetchTimeTableData();
          onClose();
        }
      } catch (error) {
        console.error("Error adding period:", error);
        showToast.error(
          error.response?.data?.message || "Failed to add period"
        );
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <form onSubmit={handleSubmit} className="space-y-4 p-6">
        <div>
          <label className="block mb-1">Period Number *</label>
          <input
            type="number"
            className="border rounded-md w-full p-2"
            value={formData.periodNumber}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, periodNumber: e.target.value }))
            }
            min="1"
            required
          />
        </div>

        {/* Teacher Select */}
        <div>
          <label className="block mb-1">Teacher *</label>
          <select
            className="border rounded-md w-full p-2"
            value={formData.teacher_id}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, teacher_id: e.target.value }))
            }
            required
            disabled={isLoadingTeachers}
          >
            <option value="">Select Teacher</option>
            {teachers.map((teacher) => (
              <option key={teacher._id} value={teacher._id}>
                {teacher.name}
              </option>
            ))}
          </select>
          {isLoadingTeachers && (
            <span className="text-xs text-gray-500">Loading teachers...</span>
          )}
        </div>
        {/* Subject Field */}
        <div>
          <label className="block mb-1">Subject *</label>
          <input
            type="text"
            className="border rounded-md w-full p-2"
            value={formData.subject}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, subject: e.target.value }))
            }
            required
          />
        </div>

        {/* Time Fields */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block mb-1">Start Time *</label>
            <input
              type="time"
              className="border rounded-md w-full p-2"
              value={formData.startTime}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, startTime: e.target.value }))
              }
              required
            />
          </div>
          <div>
            <label className="block mb-1">End Time *</label>
            <input
              type="time"
              className="border rounded-md w-full p-2"
              value={formData.endTime}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, endTime: e.target.value }))
              }
              required
            />
          </div>
        </div>

        {/* Meet Link Field */}
        <div>
          <label className="block mb-1">Meet Link (Optional)</label>
          <input
            type="url"
            className="border rounded-md w-full p-2"
            value={formData.meet_Info.Link}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                meet_Info: { ...prev.meet_Info, Link: e.target.value },
              }))
            }
            placeholder="https://meet.google.com/xyz"
          />
        </div>

        {/* Notes Field */}
        <div>
          <label className="block mb-1">Notes (Optional)</label>
          <textarea
            className="border rounded-md w-full p-2"
            value={formData.meet_Info.Note}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                meet_Info: { ...prev.meet_Info, Note: e.target.value },
              }))
            }
            placeholder="Additional information about the period"
          />
        </div>

        {/* Days Selection */}
        <div>
          <label className="block mb-1">Days *</label>
          <div className="flex flex-wrap gap-2">
            {WEEK_DAYS.map((day) => (
              <label key={day} className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.periodDays.includes(day)}
                  onChange={(e) => {
                    const updatedDays = e.target.checked
                      ? [...formData.periodDays, day]
                      : formData.periodDays.filter((d) => d !== day);
                    setFormData((prev) => ({
                      ...prev,
                      periodDays: updatedDays,
                    }));
                  }}
                  className="mr-1"
                />
                {day}
              </label>
            ))}
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-6">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border rounded-md hover:bg-gray-50"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-blue-300"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Adding..." : "Add Period"}
          </button>
        </div>
      </form>
    );
  };

  // Main Render
  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Header Section */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Timetable
              </div>
              <div className="flex items-center space-x-2">
                <select
                  onChange={(e) =>
                    setTimetableState((prev) => ({
                      ...prev,
                      selectedClass: e.target.value,
                    }))
                  }
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                  value={timetableState.selectedClass}
                >
                  {timetableState.classOptions?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>{ACADEMIC_YEAR}</option>
                </select>
                <button
                  onClick={() =>
                    setModalState((prev) => ({ ...prev, isAddModalOpen: true }))
                  }
                  className="bg-[#113870] text-white px-3 py-1 rounded-md text-xs"
                >
                  Add Period
                </button>
              </div>
            </div>

            {/* Timetable Grid */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-4 py-3">Period No</th>
                    {WEEK_DAYS.map((day) => (
                      <th key={day} className="px-4 py-3">
                        {day}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {timetableState.timetableData.map((row, index) => (
                    <tr
                      key={index}
                      className={row.isRecess ? "bg-[#F9FAFB]" : ""}
                    >
                      <td className="px-4 py-3">{row.periodNumber}</td>
                      {WEEK_DAYS.map((day) => {
                        const periodData = row[day];
                        if (!periodData?.subject) return <td key={day}></td>;
                        return (
                          <td key={day} className="px-4 py-3">
                            <div className="flex justify-between items-center">
                              <div>
                                <div className="font-medium">
                                  {periodData.subject}
                                </div>
                                <div className="text-[#888888]">
                                  {`${periodData.startTime} - ${periodData.endTime}`}
                                </div>
                                <div className="text-[#666666] text-xs">
                                  {periodData.teacherName}
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setModalState((prev) => ({
                                    ...prev,
                                    selectedPeriod: { ...periodData, day },
                                    isEditModalOpen: true,
                                  }));
                                }}
                                className="text-blue-600 hover:text-blue-800"
                              >
                                Edit
                              </button>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>

      {/* Modals */}
      {modalState.isEditModalOpen && (
        <Modal
          onClose={() =>
            setModalState((prev) => ({ ...prev, isEditModalOpen: false }))
          }
        >
          <div className="p-4">
            <h2 className="text-lg font-medium mb-4 mr-5">Edit Period</h2>
            <EditPeriodForm
              period={modalState.selectedPeriod}
              day={modalState.selectedPeriod.day}
              onClose={() =>
                setModalState((prev) => ({ ...prev, isEditModalOpen: false }))
              }
            />
          </div>
        </Modal>
      )}

      {modalState.isAddModalOpen && (
        <Modal
          onClose={() =>
            setModalState((prev) => ({ ...prev, isAddModalOpen: false }))
          }
        >
          <div className="p-4">
            <h2 className="text-lg font-medium mb-4">Add New Period</h2>
            <AddPeriodForm
              onClose={() =>
                setModalState((prev) => ({ ...prev, isAddModalOpen: false }))
              }
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
