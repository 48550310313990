"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function ComplaintsPage() {
  // State Management
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [complaintsPerPage] = useState(8);
  const [filteredComplaints, setFilteredComplaints] = useState([]);
  const [totalComplaints, setTotalComplaints] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState("all");
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  // Get Complaints Data
  const getComplaintsData = async () => {
    setIsLoading(true);
    try {
      const params = {
        school_id: userDetails?.school_id,
        model: "complaint",
        page: currentPage,
        limit: complaintsPerPage,
      };

      if (selectedMonth !== "all") {
        params.month = selectedMonth;
      }

      const response = await axiosReq.get(apiEndponts.getCircular, params);

      if (response?.data) {
        const formattedComplaints = response?.data?.map((item) => ({
          id: item?._id,
          date: format(parseISO(item?.createdAt), "dd/MM/yyyy"),
          photo: item?.photo?.[0] || "/path/to/default-photo.jpg",
          parentName: item?.parentName || "Unknown",

          issue: item?.description || "No description",
          status: item?.resolved ? "Approved" : "Pending",
        }));

        setFilteredComplaints(formattedComplaints);
        setTotalComplaints(response?.pagination?.total || 0);
        setTotalPages(response?.pagination?.pages || 1);
      }
    } catch (err) {
      console.error("Error fetching complaints:", err);
      setFilteredComplaints([]);
      setTotalComplaints(0);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to fetch data when filters change
  useEffect(() => {
    if (userDetails?.school_id) {
      getComplaintsData();
    }
  }, [currentPage, selectedMonth, userDetails?.school_id]);

  // Debounced search handler
  const handleSearch = useCallback(
    debounce(() => {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = filteredComplaints.filter((complaint) => {
        return Object.keys(complaint).some((key) =>
          complaint[key].toString().toLowerCase().includes(lowercasedFilter)
        );
      });
      setFilteredComplaints(filtered);
      setCurrentPage(1);
    }, 300),
    [searchTerm, filteredComplaints]
  );

  // Update search term and trigger debounced search
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch();
  };

  // Month Change Handler
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setCurrentPage(1);
  };

  // Pagination handlers
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Complaints header */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Complaints: {totalComplaints}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search for complaints"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                >
                  <option value="all">All Months</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>

            {/* Complaints table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Photo</th>
                    <th className="px-3 py-2">Parent Name</th>
                    <th className="px-3 py-2">Date</th>
                    <th className="px-3 py-2">Issue</th>
                    <th className="px-3 py-2">Status</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredComplaints.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        No complaints found
                      </td>
                    </tr>
                  ) : (
                    filteredComplaints.map((complaint) => (
                      <tr key={complaint.id}>
                        <td className="px-3 py-2">
                          <img
                            src={complaint.photo}
                            alt=""
                            className="w-8 h-8 rounded-full"
                          />
                        </td>
                        <td className="px-3 py-2">
                          <div>{complaint.parentName} </div>
                        </td>
                        <td className="px-3 py-2">{complaint.date}</td>
                        <td className="px-3 py-2">{complaint.issue}</td>
                        <td className="px-3 py-2 text-[#B53D3E]">
                          {complaint.status}
                        </td>
                        <td className="px-3 py-2">
                          <button className="text-[#00A76F] border border-[#00A76F] px-2 py-1 rounded-md">
                            Approve
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {!isLoading && filteredComplaints.length > 0 && (
              <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-500">
                  Showing {(currentPage - 1) * complaintsPerPage + 1} to{" "}
                  {Math.min(currentPage * complaintsPerPage, totalComplaints)}{" "}
                  of {totalComplaints} entries
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Previous
                  </button>

                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={`px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? "bg-[#0662C6] text-white"
                          : "bg-white text-[#0662C6] hover:bg-gray-50"
                      } border border-[#C2C2C2]`}
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-[#0662C6] hover:bg-gray-50"
                    } border border-[#C2C2C2]`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
