"use client";
import React, { useState, useCallback, useEffect } from "react";
import {
  FiSearch,
  FiEdit2,
  FiTrash2,
  FiEye,
  FiMapPin,
  FiX,
} from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";
import AddStudentForm from "./AddStudentForm";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function StudentsPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(8);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [studentsStats, setStudentsStats] = useState({});
  const [studentsEditStats, setStudentsEditStats] = useState({});
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState("All Classes");
  const [selectedSession, setSelectedSession] = useState("2023-24");

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    if (userDetails?.school_id) {
      getStudents(1, selectedDate);
    }
  }, [userDetails?.school_id, selectedDate]);
  const getStudents = async (page = 1, date = null, search = "") => {
    setIsLoading(true);
    try {
      const params = {
        school_id: userDetails?.school_id,
        session_id: userDetails?.session_id,
        page,
        limit: studentsPerPage,
        date: date || selectedDate,
        class: selectedClass !== "All Classes" ? selectedClass : undefined,
        academic_session: selectedSession,
      };

      if (search) {
        params.search = search;
      }

      const response = await axiosReq.get(apiEndponts.getStudents, params);

      if (response?.data?.students) {
        const { students, stats, pagination } = response.data;

        const formattedStudents = students.map((student) => ({
          id: student.id,
          name: student.name,
          photo: student?.photo?.[0] || "",
          rollNo: student?.rollNo?.toString() || "",
          class: student?.className || "",
          guardianName: student?.parentName || "",
          guardianPhone: student?.parentPhone || "",
          address: `${student?.address?.name || ""}, ${
            student?.address?.district || ""
          }, ${student?.address?.state || ""}, ${
            student?.address?.pincode || ""
          }`,
          dob: student?.DOB ? format(new Date(student.DOB), "dd/MM/yyyy") : "",
          attendanceStatus: student?.attendanceStatus || "NOT_MARKED",
          originalData: student,
        }));

        setFilteredStudents(formattedStudents);
        setStudentsStats(stats);
        setTotalPages(pagination?.totalPages || 1);
      }
    } catch (err) {
      console.error("Error fetching students:", err);
      setFilteredStudents([]);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      getStudents(1, selectedDate, value);
    }, 300),
    [selectedDate]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);
    setCurrentPage(1);
    getStudents(1, date, searchTerm);
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
    setCurrentPage(1);
    getStudents(1, selectedDate, searchTerm);
  };

  const handleSessionChange = (e) => {
    setSelectedSession(e.target.value);
    setCurrentPage(1);
    getStudents(1, selectedDate, searchTerm);
  };

  const handleAddStudent = () => {
    setIsAddStudentModalOpen(true);
  };

  const closeAddStudentModal = () => {
    setIsAddStudentModalOpen(false);
    setStudentsEditStats({});
    getStudents(currentPage, selectedDate, searchTerm);
  };

  const handleDeleteClick = (student) => {
    setSelectedStudent(student);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosReq.delete(
        `${apiEndponts.deleteStudent}/${selectedStudent.id}`
      );
      getStudents(currentPage, selectedDate, searchTerm);
      setIsDeleteModalOpen(false);
      setSelectedStudent(null);
    } catch (error) {
      console.error("Error deleting student:", error);
    }
  };

  const handleEdit = (data) => {
    setStudentsEditStats(data.originalData);
    handleAddStudent();
  };

  const getAttendanceStatusColor = (status) => {
    switch (status) {
      case "PRESENT":
        return "text-[#0A8F47]";
      case "ABSENT":
        return "text-[#B53D3E]";
      case "ON_LEAVE":
        return "text-[#F3B807]";
      default:
        return "text-gray-500";
    }
  };
  const renderPagination = () => {
    if (!filteredStudents.length || isLoading) return null;

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {(currentPage - 1) * studentsPerPage + 1} to{" "}
          {Math.min(
            currentPage * studentsPerPage,
            studentsStats?.totalStudents || 0
          )}{" "}
          of {studentsStats?.totalStudents || 0} entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={() => {
              const newPage = currentPage - 1;
              setCurrentPage(newPage);
              getStudents(newPage, selectedDate, searchTerm);
            }}
            disabled={currentPage === 1}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => {
                setCurrentPage(number);
                getStudents(number, selectedDate, searchTerm);
              }}
              className={`px-2 py-1 text-xs rounded ${
                currentPage === number
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={() => {
              const newPage = currentPage + 1;
              setCurrentPage(newPage);
              getStudents(newPage, selectedDate, searchTerm);
            }}
            disabled={currentPage === totalPages}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === totalPages
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="p-3 bg-[#FEFEFE]">
      <div className="max-w-full mx-auto">
        {/* Student stats */}
        <div className="flex justify-between items-center mb-3">
          <div className="flex space-0">
            <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
              Total Students: {studentsStats?.totalStudents || 0}
            </div>
            <div className="bg-white text-[#0A8F47] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
              Present: {studentsStats?.presentStudents || 0}
            </div>
            <div className="bg-white text-[#B53D3E] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
              Absent: {studentsStats?.absentStudents || 0}
            </div>
            <div className="bg-white text-[#F3B807] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
              On Leave: {studentsStats?.onLeaveStudents || 0}
            </div>
            <div className="bg-white text-gray-500 px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
              Not Marked: {studentsStats?.notMarkedStudents || 0}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
              />
              <FiSearch
                className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                size={12}
              />
            </div>

            <select
              className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
              value={selectedClass}
              onChange={handleClassChange}
            >
              <option value="All Classes">All Classes</option>
              <option value="9th Class">9th Class</option>
              <option value="10th Class">10th Class</option>
              <option value="Grade 10">Grade 10</option>
            </select>

            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
            />

            <select
              className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
              value={selectedSession}
              onChange={handleSessionChange}
            >
              <option>2023-24</option>
              <option>2024-25</option>
            </select>

            <button
              onClick={handleAddStudent}
              className="bg-[#0662C6] text-[#FFFFFF] px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs"
            >
              + Add Students
            </button>
          </div>
        </div>

        {/* Students table */}
        <div className="bg-[#FFFFFF] shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
          <table className="w-full">
            <thead>
              <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                <th className="px-3 py-2">Photo</th>
                <th className="px-3 py-2">Name</th>
                <th className="px-3 py-2">Roll No.</th>
                <th className="px-3 py-2">Class</th>
                <th className="px-3 py-2">Guardian Name</th>
                <th className="px-3 py-2">DOB</th>
                <th className="px-3 py-2">Address</th>
                <th className="px-3 py-2">Attendance</th>
                <th className="px-3 py-2">Action</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#C2C2C2] text-xs text-left">
              {isLoading ? (
                <tr>
                  <td colSpan={9} className="px-3 py-4 text-center">
                    Loading...
                  </td>
                </tr>
              ) : filteredStudents.length === 0 ? (
                <tr>
                  <td colSpan={9} className="px-3 py-4 text-center">
                    No students found
                  </td>
                </tr>
              ) : (
                filteredStudents.map((student) => (
                  <tr key={student.id} className="hover:bg-gray-50">
                    <td className="px-3 py-2">
                      <img
                        src={student.photo || "/default-avatar.png"}
                        alt={student.name}
                        className="w-8 h-8 rounded-full object-cover"
                        onError={(e) => {
                          if (
                            e.target.src !==
                            window.location.origin + "/default-avatar.png"
                          ) {
                            e.target.src = "/default-avatar.png";
                          }
                        }}
                      />
                    </td>
                    <td className="px-3 py-2 font-medium text-black">
                      {student.name}
                    </td>
                    <td className="px-3 py-2 text-black">{student.rollNo}</td>
                    <td className="px-3 py-2 text-[#0662C6]">
                      {student.class}
                    </td>
                    <td className="px-3 py-2">
                      {student.guardianName}
                      <br />
                      <span className="text-[#0662C6]">
                        {student.guardianPhone}
                      </span>
                    </td>
                    <td className="px-3 py-2 text-black">{student.dob}</td>
                    <td className="px-3 py-2 text-black">{student.address}</td>
                    <td
                      className={`px-3 py-2 ${getAttendanceStatusColor(
                        student.attendanceStatus
                      )}`}
                    >
                      {student.attendanceStatus.replace(/_/g, " ")}
                    </td>
                    <td className="px-3 py-2">
                      <div className="flex space-x-2">
                        <button className="text-[#0662C6] hover:text-[#113870]">
                          <FiEye size={14} />
                        </button>
                        <button className="text-[#0662C6] hover:text-[#113870]">
                          <FiMapPin size={14} />
                        </button>
                        <button
                          className="text-[#0662C6] hover:text-[#113870]"
                          onClick={() => handleEdit(student)}
                        >
                          <FiEdit2 size={14} />
                        </button>
                        <button
                          className="text-[#B53D3E] hover:text-red-700"
                          onClick={() => handleDeleteClick(student)}
                        >
                          <FiTrash2 size={14} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {renderPagination()}

        {/* Add/Edit Student Modal */}
        {isAddStudentModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">
                  {Object.keys(studentsEditStats).length
                    ? "Update Student"
                    : "Add New Student"}
                </h2>
                <button
                  onClick={closeAddStudentModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <FiX size={24} />
                </button>
              </div>
              <AddStudentForm
                onSubmitSuccess={closeAddStudentModal}
                getStudents={getStudents}
                isEditMode={Object.keys(studentsEditStats).length > 0}
                editData={studentsEditStats}
              />
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
          itemName={selectedStudent ? "this student" : ""}
        />
      </div>
    </div>
  );
}
