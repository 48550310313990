import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";
import { requestTypes } from "../../constants/AppConstant";

function AddERequest({ onSubmitSuccess, editMode, getERequestData }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  const onSubmit = (data) => {
    // onSubmitSuccess();
    const payload = {
      school_id: userDetails?.school_id || "",
      requestFor: data?.requestFor,
      quantity: data?.quantity,
      provided: false,
    };
    const payloadData = new FormData();
    for (let i = 0; i < data?.photo?.length; i++) {
      payloadData.append("photo", data?.photo?.[i]);
    }
    payloadData.append("Data", JSON.stringify(payload));
    axiosReq
      .post(apiEndponts?.addERequests, payloadData)
      .then((resp) => {
        showToast.success("E-request created successfully");
        onSubmitSuccess();
        getERequestData();
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="requestFor"
          >
            Request For
          </label>
          <select
            {...register("requestFor", { required: "Request For is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="incharge_id"
          >
            <option value="">Select Request For</option>
            {/* Add class options here */}
            {requestTypes?.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
          {errors.requestFor && (
            <p className="text-red-500 text-sm mt-1">
              {errors.requestFor.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="quantity"
          >
            Qunatity
          </label>
          <input
            {...register("quantity", { required: "Qunatity is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="quantity"
            type="text"
            placeholder="Qunatity"
          />
          {errors.quantity && (
            <p className="text-red-500 text-sm mt-1">
              {errors.quantity.message}
            </p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="photo"
          >
            Attachments
          </label>
          <input
            {...register("photo")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="photo"
            type="file"
            multiple
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-2 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add Request
        </button>
      </div>
    </form>
  );
}

export default AddERequest;
