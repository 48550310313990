import React from "react";
import { NavLink } from "../ui/NavLink";
import { Button } from "../ui/Button";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <nav className="fixed w-full bg-white backdrop-blur-sm z-50 drop-shadow-md">
      <div className="container mx-auto px-2 py-3">
        <div className="flex items-center justify-between ">
          <div className="flex items-center space-x-5">
            <img
              src="./logoImage.png"
              alt="logo"
              className="h-12 w-12 text-indigo-600"
            />
            <span className="text-2xl font-bold text-blue-700">SCHOOL-EG</span>
          </div>
          <div className="hidden md:flex items-center space-x-8">
            <NavLink href="#features">Features</NavLink>
            <NavLink href="#about">About</NavLink>
            <NavLink href="#testimonials">Testimonials</NavLink>
            <NavLink href="#contact">Contact</NavLink>
          </div>
          <div className="flex items-center space-x-4">
            <Button variant="ghost" onClick={handleLogin}>
              Login
            </Button>
            <Button variant="primary">Get Started</Button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
