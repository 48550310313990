import React, { useState, useCallback } from "react";
import { FiSearch, FiEdit2, FiTrash2, FiImage } from "react-icons/fi";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import Pagination from "../../components/Pagination";

// Sample data structure for advertisements
const advertisementsData = Array(10).fill({
  name: "DPS School Admission",
  startDate: "23 Dec 2024",
  expiryDate: "25 Dec 2024",
  city: "Gurgaon",
  district: "Gurgaon",
  state: "Haryana",
  targetLevel: "State",
  photo: "1",
});

export default function AdvertisementPage() {
  const [activeNav, setActiveNav] = useState("All School");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("Total Advertisement"); // New state for tabs
  const adsPerPage = 10;
  const [filteredAds, setFilteredAds] = useState(advertisementsData);
  // Add these state variables
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [selectedAdvertisement, setSelectedAdvertisement] = useState(null);

  // Update the handler to use selectedAdvertisement
  const handleDeleteClick = (ad) => {
    setSelectedAdvertisement(ad);
    setIsDeleteModalOpen(true);
  };

  // Update to use selectedAdvertisement
  const handleDeleteConfirm = () => {
    // Close the modal
    setIsDeleteModalOpen(false);
    setSelectedAdvertisement(null);
  };
  const handleSearch = useCallback(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filtered = advertisementsData.filter((ad) => {
      return Object.keys(ad).some((key) =>
        ad[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredAds(filtered);
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Advertisement stats and tabs */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-x-2">
                <button
                  onClick={() => setActiveTab("Total Advertisement")}
                  className={`px-2 py-1 ${
                    activeTab === "Total Advertisement"
                      ? "bg-[#E7F7FF] text-[#113870] border border-[#C2C2C2]"
                      : "text-[#888888]"
                  } font-medium text-xs`}
                >
                  Total Advertisement 50
                </button>
                <button
                  onClick={() => setActiveTab("Active")}
                  className={`px-2 py-1 ${
                    activeTab === "Active"
                      ? "bg-[#E7F7FF] text-[#113870] border border-[#C2C2C2]"
                      : "text-[#888888]"
                  } font-medium text-xs`}
                >
                  Active 50
                </button>
                <button
                  onClick={() => setActiveTab("Expired")}
                  className={`px-2 py-1 ${
                    activeTab === "Expired"
                      ? "bg-[#E7F7FF] text-[#113870] border border-[#C2C2C2]"
                      : "text-[#888888]"
                  } font-medium text-xs`}
                >
                  Expired 50
                </button>
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <button className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs">
                  + Add advertisement
                </button>
              </div>
            </div>

            {/* Advertisements table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Name</th>
                    <th className="px-3 py-2">Start Date</th>
                    <th className="px-3 py-2">Expiry Date</th>
                    <th className="px-3 py-2">City</th>
                    <th className="px-3 py-2">District</th>
                    <th className="px-3 py-2">State</th>
                    <th className="px-3 py-2">Target Level</th>
                    <th className="px-3 py-2">Photo</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {filteredAds.map((ad, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">{ad.name}</td>
                      <td className="px-3 py-2">{ad.startDate}</td>
                      <td className="px-3 py-2">{ad.expiryDate}</td>
                      <td className="px-3 py-2">{ad.city}</td>
                      <td className="px-3 py-2">{ad.district}</td>
                      <td className="px-3 py-2">{ad.state}</td>
                      <td className="px-3 py-2">{ad.targetLevel}</td>
                      <td className="px-3 py-2">
                        <div className="flex items-center">
                          <FiImage className="text-[#0662C6]" size={14} />
                        </div>
                      </td>
                      <td className="px-3 py-2">
                        <div className="flex space-x-2">
                          <button className="text-[#0662C6]">
                            <FiEdit2 size={14} />
                          </button>
                          <button
                            className="text-[#B53D3E]"
                            onClick={() => handleDeleteClick(ad)}
                          >
                            <FiTrash2 size={14} />
                          </button>

                          {/* Add the DeleteConfirmationModal */}
                          <DeleteConfirmationModal
                            isOpen={isDeleteModalOpen}
                            onClose={() => setIsDeleteModalOpen(false)}
                            onConfirm={handleDeleteConfirm}
                            itemName={
                              selectedAdvertisement ? "this advertisement" : ""
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredAds.length / adsPerPage)}
              onNextPage={() => setCurrentPage((prev) => prev + 1)}
              isLastPage={currentPage * adsPerPage >= filteredAds.length}
              isFirstPage={currentPage === 1}
              onPrevPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
