"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch, FiEdit2, FiTrash2, FiX } from "react-icons/fi";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import AddParentForm from "./AddParentForm";

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function ParentsPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalParents, setTotalParents] = useState(0);
  const [parentsPerPage] = useState(9);
  const [filteredParents, setFilteredParents] = useState([]);
  const [editParentsData, setEditParentsData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [isAddParentModalOpen, setIsAddParentModalOpen] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getParents(1);
  }, []);

  const getParents = (page = 1, search = "") => {
    const url = `${apiEndponts.getParents}${localStorage.getItem(
      "schoolId"
    )}?page=${page}${search ? `&search=${search}` : ""}`;

    axiosReq
      .get(url)
      .then((resp) => {
        setTotalParents(resp?.data?.totalParentCount);
        const parents =
          resp?.data?.parents?.map((parent) => {
            return {
              ...parent,
              originalAddress: parent?.address,
              name: parent?.name || "",
              phoneNo: parent?.phone || "",
              email: parent?.email || "",
              childrens:
                parent?.children?.map((child) => {
                  return {
                    name: child?.name || "",
                    class: child?.className || "",
                  };
                }) || [],
              address: `${parent?.address?.name || ""}, ${
                parent?.address?.district || ""
              }, ${parent?.address?.state || ""}, ${
                parent?.address?.pincode || ""
              }`,
            };
          }) || [];
        setFilteredParents(parents);
        setTotalPage(resp?.data?.pagination?.totalPages);
      })
      .catch((err) => {
        console.error("Error fetching parents:", err);
        setFilteredParents([]);
        setTotalPage(1);
      });
  };

  // Update the search handler to match this format
  const handleSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      getParents(1, value);
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleAddParents = () => {
    setIsAddParentModalOpen(true);
  };

  const closeAddParentsModal = () => {
    setIsAddParentModalOpen(false);
    setEditParentsData({});
    getParents(currentPage, searchTerm);
  };

  const handleDeleteClick = (parent) => {
    setSelectedParent(parent);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosReq.delete(`${apiEndponts.deleteParent}/${selectedParent.id}`);
      getParents(currentPage, searchTerm);
      setIsDeleteModalOpen(false);
      setSelectedParent(null);
    } catch (error) {
      console.error("Error deleting parent:", error);
    }
  };

  const handleEdit = (data) => {
    setEditParentsData(data);
    handleAddParents();
  };

  const renderPagination = () => {
    if (!filteredParents.length || isLoading) return null;

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {(currentPage - 1) * parentsPerPage + 1} to{" "}
          {Math.min(currentPage * parentsPerPage, totalParents)} of{" "}
          {totalParents} entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={() => {
              const newPage = currentPage - 1;
              setCurrentPage(newPage);
              getParents(newPage, searchTerm);
            }}
            disabled={currentPage === 1}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => {
                setCurrentPage(number);
                getParents(number, searchTerm);
              }}
              className={`px-2 py-1 text-xs rounded ${
                currentPage === number
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={() => {
              const newPage = currentPage + 1;
              setCurrentPage(newPage);
              getParents(newPage, searchTerm);
            }}
            disabled={currentPage === totalPage}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === totalPage
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Parent stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Parents: {totalParents}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>2023-24</option>
                </select>
                <button
                  className="bg-[#0662C6] text-white px-2 py-1 rounded-md font-medium hover:bg-[#113870] transition-colors duration-200 text-xs"
                  onClick={handleAddParents}
                >
                  + Add Parents
                </button>
              </div>
            </div>

            {/* Parents table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Name</th>
                    <th className="px-3 py-2">Phone No.</th>
                    <th className="px-3 py-2">E-mail</th>
                    <th className="px-3 py-2">Childrens</th>
                    <th className="px-3 py-2">Address</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="px-3 py-4 text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredParents.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="px-3 py-4 text-center">
                        No parents found
                      </td>
                    </tr>
                  ) : (
                    filteredParents.map((parent, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-2">{parent.name}</td>
                        <td className="px-3 py-2">{parent.phoneNo}</td>
                        <td className="px-3 py-2 text-[#0662C6]">
                          {parent.email}
                        </td>
                        <td className="px-3 py-2">
                          {parent.childrens.map((child, i) => (
                            <div
                              key={i}
                              className="flex items-center space-x-1"
                            >
                              <span>{child.name}, </span>
                              <span className="text-[#0662C6]">
                                {child.class}
                              </span>
                            </div>
                          ))}
                        </td>
                        <td className="px-3 py-2">{parent.address}</td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button
                              className="text-[#0662C6] hover:text-[#113870]"
                              onClick={() => handleEdit(parent)}
                            >
                              <FiEdit2 size={14} />
                            </button>
                            <button
                              className="text-[#B53D3E] hover:text-red-700"
                              onClick={() => handleDeleteClick(parent)}
                            >
                              <FiTrash2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {renderPagination()}

            {/* Add/Edit Parent Modal */}
            {isAddParentModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-4 w-1/2 mx-auto max-h-screen overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">
                      {Object.keys(editParentsData).length
                        ? "Update Parent"
                        : "Add Parent"}
                    </h2>
                    <button
                      onClick={closeAddParentsModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FiX size={24} />
                    </button>
                  </div>
                  <AddParentForm
                    onSubmitSuccess={closeAddParentsModal}
                    getParents={getParents}
                    isEditMode={Object.keys(editParentsData).length > 0}
                    editData={editParentsData}
                  />
                </div>
              </div>
            )}

            {/* Delete Confirmation Modal */}
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onConfirm={handleDeleteConfirm}
              itemName={selectedParent ? "this parent" : ""}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
