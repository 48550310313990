import React, { useState, useCallback } from "react";
import { FiSearch, FiEdit2, FiInfo } from "react-icons/fi";

import Pagination from "../../components/Pagination";

// Sample data structure for counselling
const counsellingData = Array(9).fill({
  childName: "Aditya Rai",
  dateTime: {
    date: "23 Dec 2024",
    time: "8:00 Am",
  },
  dreamJob: "Air Force",
  paymentStatus: "Paid",
  counsellingStatus: "Done",
});

export default function CounsellingPage() {
  const [activeNav, setActiveNav] = useState("All School");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const counsellingPerPage = 9;
  const [filteredCounselling, setFilteredCounselling] =
    useState(counsellingData);

  const handleSearch = useCallback(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filtered = counsellingData.filter((session) => {
      return Object.keys(session).some((key) =>
        session[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredCounselling(filtered);
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Counselling stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                Total Councelling: 50
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-[#C2C2C2] rounded-md pl-6 pr-2 py-1 w-36 focus:outline-none focus:ring-1 focus:ring-[#0662C6] bg-[#F4F4F4] text-xs"
                  />
                  <FiSearch
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#888888]"
                    size={12}
                  />
                </div>
              </div>
            </div>

            {/* Counselling table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Child Name</th>
                    <th className="px-3 py-2">Preffered Date & Time</th>
                    <th className="px-3 py-2">Dream Job/Career</th>
                    <th className="px-3 py-2">Payment Status</th>
                    <th className="px-3 py-2">Councelling Status</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {filteredCounselling.map((session, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">{session.childName}</td>
                      <td className="px-3 py-2">
                        <div>{session.dateTime.date}</div>
                        <div className="text-[#888888]">
                          {session.dateTime.time}
                        </div>
                      </td>
                      <td className="px-3 py-2">{session.dreamJob}</td>
                      <td className="px-3 py-2">
                        <span className="text-[#22A55D]">
                          {session.paymentStatus}
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <span className="text-[#0662C6]">
                          {session.counsellingStatus}
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="flex space-x-2">
                          <button className="text-[#0662C6]">
                            <FiInfo size={14} />
                          </button>
                          <button className="text-[#0662C6]">
                            <FiEdit2 size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(
                filteredCounselling.length / counsellingPerPage
              )}
              onNextPage={() => setCurrentPage((prev) => prev + 1)}
              isLastPage={
                currentPage * counsellingPerPage >= filteredCounselling.length
              }
              isFirstPage={currentPage === 1}
              onPrevPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
