"use client";
import React, { useState, useCallback, useEffect } from "react";
import { FiEye, FiEdit2, FiTrash2 } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import { useSelector } from "react-redux";
import apiEndponts from "../../constants/endpoints";
import axiosReq from "../../constants/axiosObj";
import { format, parseISO } from "date-fns";
import { getRank } from "../../constants/AppConstant";

// Sample data structure for homework

export default function Assignments() {
  const [activeNav, setActiveNav] = useState("My School");
  const [currentPage, setCurrentPage] = useState(1);
  const homeworkPerPage = 7;
  const [filteredHomework, setFilteredHomework] = useState([]);
  const [totalHomeWorks, setTotalHomeWorks] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    userDetails.school_id && getHomeWorkData();
  }, [userDetails.school_id]);

  const getStudentsNameAndCount = (studetns) => {
    const firstStdName = studetns?.[0]?.name;
    const studentCount = studetns?.length;
    if (studentCount > 1) {
      return `${firstStdName} + ${studentCount - 1} more`;
    } else {
      return firstStdName;
    }
  };
  const getHomeWorkData = (page = 1) => {
    axiosReq
      .get(apiEndponts.getCircular, {
        school_id: userDetails.school_id || "",
        model: "assignment",
        page,
      })
      .then((resp) => {
        const classOptions = resp?.data?.map((item) => ({
          ...item,
          student: item?.allClass
            ? "For all Class"
            : getStudentsNameAndCount(item?.students),
          date: format(parseISO(item?.date), "dd/MM/yyyy"),
          subject: item?.periodInfo?.subject || "",
          period: getRank(item?.periodInfo?.periodNumber),
        }));
        setFilteredHomework(classOptions || []);
        setTotalPage(resp?.pagination?.pages);
        setTotalHomeWorks(resp?.pagination?.total);
      })
      .catch((err) => console.log(err));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    getHomeWorkData(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    getHomeWorkData(currentPage + 1);
  };

  // Calculate the homework entries to display on the current page
  const currentHomework = filteredHomework;

  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Homework stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs text-left">
                Total Homework: {totalHomeWorks}
              </div>
              <div className="flex items-center space-x-2">
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>Select Class</option>
                </select>
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>2023-24</option>
                </select>
                <select className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs">
                  <option>January</option>
                  <option>February</option>
                  <option>March</option>
                  <option>April</option>
                  <option>May</option>
                  <option>June</option>
                  <option>July</option>
                  <option>August</option>
                  <option>September</option>
                  <option>October</option>
                  <option>November</option>
                  <option>December</option>
                </select>
              </div>
            </div>

            {/* Homework table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Date</th>
                    <th className="px-3 py-2">Subject</th>
                    <th className="px-3 py-2">Teacher</th>
                    <th className="px-3 py-2">Period</th>
                    <th className="px-3 py-2">Student</th>
                    <th className="px-3 py-2">Homework</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {currentHomework.map((homework, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2">{homework.date}</td>
                      <td className="px-3 py-2">{homework.subject}</td>
                      <td className="px-3 py-2">{homework.teacher}</td>
                      <td className="px-3 py-2">{homework.period}</td>
                      <td className="px-3 py-2 text-[#0662C6]">
                        {homework.student}
                      </td>
                      <td className="px-3 py-2">{homework.homework}</td>
                      <td className="px-3 py-2">
                        <div className="flex space-x-2">
                          <button
                            className="text-[#0662C6]"
                            onClick={() => {
                              homework?.photo?.forEach((element) => {
                                window.open(element);
                              });
                            }}
                          >
                            <FiEye size={14} />
                          </button>
                          <button className="text-[#0662C6]">
                            <FiEdit2 size={14} />
                          </button>
                          <button className="text-[#B53D3E]">
                            <FiTrash2 size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredHomework.length / homeworkPerPage)}
              onNextPage={handleNextPage}
              isLastPage={totalPage === currentPage}
              isFirstPage={currentPage === 1}
              onPrevPage={handlePrevPage}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
