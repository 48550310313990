"use client";
import React, { useState, useEffect } from "react";
import { FiEdit2 } from "react-icons/fi";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

export default function TeacherAttendancePage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [teachersPerPage] = useState(8);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [teacherStats, setTeacherStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [selectedSession, setSelectedSession] = useState("2023-24");
  const [totalItems, setTotalItems] = useState(0);

  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    if (userDetails?.school_id) {
      getTeacherAttendance(1, selectedDate);
    }
  }, [userDetails?.school_id, selectedDate]);

  const getTeacherAttendance = async (page = 1, date) => {
    setIsLoading(true);
    try {
      const params = {
        school_id: userDetails?.school_id,
        session_id: userDetails?.session_id,
        page,
        limit: teachersPerPage,
        // date: date || selectedDate,
        // department:
        //   selectedDepartment !== "All" ? selectedDepartment : undefined,
        // academic_session: selectedSession,
      };

      const response = await axiosReq.get(
        apiEndponts.getTeacherAttendance,
        params
      );

      if (response?.success) {
        setTeacherStats({
          totalTeachers: response.total_teachers,
          presentTeachers: response.attendance_counts.present,
          absentTeachers: response.attendance_counts.absent,
          onLeaveTeachers: response.attendance_counts.onLeave,
          halfDayTeachers: response.attendance_counts.halfDay,
          notMarkedTeachers: response.attendance_counts.notMarked,
        });

        const formattedTeachers = response.attendance.map((teacher) => ({
          teacherName: teacher.name,
          photo: teacher.photo,
          status: teacher.status,
          date: response.date
            ? format(parseISO(response.date), "dd/MM/yyyy")
            : format(new Date(), "dd/MM/yyyy"),
        }));

        setFilteredAttendance(formattedTeachers);
        setTotalPages(response.pagination.total_pages);
        setCurrentPage(response.pagination.current_page);
        setTotalItems(response.pagination.total_documents);
      }
    } catch (err) {
      console.error("Error fetching teacher attendance:", err);
      setFilteredAttendance([]);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setCurrentPage(1);
    getTeacherAttendance(1, e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setCurrentPage(1);
    getTeacherAttendance(1, selectedDate);
  };

  const handleSessionChange = (e) => {
    setSelectedSession(e.target.value);
    setCurrentPage(1);
    getTeacherAttendance(1, selectedDate);
  };

  const renderPagination = () => {
    if (!filteredAttendance.length || isLoading) return null;

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-between items-center mt-4 px-2">
        <div className="text-sm text-[#888888]">
          Showing {(currentPage - 1) * teachersPerPage + 1} to{" "}
          {Math.min(currentPage * teachersPerPage, totalItems)} of {totalItems}{" "}
          entries
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={() => {
              const newPage = currentPage - 1;
              setCurrentPage(newPage);
              getTeacherAttendance(newPage, selectedDate);
            }}
            disabled={currentPage === 1}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === 1
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => {
                setCurrentPage(number);
                getTeacherAttendance(number, selectedDate);
              }}
              className={`px-2 py-1 text-xs rounded ${
                currentPage === number
                  ? "bg-[#113870] text-white"
                  : "bg-white text-[#113870] hover:bg-gray-50"
              } border border-[#C2C2C2]`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={() => {
              const newPage = currentPage + 1;
              setCurrentPage(newPage);
              getTeacherAttendance(newPage, selectedDate);
            }}
            disabled={currentPage === totalPages}
            className={`px-2 py-1 text-xs rounded ${
              currentPage === totalPages
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-white text-[#113870] hover:bg-gray-50"
            } border border-[#C2C2C2]`}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="flex h-screen bg-[#FEFEFE] font-sans text-[#1E1E1E] antialiased text-xs text-left">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FEFEFE] p-3">
          <div className="max-w-full mx-auto">
            {/* Attendance stats */}
            <div className="flex justify-between items-center mb-3">
              <div className="flex space-x-0">
                <div className="bg-[#E7F7FF] text-[#113870] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Total Teachers: {teacherStats?.totalTeachers || 0}
                </div>
                <div className="bg-white text-[#0A8F47] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Present: {teacherStats?.presentTeachers || 0}
                </div>
                <div className="bg-white text-[#B53D3E] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Absent: {teacherStats?.absentTeachers || 0}
                </div>
                <div className="bg-white text-[#F3B807] px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  On Leave: {teacherStats?.onLeaveTeachers || 0}
                </div>
                <div className="bg-white text-orange-500 px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Half Day: {teacherStats?.halfDayTeachers || 0}
                </div>
                <div className="bg-white text-gray-500 px-2 py-1 border border-[#C2C2C2] font-medium text-xs">
                  Not Marked: {teacherStats?.notMarkedTeachers || 0}
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                >
                  <option value="All">All Departments</option>
                  <option value="Science">Science</option>
                  <option value="Mathematics">Mathematics</option>
                  <option value="English">English</option>
                  <option value="Social Studies">Social Studies</option>
                </select>

                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                />

                <select
                  className="border border-[#C2C2C2] rounded-md px-2 py-1 bg-[#F4F4F4] text-xs"
                  value={selectedSession}
                  onChange={handleSessionChange}
                >
                  <option>2023-24</option>
                  <option>2024-25</option>
                </select>
              </div>
            </div>

            {/* Attendance table */}
            <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-[#C2C2C2]">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#F4F4F4] text-left text-xs font-medium text-[#888888]">
                    <th className="px-3 py-2">Photo</th>
                    <th className="px-3 py-2">Date</th>
                    <th className="px-3 py-2">Teacher Name</th>
                    <th className="px-3 py-2">Status</th>
                    <th className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#C2C2C2] text-xs">
                  {isLoading ? (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredAttendance.length === 0 ? (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        No records found
                      </td>
                    </tr>
                  ) : (
                    filteredAttendance.map((record, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-2">
                          <img
                            src={record.photo || "/default-avatar.png"}
                            alt={record.teacherName}
                            className="w-8 h-8 rounded-full object-cover"
                            onError={(e) => {
                              if (
                                e.target.src !==
                                window.location.origin + "/default-avatar.png"
                              ) {
                                e.target.src = "/default-avatar.png";
                              }
                            }}
                          />
                        </td>
                        <td className="px-3 py-2">{record.date}</td>
                        <td className="px-3 py-2">{record.teacherName}</td>
                        <td className="px-3 py-2">
                          <span
                            className={`${
                              record.status === "Present"
                                ? "text-[#0A8F47]"
                                : record.status === "Absent"
                                ? "text-[#B53D3E]"
                                : record.status === "On Leave"
                                ? "text-[#F3B807]"
                                : record.status === "Half Day"
                                ? "text-orange-500"
                                : "text-gray-500"
                            }`}
                          >
                            {record.status}
                          </span>
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex space-x-2">
                            <button className="text-[#0662C6] hover:text-[#113870]">
                              <FiEdit2 size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {renderPagination()}
          </div>
        </main>
      </div>
    </div>
  );
}
