import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axiosReq from "../../constants/axiosObj";
import apiEndponts from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { showToast } from "../../utils/toast";

function AddClassForm({ onSubmitSuccess, editMode, getClassInfo }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [teachers, setTeachers] = useState([]);
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);

  useEffect(() => {
    if (userDetails?.school_id) {
      getTeachers();
    }
  }, [userDetails?.school_id]);

  const getTeachers = () => {
    const url = `${apiEndponts.getTeacherList}${userDetails?.school_id}/${userDetails?.session_id}`;
    axiosReq
      .get(url)
      .then((resp) => {
        const teachers = resp?.data?.map((item) => ({
          id: item?._id,
          name: item?.name,
        }));
        setTeachers(teachers);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (data) => {
    const payload = {
      name: data?.name || "",
      school_id: userDetails?.school_id || "",
      session_id: userDetails?.session_id || "",
      incharge_id: data?.incharge_id || "",
      lunch: {
        from: data?.from || "",
        to: data?.to || "",
      },
      isActive: true,
      fee: {
        tuitionFee: data?.tutionFee || 0,
        examFee: data?.examFee || 0,
        booksCharges: data?.bookFee,
        dressCharges: data?.dressFee,
        idCardCharges: data?.idCardCharges || 0,
        otherCharges: data?.otherCharges || 0,
        admissionCharges: data?.admissionCharges || 0,
      },
      // "platformFee": 100
    };
    // const payloadData = new FormData();
    // payloadData.append('Data', JSON.stringify(payload));
    axiosReq
      .post(apiEndponts?.createClasses, payload)
      .then((resp) => {
        showToast.success("Class created successfully");
        onSubmitSuccess();
        getClassInfo();
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg p-2 space-y-1 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="name"
          >
            Class Name
          </label>
          <input
            {...register("name", { required: "Class Name is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
            type="text"
            placeholder="Class Name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="incharge_id"
          >
            Incharge
          </label>
          <select
            {...register("incharge_id", { required: "Incharge is required" })}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="incharge_id"
          >
            <option value="">Select Incharge</option>
            {/* Add class options here */}
            {teachers?.map((item) => {
              return <option value={item.id}>{item?.name}</option>;
            })}
          </select>
          {errors.class_id && (
            <p className="text-red-500 text-sm mt-1">
              {errors.class_id.message}
            </p>
          )}
        </div>
        <div className="grid-column2">
          <p className="text-gray-700 font-semibold mb-2">Lunch Time:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 ml-2 ms-2">
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="from"
              >
                From
              </label>
              <input
                {...register("from", { required: "Lunch From is required" })}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 "
                id="from"
                type="time"
                placeholder="Lunch From"
              />
              {errors.from && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.from.message}
                </p>
              )}
            </div>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="to"
              >
                To
              </label>
              <input
                {...register("to", { required: "Lunch to is required" })}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="phone"
                type="time"
                maxLength={10}
                placeholder="Lunch to"
                onChange={(e) => {
                  const value = e.target.value?.replace(/\D/g, "");
                  setValue("phone", value);
                }}
              />
              {errors.phone && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.phone.message}
                </p>
              )}
            </div>
          </div>
        </div>

        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="cameraImei"
          >
            Camera IMEI
          </label>
          <input
            {...register("cameraImei")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="cameraImei"
            type="text"
            maxLength={10}
            placeholder="Camera IMEI"
          />
        </div>
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1"
            htmlFor="sim"
          >
            Sim
          </label>
          <input
            {...register("sim")}
            className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="sim"
            type="text"
            maxLength={10}
            placeholder="Sim"
          />
        </div>

        <div className="grid-column2">
          <p className="text-gray-700 font-semibold mb-2">Fee:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 ml-2 ms-2">
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="tutionFee"
              >
                Tution Fee
              </label>
              <input
                {...register("tutionFee")}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="tutionFee"
                type="text"
                placeholder="Tution Fee"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="examFee"
              >
                Exam Fee
              </label>
              <input
                {...register("examFee")}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="examFee"
                type="text"
                placeholder="Exam Fee"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="dressFee"
              >
                Dress
              </label>
              <input
                {...register("dressFee")}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="dressFee"
                type="text"
                placeholder="Dress"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="idCardCharges"
              >
                ID Card
              </label>
              <input
                {...register("idCardCharges")}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="idCardCharges"
                type="text"
                placeholder="ID Card"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="bookFee"
              >
                Book Fee
              </label>
              <input
                {...register("bookFee")}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="bookFee"
                type="text"
                placeholder="Hostel Fee"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="hostelFee"
              >
                Hostel Fee
              </label>
              <input
                {...register("hostelFee")}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="hostelFee"
                type="text"
                placeholder="Hostel Fee"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="admissionCharges"
              >
                Admission Charge
              </label>
              <input
                {...register("admissionCharges")}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="admissionCharges"
                type="text"
                placeholder="Admission Charge"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1"
                htmlFor="otherCharges"
              >
                Other Charge
              </label>
              <input
                {...register("otherCharges")}
                className="shadow-sm border rounded-lg w-full py-1 px-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="otherCharges"
                type="text"
                placeholder="Other Charge"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-2 rounded-lg focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Add Class
        </button>
      </div>
    </form>
  );
}

export default AddClassForm;
