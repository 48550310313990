import { configureStore } from '@reduxjs/toolkit';
import userDetails from './Slices/userDetailsSlice';
import storage from 'redux-persist/lib/storage'; // Default: localStorage for web
import { persistReducer, persistStore } from 'redux-persist';

// Persist configuration for userDetails slice
const persistConfig = {
  key: 'userDetails',
  storage,
};

// Wrap userDetails reducer with persistReducer
const persistedUserDetailsReducer = persistReducer(persistConfig, userDetails);

export const store = configureStore({
  reducer: {
    userDetails: persistedUserDetailsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Avoid errors with non-serializable data in redux-persist
    }),
});

// Persistor to persist the store
export const persistor = persistStore(store);
